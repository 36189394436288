import Blobs from '../components/BlobsMain/Blobs'
import Home from '../Home/Home'
import About from '../About/About'
import Video from '../Video/Video'
import Perks from '../Perks/Perks'
import Email from '../Email/Email'
import Footer from '../Footer/Footer'
import './HomeWrapper.scss'
import { Developer } from '../Developer/Developer'
import { StaticStats } from '../../Static/Components/StaticStats/StaticStats'
import FAQ from '../FAQ/FAQ'
import { ReadMore } from '../ReadMore/ReadMore'
import { Simple } from '../Simple/Simple'
import { GetStarted } from '../GetStarted/GetStarted'
import { AboutSimple } from '../AboutSimple/AboutSimple'
import { FunTool } from '../FunTool/FunTool'
import { AiExpert } from '../FunTool/Components/AiExpert/AiExpert'
import { MultiExpert } from '../FunTool/Components/MultiExpert/MultiExpert'

const Landing = ({ sendAlert }: { sendAlert: Function }) => {

    const statsConfig = [
        {
            percentage: 87,
            text: 'of global organizations believe that AI technologies will give them a competitive edge.',
            source: 'https://web-assets.bcg.com/1e/4f/925e66794465ad89953ff604b656/mit-bcg-expanding-ai-impact-with-organizational-learning-oct-2020-n.pdf',
        },
        {
            percentage: 40,
            text: 'more productive, will be the employees using AI by 2035.',
            source: 'https://www.pwc.com/us/en/tech-effect/ai-analytics/ai-predictions.html',
        },
        {
            percentage: 97,
            text: 'of business owners believe ChatGPT will help their business',
            source: 'https://www.forbes.com/advisor/business/software/ai-in-business/',
        },
        {
            percentage: 83,
            text: 'of organizations worldwide claim that AI is a top priority for their business processes.',
            source: 'https://techbullion.com/83-of-executives-consider-ai-a-strategic-priority-for-their-company/',
        },
    ]
    const funToolConfig = [
        {
            'name': 'AI Expert',
            'component': <AiExpert />,
        },
        {
            'name': 'Multi Expert response',
            'component': <MultiExpert />,
    
        },
    ]

    return (
        <div className='wrapper'>
            <Blobs />
            <div className='homeWrapper'>
                <Home />
                {/*<Video />*/}
                {/*<About />*/}
                <StaticStats statsConfig={statsConfig} title={'AI is the future'}/>
                <Simple />
                {/*<AboutSimple />*/}
                <FunTool funToolConfig={funToolConfig}/>
                <GetStarted />
                <FAQ />
                <ReadMore />
                {/*<Perks />*/}
                {/*<Developer />*/}
                <Email sendAlert={sendAlert} />a
                <Footer />
            </div>
        </div>
    )
}

export default Landing
