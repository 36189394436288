import React, { useEffect, useRef } from 'react'
import './Email.scss'
import './EmailMobile.scss'
import { Element } from 'react-scroll/modules'
import { addToWaitlist } from '../../../Requests/otherRequests'
import { AlertSeverity, AlertType } from '../../../Interfaces/alertsInterfaces'
import { AiFillLinkedin, AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai'
import { ContactComponent } from '../../Static/Contact/Contact'

const Email = ({ sendAlert }: { sendAlert: Function }) => {

    const emailRef = useRef(null)
    const nameRef = useRef(null)
    const occupationRef = useRef(null)
    const isDeveloperRef = useRef(null)

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        // @ts-ignore
        const email = emailRef.current.value
        // @ts-ignore
        const name = nameRef.current.value
        // @ts-ignore
        const occupation = occupationRef.current.value
        // @ts-ignore
        const isDeveloper = isDeveloperRef.current.checked
        const response = await addToWaitlist(name, email, occupation, isDeveloper)
        if (response.status === 200) {
            sendAlert(AlertType.SUCCESS, 'You have been added to the waitlist!', AlertSeverity.SUCCESS)
        } else if (response.status === 400 && response.data === 'User already exists') {
            sendAlert(AlertType.DUPLICATE, 'You are already on the waitlist!', AlertSeverity.INFO)
        } else {
            sendAlert(AlertType.UNKNOWN, 'Something went wrong. Please try again later.', AlertSeverity.ERROR)
        }
    }
    return (
        <Element name='email' className='email'>
            <h1 className='email-title'>Contact us!</h1>
            {/*<div className='sender'>*/}
            {/*    <div className='social'>*/}
            {/*        <h2>Contact us on:</h2>*/}
            {/*        <a className='socialItem' href='mailto:office@appi-marketplace.com'>*/}
            {/*            <AiOutlineMail id='socialIcon' />*/}
            {/*            <h3>office@appi-marketplace.com</h3>*/}
            {/*        </a>*/}
            {/*        <a className='socialItem' href='https://www.linkedin.com/company/90967506/'>*/}
            {/*            <AiFillLinkedin id='socialIcon' />*/}
            {/*            <h3>LinkedIn</h3>*/}
            {/*        </a>*/}
            {/*        <a className='socialItem' href='https://www.instagram.com/appi_marketplace/'>*/}
            {/*            <AiOutlineInstagram id='socialIcon' />*/}
            {/*            <h3>Instagram</h3>*/}
            {/*        </a>*/}
            {/*    </div>*/}
            {/*    <div className='formWrapper'>*/}
            {/*        <h2>Sign up for the newsletter</h2>*/}
            {/*        <form onSubmit={handleSubmit}>*/}
            {/*            <input type='email' placeholder='Your email' required={true} ref={emailRef} />*/}
            {/*            <input type='text' placeholder='Your name' required={true} ref={nameRef} />*/}
            {/*            <div className='formWrapper-checkbox'>*/}
            {/*                <label> Do you intend to create AI agents?</label>*/}
            {/*                <input type='checkbox' ref={isDeveloperRef} />*/}
            {/*            </div>*/}
            {/*            <button type='submit'>Send</button>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <ContactComponent title={false} side={true}/>
        </Element>
    )
}

export default Email
