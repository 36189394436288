import { apiDelete, apiGet, apiPost, apiPut } from '../Utils/RequestsUtil'
import Cookies from 'js-cookie'
import axios from 'axios'

export const uploadDocument = async (expertId: string, file: File) => {
    const jwt = Cookies.get('jwt');
    const url = `${process.env.REACT_APP_API_URL}ExpertDocument/AddExpertDocumentWithPython/${expertId}`;

    // Create a FormData object
    const formData = new FormData();
    formData.append('expertDocumentFile', file);

    // Set headers
    const headers: { [key: string]: string } = {
        'Content-Type': 'application/pdf',
    };

    if (jwt) {
        headers['Authorization'] = `Bearer ${jwt}`;
    }

    try {
        // Make the POST request with axios
        const response = await axios.post(url, formData, { headers, timeout: 6000000 });
        return response.data;
    } catch (error) {
        // Handle errors
        console.error('Error uploading document:', error);
        throw error;
    }
};

export const getDocuments = async (expertId: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiGet(
            'ExpertDocument/' + expertId,
            null,
            false,
            // @ts-ignore
            jwt)
    } catch (e) {
        throw e
    }
}

export const getExpertDocument = async (expertId: string, documentId: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiGet(
            'ExpertDocument/' + expertId + '/' + documentId,
            null,
            false,
            // @ts-ignore
            jwt)
    } catch (e) {
        throw e
    }
}

export const getExpertDocumentFile = async (documentId: string) => {
    const jwt = Cookies.get('jwt')
    try {
        const url = 'ExpertDocument/GetDocumentFile/' + documentId
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'accept': '*/*',
        }
        const response = await axios
            .get(
                process.env.REACT_APP_API_URL + url,
                {
                    headers: headers,
                    responseType: 'blob',
                })
            .catch((error) => {
                throw error
            })

        return await response.data
    } catch (e) {
        throw e
    }
}

export const deleteExpertDocument = async (expertId: string, documentId: string) => {
    const jwt = Cookies.get('jwt')
    try {
        return await apiDelete(
            'ExpertDocument/' + expertId + '/' + documentId,
            null,
            false,
            // @ts-ignore
            jwt)
    } catch (e) {
        throw e
    }
}