import './DevApplicationStats.scss'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'

interface DevApplicationStatsInterface {
    agent: AgentInterface
}

export const DevApplicationStats = ({ agent }: DevApplicationStatsInterface) => {
    console.log(agent)
    return (
        <div className='devApplicationStats'>

        </div>
    )
}