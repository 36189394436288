import { useState } from "react"
import './DummyModelSettings.scss'

export interface DummyModelSettingsProps {
    onClick: () => void;
    active: boolean;
}

export const DummyModelSettings = (props : DummyModelSettingsProps) => {
    return (
        <div className={props.active ? 'dummyModelSettings' : 'dummyModelSettings inactive'}>
            <div className='dummyModelSettings-elements'>
                <DummyModelSettingsElement disabled={!props.active} label='Temperature' min={0} max={1} step={0.01} defaultValue={1} />
                <DummyModelSettingsElement disabled={!props.active} label='Maximum length' min={1} max={4096} step={1} defaultValue={256} />
                <DummyModelSettingsElement disabled={!props.active} label='Top P' min={0} max={1} step={0.01} defaultValue={1} />
            </div>
            <div className='dummyModelSettings-elements'>
                <DummyModelSettingsElement disabled={!props.active} label='Frequency penalty' min={0} max={2} step={0.01} defaultValue={0} />
                <DummyModelSettingsElement disabled={!props.active} label='Presence penalty' min={0} max={2} step={0.01} defaultValue={0} />
            </div>
            <button className='dummyModelSettings-button'  onClick={() => {props.onClick()}}>Continue</button>
        </div>
    )
}

interface DummyModelSettingsElementInterface {
    label: string
    min: number
    max: number
    step: number
    defaultValue: number
    disabled: boolean
}

const DummyModelSettingsElement = ({
    label,
    min,
    max,
    step,
    defaultValue,
    disabled
}: DummyModelSettingsElementInterface) => {

    const [value, setValue] = useState<number>(defaultValue)

    const handleChange = (newValue: number) => {
        const clampedValue = Math.max(min, Math.min(max, newValue))
        setValue(clampedValue)
    }

return (
    <div className='modelSettings-item'>
    <div className='modelSettings-item-top'>
        <div className='modelSettings-item-label'>{label}</div>
            <input
                className={disabled ? 'modelSettings-item-input-number inactive' : 'modelSettings-item-input-number'}
                type='number'
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(e) => handleChange(parseFloat(e.target.value))}
                disabled={disabled}
            />
        </div>
        <input
            className= {disabled ? 'modelSettings-item-input-range inactive' : 'modelSettings-item-input-range-active'}
            type='range'
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={(e) => handleChange(parseFloat(e.target.value))}
            disabled={disabled}
        />
    </div>
)
}