import './Simple.scss'
import { FaTrophy } from 'react-icons/fa'
import { useState } from 'react'

export const Simple = () => {


    return (
        <div className='simple'>
            {/*<h1 className='simple-title'>We make it simple</h1>*/}
            <h2 className='simple-title'>We understand AI is <span
                className='simple-title-highlight'>overwhelming</span></h2>
            <h2 className='simple-subtitle'>You dream up what to automate — APPI will handle the rest. Combine user
                interfaces, data tables. APPI will help you grow twice as fast, even without hiring another
                person.</h2>
            <button className='simple-button'>
                Let us guide you in AI transformation
            </button>
            {/*<FaHandshake className='simple-icon' />*/}
            {/*<img src='https://via.placeholder.com/500x300' alt='handshake' className='simple-icon' />*/}
        </div>
    )
}