import './StaticProps.scss'
import React from 'react'

interface StaticPropsItemInterface {
    title: string
    text: string
    icon: React.ReactNode
}

interface StaticPropsInterface {
    items?: StaticPropsItemInterface[]
    title: string
    altStyle?: boolean
}

export const StaticProps = (props: StaticPropsInterface) => {
    return (
        <div className='staticProps' id={props.altStyle ? "alt" : ""}>
            <h1>{props.title}</h1>
            <div className='staticProps-itemsWrapper'>
                {props.items?.map((item, index) => (
                    <StaticPropsItem key={index} {...item} />
                ))}
            </div>
        </div>
    )
}


const StaticPropsItem = (props: StaticPropsItemInterface) => {
    return (
        <div className='staticProps-item'>
            {props.icon}
            <h1>{props.title}</h1>
            <p>{props.text}</p>
        </div>

    )
}