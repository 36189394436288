import React from 'react'
import './Home.scss'
import './HomeMobile.scss'
import { Element, Link } from 'react-scroll/modules'
import { useGlobalState } from '../../../GlobalStateContext'
import useRedirect from '../../../Utils/redirect'
import SEO from '../../../Components/SEO'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { Trans, useTranslation } from 'react-i18next'


const Home = () => {
    const redirect = useRedirect()
    const [globalState, globalActions] = useGlobalState()
    const { i18n, t } = useTranslation()
    return (
        <Element name='Home'>
            <SEO
                title='APPI | Home'
                description='Build integrations fast and deliver a native integration marketplace experience to your customers. Built in a way devs love.'
                name='Home'
                type='website'
                keywords={['APPI', 'Home', 'Chat', 'Experts', 'Create an account', 'Read more']}
            />
            <div className='home'>
                <div className='home-titleWrapper'>
                    <h1 className='mainTitle'>
                        <Trans i18nKey='landing.homeTitle'>
                            Ready for your <b>AI</b> journey?
                        </Trans>
                    </h1>
                    <div className='home-description'>
                        {t('landing.homeSubtitle')}
                    </div>
                    <div className='home-titleWrapper-buttonsWrapper'>
                        <Link to='video' className='home-titleWrapper-buttonsWrapper-button' spy={true}
                              smooth={true} duration={500} offset={50} id={'first'}>
                            <h1>{t('landing.buttons.title_1')}</h1>
                            <h2>{t('landing.buttons.description_1')}</h2>
                            <MdOutlineKeyboardArrowRight className='home-titleWrapper-buttonsWrapper-button-icon' />
                        </Link>
                        <div className='home-titleWrapper-buttonsWrapper-button' id={'second'} onClick={() => {
                            if (!globalState.user)
                                redirect('', 'login')
                            else
                                redirect('chat', '')
                        }}>
                            <h1>{t('landing.buttons.title_2')}</h1>
                            <h2>{t('landing.buttons.description_2')}</h2>
                            <MdOutlineKeyboardArrowRight className='home-titleWrapper-buttonsWrapper-button-icon' />
                        </div>
                        <a href='https://appi.chat' className='home-titleWrapper-buttonsWrapper-button' id={'third'}>
                            <h1>{t('landing.buttons.title_3')}</h1>
                            <h2>{t('landing.buttons.description_3')}</h2>
                            <MdOutlineKeyboardArrowRight className='home-titleWrapper-buttonsWrapper-button-icon' />
                        </a>
                    </div>
                </div>
            </div>
        </Element>
    )
}

export default Home
