import './FunTool.scss'
import React, { useEffect, useState } from 'react'
import { AiExpert } from './Components/AiExpert/AiExpert'
import { MultiExpert } from './Components/MultiExpert/MultiExpert'

interface FunToolProps {
    funToolConfig: any[]
}

export const FunTool = (props: FunToolProps) => {

    const [selected, setSelected] = useState<string>('AI Expert')
    const [config, setConfig] = useState<FunToolInterface>(props.funToolConfig[0])

    useEffect(() => {
        setConfig(props.funToolConfig.find(item => item.name === selected) || props.funToolConfig[0])
    }, [selected])

    return (
        <div className='funTool'>
            <h1 className='funTool-title'>See it in <span
                className='funTool-title-highlight'
            >action</span></h1>
            <div className='funTool-content'>
                <div className='funTool-content-choose'>
                    {props.funToolConfig.map((item, index) => (
                        <div key={index}
                             className={selected === item.name ? 'funTool-content-choose-item funTool-content-choose-item-selected' : 'funTool-content-choose-item'}
                             onClick={() => setSelected(item.name)}
                        >
                            {item.name}
                        </div>
                    ))}
                </div>
                <div className='funTool-content-panel'>
                    {config.component}
                </div>
            </div>
        </div>
    )

}

interface FunToolInterface {
    name: string,
    component?: any
}