import React, { useEffect, useState } from 'react'
import './Application.scss'
import './ApplicationMobile.scss'
import { Color } from 'react-color-surge/dist/index'
import {
    getExpert,
    getSummarizer, modifyDefaultExperts, modifyDefaultSummarizer,
    modifyFavExperts,
    modifyFavSummarizers,
} from '../../Requests/agentRequests'
import { FaRobot } from 'react-icons/fa'
import { getUserAccount } from '../../Requests/userRequests'
import { AiFillStar, AiOutlineCheckSquare, AiOutlineLoading, AiOutlineStar, AiOutlineUser } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGlobalState } from '../../GlobalStateContext'
import { AgentInterface } from '../../Interfaces/agentsInterfaces'
import useRedirect from '../../Utils/redirect'
import FreeBlobEnv from '../../Components/Blobs/FreeBlob/FreeBlobEnv'

interface AgentPageInterface {
    handleError: Function
}

const Application = ({ handleError }: AgentPageInterface) => {
    const location = useLocation()
    const [backgroundColor, setBackgroundColor] = useState<string>('')
    const [agent, setAgent] = useState<any>(null)
    const [author, setAuthor] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate()
    const [summarizer, setSummarizer] = useState<any>(false)
    const [globalState, globalActions] = useGlobalState()
    const redirect = useRedirect()
    useEffect(() => {
        const id = location.pathname.split('/')[2]
        getExpert(id).then((res) => {
            setAgent(res)
        }).catch((err) => {
            getSummarizer(id).then((res2) => {
                setAgent(res2)
                setSummarizer(true)
            })
        })
    }, [])
    useEffect(() => {
        if (agent?.authorId)
            getUserAccount(agent?.authorId).then((res) => {
                setAuthor(res)
                setLoading(false)
            })
    }, [agent])

    const handleFavClick = () => {
        if (!summarizer) {
            let favs = globalState.user?.favouriteExperts.map((e: AgentInterface) => e.id)
            if (!favs?.includes(agent?.id))
                favs?.push(agent?.id)
            else
                favs = favs?.filter((e: string) => e !== agent?.id)
            if (favs)
                modifyFavExperts(favs).then((res) => {
                    globalActions.setUser(res)
                }).catch((err) => {
                    handleError(err)
                })
        } else {
            let favs = globalState.user?.favouriteSummarizers.map((e: AgentInterface) => e.id)
            if (!favs?.includes(agent?.id))
                favs?.push(agent?.id)
            else
                favs = favs?.filter((e: string) => e !== agent?.id)
            if (favs)
                modifyFavSummarizers(favs).then((res) => {
                    globalActions.setUser(res)
                }).catch((err) => {
                    handleError(err)
                })
        }
    }
    const handleDefaultClick = () => {
        if (!summarizer) {
            let defaults = globalState.user?.defaultExperts.map((e: AgentInterface) => e.id)
            if (!defaults?.includes(agent?.id))
                defaults?.push(agent?.id)
            else
                defaults = defaults?.filter((e: string) => e !== agent?.id)
            if (defaults)
                modifyDefaultExperts(defaults).then((res) => {
                    globalActions.setUser(res)
                }).catch((err) => {
                    handleError(err)
                })
        } else {
            const defaultSummarizer = globalState.user?.defaultSummarizer
            if (defaultSummarizer !== agent?.id)
                modifyDefaultSummarizer(agent?.id).then((res: any) => {
                    globalActions.setUser(res)
                }).catch((err: Error) => {
                    handleError(err)
                })
            else
                modifyDefaultSummarizer('').then((res) => {
                    globalActions.setUser(res)
                }).catch((err) => {
                    handleError(err)
                })
        }
    }
    return (
        <div className='applicationWrapper' id={summarizer ? 'applicationSummarizer' : ''}>
            <FreeBlobEnv width={'100%'} height={'100%'} min={40} max={20} />
            {loading &&
                <div className='application-loading'>
                    <AiOutlineLoading className='application-loading-icon' />
                </div>
            }
            {!loading &&
                <div className='application'>
                    {agent && (
                        <div className='informationPanel'>
                            {agent?.logoUrl &&
                                <FaRobot className='informationPanel-logo informationPanel-logo-default'
                                         style={{ color: agent?.logoColorHex }} />}
                            <h1>{agent?.name}</h1>
                            {
                                summarizer ? (
                                        globalState.user?.favouriteSummarizers.map((e: any) => e.id).includes(agent?.id) ?
                                            <AiFillStar className='informationPanel-favourite'
                                                        onClick={handleFavClick} /> :
                                            <AiOutlineStar
                                                className='informationPanel-favourite informationPanel-favourite-outline'
                                                onClick={handleFavClick} />) :
                                    (
                                        globalState.user?.favouriteExperts.map((e: any) => e.id).includes(agent?.id) ?
                                            <AiFillStar className='informationPanel-favourite'
                                                        onClick={handleFavClick} /> :
                                            <AiOutlineStar
                                                className='informationPanel-favourite informationPanel-favourite-outline'
                                                onClick={handleFavClick} />
                                    )
                            }
                            {
                                summarizer ? (
                                        globalState.user?.defaultSummarizer === agent?.id ?
                                            <AiOutlineCheckSquare
                                                className='informationPanel-default informationPanel-default-check'
                                                onClick={handleDefaultClick} /> :
                                            <AiOutlineCheckSquare className='informationPanel-default'
                                                                  onClick={handleDefaultClick} />) :
                                    (
                                        globalState.user?.defaultExperts.map((e: any) => e.id).includes(agent?.id) ?
                                            <AiOutlineCheckSquare
                                                className='informationPanel-default informationPanel-default-check'
                                                onClick={handleDefaultClick} /> :
                                            <AiOutlineCheckSquare className='informationPanel-default'
                                                                  onClick={handleDefaultClick} />
                                    )
                            }
                        </div>
                    )}
                    <div className='application-categories'>
                        {agent?.tags.map((category: string, index: number) => (
                            <div className='application-categories-item' key={index}>
                                <h2>{category}</h2>
                            </div>
                        ))}
                    </div>
                    <div className='application-description'>
                        <div className='application-description-description'>
                            <p>{agent?.description}</p>
                        </div>
                        <div className='application-description-author'>
                            <div className='application-description-author-card'
                                 onClick={() => navigate('/developer/' + author?.id)}>
                                {author?.img ? <img src={author?.img} alt='author' /> :
                                    <AiOutlineUser className='application-description-author-card-icon' />}
                                <p>{author?.name} {author?.surname}</p>
                                <div className='application-description-author-card-link'
                                     onClick={() => navigate('/developer/' + author?.id)}
                                >View profile
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='application-chat' onClick={() => {
                        if (summarizer)
                            redirect('chat', '?new&summarizer=' + agent?.id)
                        else
                            redirect('chat', '?new&expert=' + agent?.id)
                    }}>
                        Try it now!
                    </div>
                </div>}

        </div>
    )
}

export default Application
