import './StaticPrompt.scss'

interface StaticPrompt {
    title: string
    subtitle?: string
}

export const StaticPrompt = (props: StaticPrompt) => {
    return (
        <div className='staticPrompt'>
            <div className='staticPrompt-text'>
                <h1 className='staticPrompt-text-title'>{props.title}</h1>
                <h2 className='staticPrompt-text-subtitle'>{props.subtitle}</h2>
            </div>
            <div className='staticPrompt-buttons'>
                <a href="/contact" className='staticPrompt-buttons-button'>Contact us</a>
            </div>

        </div>
    )
}