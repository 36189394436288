import './CustomInstructionItem.scss'
import { useState } from 'react'
import { AiOutlineEdit, AiOutlineRight } from 'react-icons/ai'
import { MdOutlineIntegrationInstructions } from 'react-icons/md'
import { BiTrashAlt } from 'react-icons/bi'
import { deleteCustomInstruction } from '../../../../Requests/customProfilesRequests'

interface CustomInstructionItemInterface {
    instruction: any
    selectedCustomInstruction: any
    setSelectedCustomInstruction: Function
    setEditInstruction: Function
}

export const CustomInstructionItem = ({
                                          instruction,
                                          setSelectedCustomInstruction,
                                          selectedCustomInstruction,
                                          setEditInstruction,
                                      }: CustomInstructionItemInterface) => {
    const [active, setActive] = useState<boolean>(false)

    let className = 'customInstructionItem'

    if (selectedCustomInstruction?.id === instruction?.id) {
        className += ' customInstructionItem-selected'
    }

    if (active) {
        className += ' customInstructionItem-active'
    }

    const deleteInstructionFunc = () => {
        deleteCustomInstruction(instruction.id).then(() => {
            setSelectedCustomInstruction()
        })
    }

    return (
        <div
            className={className}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            onClick={() => setSelectedCustomInstruction(instruction)}
        >
            {/*<MdOutlineIntegrationInstructions className='customInstructionItem-icon' />*/}
            <h1>{instruction?.name}</h1>
            {(active && instruction.id !== "-1") && <div className='customInstructionItem-button'>
                <AiOutlineEdit id='edit' onClick={(e) => {
                    e.stopPropagation()
                    setEditInstruction()
                }} />
                <BiTrashAlt id='delete' onClick={(e) => {
                    e.stopPropagation()
                    deleteInstructionFunc()
                }} />
            </div>}
        </div>
    )
}