import React, { useEffect } from 'react'
import './AgentCard.scss'
import './AgentCardMobile.scss'
import { FaLock, FaRobot } from 'react-icons/fa'
import { AnimatedBlob } from '../Blobs/AnimatedBlob/AnimatedBlob'
import { hexToCSSFilter } from 'hex-to-css-filter'
import { useNavigate } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import { IconType } from 'react-icons'

interface AgentCardInterface {
    agent: any;
    type?: string
    description?: boolean
    small?: boolean
    colored?: boolean
    id?: string
    nav?: string
    onClick?: Function
    onClickRedirect?: boolean
}

const AgentCard = ({
                       agent,
                       type,
                       description = true,
                       small = false,
                       colored = true,
                       id = '',
                       nav = 'default',
                       onClickRedirect = true,
                       onClick = () => {
                       },
                   }: AgentCardInterface) => {

    const navigate = useNavigate()
    const icons = FaIcons as { [key: string]: IconType }
    const CurrentIconComponent = agent?.logoUrl ? icons[agent?.logoUrl] : icons['FaRobot']

    const navigateFunc = () => {
        if (onClickRedirect) {
            if (nav === 'default')
                navigate('/agents/' + agent.id)
            if (nav === 'dev')
                navigate('/developer/agents/' + agent.id)
        }
        onClick()
    }

    const [color, setColor] = React.useState(type === 'expert' ? '#CE8B09' : '#ED1660')
    const [filters, setFilters] = React.useState('')

    useEffect(() => {
        if (agent && agent.logoColorHex) {
            const filters_base = hexToCSSFilter(agent?.logoColorHex).filter
            if (filters_base)
                setFilters(filters_base.substring(0, filters_base.length - 1))
        }
    }, [color])

    let className = 'agentCard'
    if (small) {
        className += ' agentCard-small'
    }
    if (!colored) {
        className += ' agentCard-noColor'
    }
    const blob_1 = Math.floor(Math.random() * (11))
    return (
        <div className={className}
             onClick={() => navigateFunc()}
             id={id}
        >
            {(!agent.isPublic && agent.hasOwnProperty('isPublic')) && <FaLock className='agentCard-lock' />}

            <CurrentIconComponent className='agentCard-logo agentCard-logo-default'
                                  style={{ color: agent.logoColorHex }} />
            <AnimatedBlob number={blob_1} animated={false}
                          blobStyle={{
                              filter: filters,
                          }}
                          id={'blob_1'}
            />
            <div className='agentCard-content'>
                <div className='agentCard-title'
                    // style={{ color: agent.logoColorHex }}
                >{agent.name}</div>
                <div className='agentCard-author'>@APPI</div>
                {description && small === false && < div className='agentCard-description'>
                    {agent?.shortDescription.slice(0, 100)}
                    {agent?.shortDescription.length > 100 && '...'}
                </div>}
                <div className='categoriesSection'>
                    {agent?.tags.map((category: string, index: any) => (
                        <div
                            className='categoriesSectionItem'
                            key={index}
                        >
                            {category}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AgentCard
