import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend, Filler, ChartOptions,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { CallsInterface } from '../Subpages/Stats/Stats'
import { verticalLinePlugin } from './plugins/verticalLinePlugin'

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    verticalLinePlugin,
)


export const NumberOfRequestsPerDay = ({ data, lineChartConfig, setLineChartConfig }: {
    data: CallsInterface[],
    lineChartConfig: any,
    setLineChartConfig: any
}) => {
    const [preparedData, setPreparedData] = useState<any>(null)
    useEffect(() => {
        let agents_count: {
            [key: string]: number
        } = {}
        let summarizers_count: {
            [key: string]: number
        } = {}
        let total_count: {
            [key: string]: number
        } = {}

        for (const day of data) {
            agents_count[day.date] = day.expertCallsSum
            summarizers_count[day.date] = day.summarizerCallsSum
            total_count[day.date] = day.expertCallsSum + day.summarizerCallsSum
        }

        const labels = Object.keys(agents_count).map((day: any) => day.split('T')[0])
        setPreparedData({
            labels: labels,
            datasets: [
                {
                    label: 'Total',
                    data: Object.keys(total_count).map((day: any) => total_count[day]),
                    backgroundColor: 'rgba(244,244,244,0.40)',
                    borderColor: 'rgb(244,244,244)',
                    borderWidth: 2,
                    fill: true,
                    tension: 0.4,
                    hidden: true,
                },
                {
                    label: 'Summarizers',
                    data: Object.keys(summarizers_count).map((day: any) => summarizers_count[day]),
                    backgroundColor: 'rgba(237,22,96,0.4)',
                    borderColor: 'rgba(237,22,96,1)',
                    borderWidth: 2,
                    fill: true,
                    tension: 0.4,
                },
                {
                    label: 'Agents',
                    data: Object.keys(agents_count).map((day: any) => agents_count[day]),
                    backgroundColor: 'rgba(206,139,9,0.40)',
                    borderColor: 'rgb(206,139,9)',
                    borderWidth: 2,
                    fill: 'origin',
                    tension: 0.4,
                },

            ],
        })
    }, [data])

    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position:
                    'bottom' as const,
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 15,
                },
            },
            y: {
                beginAtZero: true,
            },
        },
        elements: {
            point: {
                radius: 2,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
    }
    return (
        <div className='numberOfRequestsPerDay'>
            {preparedData && <Line
                height='100%'
                data={preparedData}
                options={options}
            />}
        </div>
    )
}