import React, { useEffect, useState } from 'react'
import './Organization.scss'
import { AiOutlineHome, AiOutlineDollarCircle, AiOutlineUser, AiOutlineLoading } from 'react-icons/ai'
import { IoStatsChartSharp } from 'react-icons/io5'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useWindowDimensions } from '../../Utils/hooks'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'
import { useGlobalState } from '../../GlobalStateContext'
import { FaBook } from 'react-icons/fa'
import SEO from '../../Components/SEO'
import Overview from './Subpages/Overview/Overview'
import { OrganizationInterface } from '../../Interfaces/organizationInterfaces'
import { getMyOrganizations, getOrganizationById } from '../../Requests/organizationsRequests'
import { OrganizationTeam } from './Subpages/OrganizationTeam/OrganizationTeam'
import { Dashboard } from '../../Components/Dashboard/Dasbhoard'
import { OrganizationAgents } from './Subpages/OrganizationAgents/OrganizationAgents'
import Dropdown from '../../Components/Dropdown/Dropdown'

enum Page {
    Overview,
    Stats,
    Team,
    Payment,
    Account,
    Experts,
}

interface organizationInterface {
    sendAlert: Function
}

const Organization = ({ sendAlert }: organizationInterface) => {
    const dims = useWindowDimensions()
    const params = useLocation()
    const navigate = useNavigate()
    const mobile = dims.width < 991
    const [initialEmployee, setInitialEmployee] = useState<number | null>(null);
    const [page, setPage] = useState<Page>(Page.Overview)
    const [searchParams, setSearchParams] = useSearchParams()
    const [sidebar, setSidebar] = useState(false)
    const [globalState, globalActions] = useGlobalState()
    const [userRole, setUserRole] = useState<string>('Member')
    const [userId, setUserId] = useState<string | null>(null)

    const [organizations, setOrganizations] = useState<OrganizationInterface[]>([])
    const defaultOrganization: OrganizationInterface = {
        id: '-1',
        name: 'None',
        contactEmail: '',
        members: [],
        // organizationOwnerId: '',
        assignedExpertsIds: [],
    }
    const doesntExist: OrganizationInterface = {
        id: '-2',
        name: 'None',
        contactEmail: '',
        members: [],
        // organizationOwnerId: '',
        assignedExpertsIds: [],
    }
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationInterface>(defaultOrganization)
    useEffect(() => {
        if (globalState.user) {
            getMyOrganizations().then((res) => {
                setOrganizations(res)
                if (res.length === 1) {
                    getOrganizationById(res[0].id).then((org) => setSelectedOrganization(org))
                } else if (res.length > 1) {

                    const id = params.pathname.split('/')[2]
                    getOrganizationById(id).then((organization) => setSelectedOrganization(organization)).catch(() => {
                        setSelectedOrganization(doesntExist)
                    })
                }
            })
        }
    }, [globalState.user])

    useEffect(() => {
        if (selectedOrganization) {
            const id = params.pathname.split('/')[2]
            if (id !== selectedOrganization.id && organizations.map(org => org.id).includes(id))
                navigate(`/organization/${selectedOrganization.id}`)
        }
    }, [selectedOrganization])

    useEffect(() => {
        if (!selectedOrganization || !globalState.user) {
            return
        }
        const user: any = selectedOrganization.members?.find(user => user.id === globalState.user?.id)
        if (user) {
            setUserRole(user.role)
            setUserId(user.id)
        }
    }, [selectedOrganization, globalState.user])

    const fetchSelectedOrganization = async (props: any) => {
        getOrganizationById(props.id).then((organization) => setSelectedOrganization(organization))
    }

    const handleEmployeeClick = (employeeId: string | null) => {
        if (employeeId === null) {
            setInitialEmployee(null);
            return;
        }
        const arrayIndex: number = selectedOrganization.members.findIndex(employee => employee.id === employeeId);
        setInitialEmployee(arrayIndex);
        navigate(`/organization/${selectedOrganization.id}?page=Team`);
    }

    const organizationPages = [
        {
            label: 'Overview',
            icon: <AiOutlineHome className='dashboard-sidebar-menu-item-img' />,
            component: <Overview organization={selectedOrganization} team={selectedOrganization.members} handleEmployeeClick={handleEmployeeClick} userRole={userRole} userId={userId} />,
        },
        {
            label: 'Team',
            icon: <AiOutlineUser className='dashboard-sidebar-menu-item-img' />,
            component: <OrganizationTeam organization={selectedOrganization} userRole={userRole} initialEmployee={initialEmployee}/>,
        },
        {
            label: 'Stats',
            icon: <IoStatsChartSharp className='dashboard-sidebar-menu-item-img' />,
            component: <div>Stats Page</div>,
        },
        {
            label: 'Payment',
            icon: <AiOutlineDollarCircle className='dashboard-sidebar-menu-item-img' />,
            component: <div>Payment Page</div>,
        },
        {
            label: 'Agents',
            icon: <FaBook className='dashboard-sidebar-menu-item-img' />,
            component: <OrganizationAgents organization={selectedOrganization} />,
        },
    ]
    if (!globalState.user || !selectedOrganization) {
        return (
            <div className='organization organization-loading'>
                <AiOutlineLoading className='organization-loading-icon' />
            </div>
        )
    } else
        return (
            <div className='organization' onClick={() => setSidebar(false)}>
                <SEO
                    title='APPI | Organization'
                    description='Account settings'
                    keywords={['appi', 'account', 'settings', 'user', 'developer', 'api', 'keys', 'delete', 'danger', 'zone']}
                    type='website'
                    name='APPI Organization'
                />
                {selectedOrganization.id !== defaultOrganization.id &&
                    <Dashboard pages={organizationPages} SidebarTitle={
                        // <div className='dashboard-sidebar-title'>
                        //     <h1>{selectedOrganization.name}</h1>
                        // </div>
                        <Dropdown<OrganizationInterface> selected={selectedOrganization}
                                                         setSelected={fetchSelectedOrganization}
                                                         options={organizations} displayAttribute='name'
                                                         createNew={() => navigate('/organization/create')}
                        />
                    } />}
                {selectedOrganization.id === doesntExist.id &&
                    <div className='organization-noOrganization'>
                        <h1>Organization does not exist</h1>
                    </div>
                }
            </div>
        )
}

export default Organization