import './StaticStats.scss'
import { CiCircleInfo } from 'react-icons/ci'
import { FaInfo } from 'react-icons/fa'

interface StatsConfigInterface {
    percentage: number
    text: string
    source: string
}

interface StaticStatsProps {
    title?: string
    statsConfig: StatsConfigInterface[];
}

export const StaticStats = (props : StaticStatsProps) => {
    return (
        <div className='staticStats'>
            <div className='staticStats-title'>{props.title}</div>
            <div className='staticStats-stats'>
                {props.statsConfig.map((config, index) => {
                    return (
                        <div className='staticStats-stats-stat' key={index}
                             style={{
                                 '--border-fill': `inset(${100 - config.percentage}% 0% 0% 0%)`,
                             } as React.CSSProperties}>
                            <div className='staticStats-stats-stat-percentage'>{config.percentage}%</div>
                            <div className='staticStats-stats-stat-text'>{config.text}</div>
                            <a href={config.source} className='staticStats-stats-stat-source' target='_blank'
                               rel='noreferrer'>
                                <FaInfo/>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}