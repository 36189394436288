import './FunToolItem.scss'
import {Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill } from 'react-icons/bs'

interface FunToolItemInterface {
    title: string,
    description: string,
    num: number,
    children?: any
    active?: boolean
}

export const FunToolItem = (props: FunToolItemInterface) => {
    const icons = [
        <Bs1CircleFill size='40px' className='icon'/>,
        <Bs2CircleFill size="40px" className='icon'/>,
        <Bs3CircleFill size="40px" className='icon'/>,
        <Bs4CircleFill size="40px" className='icon'/>,
        <Bs5CircleFill size="40px" className='icon'/>
    ]

    return (
        <div className={props.active ? 'funToolItem funToolItem-active' : 'funToolItem'}>
            <div className='funToolItem-left'>
                <div className='funToolItem-left-heading'>
                    {icons[props.num-1]}
                    <div className='funToolItem-left-heading-title'>
                        {props.title}</div>
                </div>
                <div className='funToolItem-left-description'>{props.description}</div>
            </div>
            <div className='funToolItem-right'>
                {props.children}
            </div>
        </div>
    )
}
