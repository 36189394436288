import './StaticList.scss'
import { ReactNode } from 'react'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'

interface StaticListInterface {
    title: string
    points?: PointInterface[]
    flipped?: boolean
}

interface PointInterface {
    title: string
    text: string
    icon: ReactNode

}

export const StaticList = (props: StaticListInterface) => {
    if (!props.flipped) return (
        <div className='staticList'>
            <div className='staticList-title'>
                <h1>{props.title}</h1>
            </div>
            <div className='staticList-list'>
                {props.points && props.points.map((point, index) => (
                    <StaticListPoint
                        key={index}
                        title={point.title}
                        text={point.text}
                        icon={point.icon}
                    />
                ))}
            </div>
        </div>
    )
    return (
        <div className='staticList'>
            <div className='staticList-list'>
                {props.points && props.points.map((point, index) => (
                    <StaticListPoint
                        key={index}
                        title={point.title}
                        text={point.text}
                        icon={point.icon}
                    />
                ))}
            </div>
            <div className='staticList-title'>
                <h1>{props.title}</h1>
            </div>
        </div>
    )
}

const StaticListPoint = (props: PointInterface) => {
    return (
        <div className='staticListPoint'>
            <div className='staticListPoint-icon'>
                {props.icon}
            </div>
            <div className='staticListPoint-text'>
                <h1>{props.title}</h1>
                <h2>{props.text}</h2>
            </div>
        </div>
    )
}