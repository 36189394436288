import { createStore, createHook, Action } from 'react-sweet-state'
import { UserInterface } from './Interfaces/userInterfaces'

interface GlobalStateInterface {
    user: UserInterface | null
    developer: boolean
}


type SetUserAction = Action<GlobalStateInterface, UserInterface | null>;
type SetDeveloperAction = Action<GlobalStateInterface, boolean>;

const Store = createStore<GlobalStateInterface, {
    setUser: (user: UserInterface | null) => SetUserAction,
    setDeveloper: (developer: boolean) => SetDeveloperAction,
}>({
    initialState: {
        user: null,
        developer: false,
    },
    actions: {
        setUser: (user: UserInterface | null): SetUserAction => ({ setState }) => {
            setState({ user: user })
        },
        setDeveloper: (developer: boolean): SetDeveloperAction => ({ setState }) => {
            setState({ developer: developer })
        },
    },
    name: 'globalState',
})

export const useGlobalState = createHook(Store)