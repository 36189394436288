import './Labs.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import { StaticPrompt } from '../../Components/StaticPrompt/StaticPrompt'
import Footer from '../../../MainSection/Footer/Footer'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { StaticFlow } from '../../Components/StaticFlow/StaticFlow'
import { StaticProps } from '../../Components/StaticProps/StaticProps'
import { StaticGrid } from '../../Components/StaticGrid/StaticGrid'
import { AiOutlineSafetyCertificate, AiOutlineStock } from 'react-icons/ai'
import { IoIosGitNetwork } from 'react-icons/io'
import { IoBarbell } from 'react-icons/io5'
import { RiTeamLine } from 'react-icons/ri'

import { FaBook } from 'react-icons/fa'
import { StaticUs } from '../../Components/StaticUs/StaticUs'


const image = require('../../../../Images/Assets/About/img1.png')
export const Labs = () => {
    return (
        <div className='solutionsBusinesses-wrapper'>
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className='solutionsBusinesses'>
                <StaticTitle
                    title={'Labs'}
                    subtitle={'Join us on the quest to innovate and create.'}
                    buttons={{
                        main: 'Apply now',
                        secondary: 'Read more',
                    }}
                />

                <StaticProps
                    title='What we offer'
                    items={[
                        {
                            'title': 'Team-Based Projects',
                            'text': 'Join our project teams to work on real products, enhancing your practical experience and teamwork skills.',
                            'icon': <RiTeamLine />,
                        },
                        {
                            'title': 'Focused Training',
                            'text': 'Benefit from specialized training sessions that are designed to boost your tech skills and industry knowledge.',
                            'icon': <IoBarbell />,
                        },
                        {
                            'title': 'Certification Programs',
                            'text': 'Achieve certifications that validate your skills and set you apart in the competitive tech landscape from the very start.',
                            'icon': <AiOutlineSafetyCertificate />,
                        },
                    ]}
                />

                <StaticProps
                    title='What you earn'
                    items={[
                        {
                            'title': 'Networking Opportunities',
                            'text': 'Expand your professional network by collaborating with industry experts and peers, opening doors to new opportunities.',
                            'icon': <IoIosGitNetwork />,
                        },
                        {
                            'title': 'Startup Immersion',
                            'text': 'Kickstart your journey with startups. Immerse yourself in the dynamic startup ecosystem and learn from the ground up.',
                            'icon': <AiOutlineStock />,
                        },
                        {
                            'title': 'Applied Knowledge',
                            'text': 'Apply your knowledge in real-world projects, adding valuable, tangible outcomes to your portfolio.',
                            'icon': <FaBook />,
                        },
                    ]}
                    altStyle={true}
                />

                <StaticGrid
                    title='Our projects'
                    items={[
                        {
                            'title': 'Text embeddings exploration',
                            'text': 'We provide a comprehensive analysis of your data, identifying patterns and trends that can be leveraged to drive business growth.',
                            'img': image,
                        },
                        {
                            'title': 'Data Analysis',
                            'text': 'We provide a comprehensive analysis of your data, identifying patterns and trends that can be leveraged to drive business growth.',
                            'img': image,

                        },
                    ]}
                />
                <StaticUs />
                <StaticPrompt title={'Ready to get started?'}
                              subtitle={'Secure your AI career today'} />
                <Footer />
            </div>
        </div>
    )
}