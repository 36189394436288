import './ExpertCreation.scss'
import { FunToolItem } from '../../FunToolItem/FunToolItem'
import { useState } from 'react';
import { DummyFormDesc } from './DummyForm/DummyFormDesc'
import { DummyFormPrompts } from './DummyForm/DummyFormPrompts';
import { DummyModelSettings } from './DummyForm/DummyModelSettings';
import DummyAgentCard from './DummyAgent/DummyAgent';
enum Phase {
    Naming,
    Prompting,
    Parameters,
    Launch
}

export const ExpertCreation = () => {

    const [phase, setPhase] = useState<Phase>(Phase.Naming);
    const [name, setName] = useState<string>('');
    const [shortDesc, setShortDesc] = useState<string>('');

    const handleClickStep1 = (_name: string, _shortDesc: string) => {
        setName(_name);
        setShortDesc(_shortDesc);
        if (name.length > 0 && shortDesc.length > 0) setPhase(Phase.Prompting);
    }

    return (
        <div className="expertCreation">
            <FunToolItem
                title='Name your agent'
                description='Something memorable'
                num={1}
                active={phase === Phase.Naming}
            >
                {phase >= Phase.Naming && <DummyFormDesc onClick={handleClickStep1} active={phase === Phase.Naming}/>}
            </FunToolItem>
            
            <FunToolItem
                title='Instruct its actions'
                description='System prompt tells the expert about itself, expert prompt is for starting conversations'
                num={2}
                active={phase === Phase.Prompting}
            >
                {phase >= Phase.Prompting && <DummyFormPrompts onClick={() => {setPhase(phase + 1)}} active={phase === Phase.Prompting}/>}
            </FunToolItem>

            <FunToolItem
                title='Adjust parameters'
                description='A{P}PI makes it possible to adjust hyperparameters (for example for ChatGPT)'
                num={3}
                active={phase === Phase.Parameters}
            >
                {phase >= Phase.Parameters && <DummyModelSettings onClick={() => {setPhase(phase + 1)}} active={phase === Phase.Parameters}/>}
            </FunToolItem>

            <FunToolItem
                title='Launch your expert'
                description='Remember about adding tags!'
                num={4}
                active={phase === Phase.Launch}
            >
            {phase === Phase.Launch && <DummyAgentCard name={name} description={shortDesc}/>}
            </FunToolItem>
        </div>
    )
}