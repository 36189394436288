import './StaticUs.scss'
import { FaDiscord, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { IoMail } from 'react-icons/io5'
import React from 'react'

export const StaticUs = () => {
    return (
        <div className='staticUs'>
            <h1 className='staticUs-title'>Meet our team</h1>
            <div className='staticUs-content'>
                <div className='staticUs-content-person'>
                    <img src={require('../../../../Images/Assets/Labs/bartek.jpeg')} alt='person' />
                    <h1>Bartosz Kostarczyk</h1>
                    <h2>CEO</h2>

                    <p>
                        John is the CEO of our company. He has a lot of experience in the tech industry and is a great
                        leader.
                    </p>
                    <div className='staticUs-content-person-socials'>
                        <a href='https://www.linkedin.com/company/90967506/'>
                            <FaLinkedin className='staticUs-content-person-socials-link' />
                        </a>
                        <a href='mailto:office@appi-marketplace.com'>
                            <IoMail className='staticUs-content-person-socials-link' />
                        </a>
                        <a href='https://www.linkedin.com/company/90967506/'>
                            <FaFacebook className='staticUs-content-person-socials-link' />
                        </a>
                    </div>

                </div>
                <div className='staticUs-content-person'>
                    <img src={require('../../../../Images/Assets/Labs/adam.jpeg')} alt='person' className='staticUs-content-person-flipped'/>
                    <h1>Adam Kaniasty</h1>
                    <h2>CTO</h2>

                    <p>
                        John is the CEO of our company. He has a lot of experience in the tech industry and is a great
                        leader.
                    </p>
                    <div className='staticUs-content-person-socials'>npmno
                        <a href='https://www.linkedin.com/company/90967506/'>
                            <FaLinkedin className='staticUs-content-person-socials-link' />
                        </a>
                        <a href='mailto:office@appi-marketplace.com'>
                            <IoMail className='staticUs-content-person-socials-link' />
                        </a>
                        <a href='https://www.linkedin.com/company/90967506/'>
                            <FaFacebook className='staticUs-content-person-socials-link' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}