import './CustomAgents.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import { StaticTextWithImage } from '../../Components/StaticTextWithImage/StaticTextWithImage'
import { StaticPrompt } from '../../Components/StaticPrompt/StaticPrompt'
import Footer from '../../../MainSection/Footer/Footer'
import { StaticList } from '../../Components/StaticList/StaticList'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { StaticFlow } from '../../Components/StaticFlow/StaticFlow'
import { FunTool } from '../../../MainSection/FunTool/FunTool'
import { ExpertCreation } from '../../../MainSection/FunTool/Components/ExpertCreation/ExpertCreation'

export const CustomAgents = () => {

    const funToolConfig = [
        {
            'name': 'Agent creation',
            'component': <ExpertCreation/>
        }
    ]


    return (
        <div className='customAgents-wrapper'>
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className='customAgents'>
                <StaticTitle
                    title={'Custom agents'}
                    subtitle={'Earn money through your specialized agents'}
                    buttons={{
                        main: 'Contact us',
                        secondary: 'Read more',
                    }}
                />

                <StaticFlow
                    title = 'Let your agents work for you'
                    bullets={[
                        {
                            title: 'Sth about agents earning money',
                            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                        }
                    ]}
                />

                <FunTool funToolConfig={funToolConfig}/>
                                
                <Footer />
            </div>
        </div>
    )
}