import React from 'react'
import './Blobs.scss'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'

export default function Blobs() {
    return (
        <div className='blobs'>
            {/*<AnimatedBlob number={3} top={'70vh'} left={'15vh'} scale={3} rotate={'-45deg'} />*/}
            <AnimatedBlob number={5} top={'35vh'} left={'40vw'} scale={5} rotate={'135deg'} />
            <AnimatedBlob number={9} top={'120vh'} left={'40vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={4} top={'340vh'} right={'20vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={13} top={'430vh'} left={'-10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'510vh'} right={'20vh'} scale={6} rotate={'45deg'} />
            <AnimatedBlob number={4} top={'600vh'} left={'20vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={3} top={'680vh'} right={'20vh'} scale={4} rotate={'45deg'} />
        </div>
    )
}
