import React, { useEffect, useRef, useState } from 'react'
import './SearchBar.scss'
import './SearchBarMobile.scss'
import { useNavigate } from 'react-router-dom'
import { IoMdSearch } from 'react-icons/io'
import AgentCard from '../AgentCard/AgentCard'

interface SearchBarProps {
    agents: any[]
}

const SearchBar = ({ agents }: SearchBarProps) => {
    const [searchText, setSearchText] = useState('')
    const [searchResultsOpen, setSearchResultsOpen] = useState(false)
    const [searchResultAgents, setSearchResultAgents] = useState<any[]>([])
    const [categories, setCategories] = useState<any>([])
    const navigate = useNavigate()

    const handleInputChange = (event: any) => {
        setSearchText(event.target.value)
    }
    useEffect(() => {
        if (searchText.length > 2) {
            setTimeout(() => {
                setSearchResultsOpen(true)
                const newAgents = agents.filter(item => item.name.toLowerCase().includes(searchText.toLowerCase()))
                if (newAgents.length === 0) {
                    setSearchResultAgents([{ name: 'No results found' }])
                } else
                    setSearchResultAgents(newAgents)
            }, 500)
        } else {
            setSearchResultsOpen(false)
        }
    }, [searchText])
    const handleSend = (e: any) => {
        e.preventDefault()
        let name_str = ''
        let categories_str = ''
        let integrations_str = ''
        let creator_str = ''
        let prefix = ''
        if (categories.length !== 0) {
            categories_str = '&categories='
            categories.forEach((item: any) => {
                categories_str += item.label + ','
            })
        }
        if (searchText !== '')
            prefix = 'search=' + searchText
        const href = '/agents?' + prefix + name_str + categories_str + integrations_str + creator_str
        setSearchResultsOpen(false)
        navigate(href)
    }
    return (
        <div className='searchBar'>
            <form className='searchBar-form' onSubmit={(e) => handleSend(e)}>
                <input type='text' placeholder='Search for agents and summarizers'
                       value={searchText}
                       onChange={handleInputChange} />
                <IoMdSearch className='sendButton' onClick={(e) => handleSend(e)} />

            </form>
            {searchResultsOpen && <div className='searchResults'>
                {searchResultAgents.map((agent, index) => {
                    if (agent.name === 'No results found')
                        return (
                            <div className='searchOption' key={index}>
                                <h1>No results found</h1>
                            </div>
                        )
                    return (
                        <div className='searchOption' key={index}>
                            <AgentCard agent={agent} small={true} />
                        </div>
                    )
                })}
            </div>}

            {/*<div className='searchBarLower'>*/}
            {/*    <Select className='select'*/}
            {/*            options={creator_options}*/}
            {/*            isMulti*/}
            {/*            styles={customStyles}*/}
            {/*            onChange={(value) => setCreator(value)}*/}
            {/*            placeholder={'Creator'}*/}
            {/*    />*/}
            {/*    <Select className='select'*/}
            {/*            options={creator_options}*/}
            {/*            isMulti*/}
            {/*            styles={customStyles}*/}
            {/*            onChange={(value) => setIntegrations(value)}*/}
            {/*            placeholder={'Integrations'}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*{marketplace && (*/
            }
            {/*    <div className='searchBarWrapper'>*/
            }
            {/*        <div className='searchBarUpper'>*/
            }
            {/*            <div className='inputWrapper'>*/
            }

            {/*            </div>*/
            }

            {/*            <Select className='select'*/
            }
            {/*                    options={creator_options}*/
            }
            {/*                    isMulti*/
            }
            {/*                    styles={customStyles}*/
            }
            {/*                    onChange={(value) => setCategories(value)}*/
            }
            {/*                    placeholder={'Categories'}*/
            }
            {/*            />*/
            }
            {/*        </div>*/
            }
            {/*        <div className='searchBarLower'>*/
            }
            {/*            <Select className='select'*/
            }
            {/*                    options={creator_options}*/
            }
            {/*                    isMulti*/
            }
            {/*                    styles={customStyles}*/
            }
            {/*                    onChange={(value) => setCreator(value)}*/
            }
            {/*                    placeholder={'Creator'}*/
            }
            {/*            />*/
            }
            {/*            <Select className='select'*/
            }
            {/*                    options={creator_options}*/
            }
            {/*                    isMulti*/
            }
            {/*                    styles={customStyles}*/
            }
            {/*                    onChange={(value) => setIntegrations(value)}*/
            }
            {/*                    placeholder={'Integrations'}*/
            }
            {/*            />*/
            }
            {/*        </div>*/
            }

            {/*    </div>*/
            }
            {/*)}*/
            }
        </div>
    )
}

export default SearchBar
