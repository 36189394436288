import './Contact.scss'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'
import TextareaAutosize from 'react-textarea-autosize'
import React from 'react'
import { FreeBlob } from '../../../Components/Blobs/FreeBlob/FreeBlob'
import { AnimatedBlob } from '../../../Components/Blobs/AnimatedBlob/AnimatedBlob'


export const Contact = () => {
    return (
        <div className='contactWrapper'>
            <FreeBlobEnv />
            <ContactComponent title={true} side={true}/>
        </div>
    )
}

interface ContactComponentInterface {
    title: boolean
    side: boolean
}

export const ContactComponent = (props: ContactComponentInterface) => {

    const messageRef = React.useRef<HTMLTextAreaElement>(null)

    return (
        <div className='contact'>
            {props.side && <div className='contact-left'>
                <h1 className='contact-title'>Schedule time with us to:</h1>
                <ul>
                    <li>Get all your questions answered</li>
                    <li>Do a live walkthrough of the platform</li>
                    <li>See customized automation examples</li>
                    <li>Explore which pricing plan is best for you</li>
                </ul>
            </div>}
            <div className='contact-right'>
                <form>
                    <label className='contact-label'>Your name / company</label>
                    <input className='contact-input' required={true} />
                    <label className='contact-label'>Your email</label>
                    <input className='contact-input' required={true} />
                    <label className='contact-label'>Subject</label>
                    <input className='contact-input' required={true} />
                    <label className='contact-label'>Message</label>
                    <TextareaAutosize ref={messageRef} minRows={10} maxRows={15}
                                      className='contact-textarea'
                                      required={true} />
                    <button className='contact-button'>Send</button>
                </form>
            </div>
        </div>

    )
}