import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { verify } from 'crypto'
import { verifyUser } from '../../Requests/userRequests'
import Cookies from 'js-cookie'
import redirect from '../../Utils/redirect'
import useRedirect from '../../Utils/redirect'

export const Verify = () => {
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const redirect = useRedirect()
    useEffect(() => {
        const code = searchParams.get('code')
        verifyUser(code).then((res) => {
            Cookies.set('jwt', res.token, {
                expires: 1,
                path: '/',
                domain: '.' + process.env.REACT_APP_APPI_DOMAIN,
            })
            redirect('', 'dashboard')
        })
    }, [])

    return (
        <div>

        </div>
    )
}