import React, { useEffect, useRef, useState } from 'react'
import { useGlobalState } from '../../../../GlobalStateContext'
import { ConversationState } from '../../../../Utils/enums'
import copy from 'copy-to-clipboard'
import { AiOutlineCheckSquare, AiOutlineEdit, AiOutlineUser } from 'react-icons/ai'
import { MdAlignVerticalBottom, MdCancel, MdOutlineAlignHorizontalLeft, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { FaCheck, FaRegCopy } from 'react-icons/fa'
import 'katex/dist/katex.min.css'
import { Parser } from '../Parser'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import {
    ExpertAnswerInterface,
    MessageInterface,
    MessageInterfaceApi,
    MessageType,
} from '../../../../Interfaces/messageInterfaces'

const APPI_icon = require('../../../../Images/Logo/APPI_icon.png')

interface MessageItemInterface {
    message: MessageInterface,
    fold?: Function,
    conversationState: ConversationState,
    isSelected: boolean,
    folded: any,
    selectedMessages: any[],
    setSelectedMessages: Function,
    setFinalAnswer: Function,
    editPrompt: Function,
    setFlow: Function
    flow: any
    alignment: boolean
    setAlignment: Function
}

export const MessageItem = ({
                                message,
                                folded,
                                fold,
                                conversationState,
                                isSelected,
                                selectedMessages,
                                setSelectedMessages,
                                setFinalAnswer,
                                editPrompt,
                                setFlow,
                                flow,
                                alignment,
                                setAlignment,
                            }: MessageItemInterface) => {
    const isFolded = folded.includes(message.id)
    const [sender, setSender] = useState<any>(null)
    const [copied, setCopied] = useState<boolean>(false)
    const [globalState, globalActions] = useGlobalState()
    const [hovered, setHovered] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)
    const [previousText, setPreviousText] = useState<string | null>('')
    const promptRef = useRef<HTMLHeadingElement>(null)
    let className = 'message ' + message.id


    useEffect(() => {
        if (message.messageType === MessageType.USER_MESSAGE && globalState.user)
            setSender(globalState.user?.name + ' ' + globalState.user?.surname)
        else
            setSender('User')
    }, [message])

    const handleCopy = (text: string) => {
        copy(text)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
    }

    const handleMouseEnter = () => {
        setHovered(true)
    }

    const handleMouseLeave = () => {
        setHovered(false)
    }
    const handleEdit = (cancel: boolean) => {
        if (edit) {
            if (cancel && promptRef.current) {
                promptRef.current.textContent = previousText
            } else if (promptRef.current) {
                console.log(message)
                const experts_ids = message.agentAnswers.map((answer: ExpertAnswerInterface) => answer.expertResponding.id)
                const summarizer_id = message.summarizerAnswer ? message.summarizerAnswer.sender.id : null
                editPrompt(message.previousMessageId, promptRef.current.textContent, experts_ids, summarizer_id)
            }
            setEdit(false)
        } else {
            if (promptRef.current) {
                const text = promptRef.current.textContent
                setPreviousText(text)
            }
            setEdit(true)
        }
    }
    const flowBackward = () => {
        const newFlow = [...flow]
        if (newFlow[message.depth] < message.versions - 1) {
            newFlow[message.depth]++
            setFlow(newFlow)
        }
    }


    const flowForward = () => {
        const newFlow = [...flow]
        if (newFlow[message.depth] > 0) {
            newFlow[message.depth]--
            setFlow(newFlow)
        }
    }

    return (
        <div className='message message-user'
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            <div className='message-innerWrapper'>
                <div className='message-icon-wrapper'>
                    <AiOutlineUser className='message-icon user' />
                    <div className='message-icon-sender'>{sender ? sender : 'User'}</div>
                </div>
                <div className='message-controls'>
                    {message.versions > 1 && <div className='message-controls-version'>
                        <IoIosArrowBack className='message-controls-version-arrow'
                                        onClick={() => flowBackward()} />
                        <div className='message-controls-version-text'>
                            <h1>{message.versions - flow[message.depth]} / {message.versions}</h1>
                        </div>
                        <IoIosArrowForward className='message-controls-version-arrow'
                                           onClick={() => flowForward()} />
                    </div>}
                    <div className='message-controls-iconHolder-icon-wrapper'
                         onClick={() => setAlignment(!alignment)}>
                        {alignment && <MdOutlineAlignHorizontalLeft
                            className='message-controls-iconHolder-icon' />}
                        {!alignment && <MdAlignVerticalBottom
                            className='message-controls-iconHolder-icon' />}
                        <p>Position</p>
                    </div>
                    {(hovered || edit) && <div className='message-controls-iconHolder'>
                        {!edit &&
                            <div className='message-controls-iconHolder-icon-wrapper'
                                 onClick={() => handleEdit(true)}>
                                <AiOutlineEdit
                                    className='message-controls-iconHolder-icon' />
                                <p>Edit</p>
                            </div>}
                        {edit && <MdCancel className='message-controls-iconHolder-icon'
                                           onClick={() => handleEdit(true)} />}
                        {edit && <FaCheck className='message-controls-iconHolder-icon'
                                          onClick={() => handleEdit(false)} />}
                    </div>
                    }
                </div>
                <div className='message-text-wrapper'>
                    <div
                        className={edit ? 'message-text message-text-edit' : 'message-text'}
                        contentEditable={edit}
                        suppressContentEditableWarning={true}
                        ref={promptRef}
                    >{message.messageContentParts[0]?.text && message.messageContentParts[0].text.includes('undefined') ?
                        <Parser copied={copied} handleCopy={handleCopy}>
                            {message.messageContentParts[0].text.slice(9, message.messageContentParts[0].text.length)}
                        </Parser> :
                        <Parser copied={copied} handleCopy={handleCopy}>
                            {message.messageContentParts[0].text}
                        </Parser>}</div>

                </div>
            </div>
        </div>
    )
}
