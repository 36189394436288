import './AiExpert.scss'
import React, { useEffect, useState } from 'react'
import { linked_in_hook_crafting_expert, senior_java_developer } from '../../../../../Components/staticExperts'
import { FunToolItem } from '../../FunToolItem/FunToolItem'
import AgentCard from '../../../../../Components/AgentCard/AgentCard'
import { useNavigate } from 'react-router-dom'
import { AiOutlineLoading, AiOutlineUser } from 'react-icons/ai'
import { FaRobot } from 'react-icons/fa'
import { Parser } from '../../../../Chat/Message/Parser'

enum Phase {
    SelectExpert,
    SelectPrompt,
    GeneratingResponseLoading,
    GeneratingResponse,
    ResponseGenerated,
}

const expertList = [
    {
        'expert': senior_java_developer,
        'prompts': [
            {
                'prompt': 'How to write a Java program to reverse a string in Java?',
                'response': 'You can reverse a string in Java by using the StringBuilder class. Here is an example of how to do it:\n\n```java\npublic class Main {\n   public static void main(String[] args) {\n      String str = "Hello, World!";\n      StringBuilder sb = new StringBuilder(str);\n\n      sb.reverse();\n\n      System.out.println("Reversed string is: " + sb.toString());\n   }\n}\n```',
            },
            {
                'prompt': 'How to find the length of a string in Java?',
                'response': 'In Java, you can find the length of a string by using the length() method of the String class. Here is an example:\n\n```java\npublic class Main {\n    public static void main(String[] args) {\n        String myString = "Hello, world!";\n        int length = myString.length();\n        System.out.println("The length of the string is: " + length);\n    }\n}\n```',
            },

        ],
    },
    {
        'expert': linked_in_hook_crafting_expert,
        'prompts': [
            {
                'prompt': 'How to write a LinkedIn profile summary?',
                'response': 'A LinkedIn profile summary should be a brief and engaging description of your professional background, skills, and accomplishments. It should be written in the first person and highlight your unique value proposition to potential employers or clients.',
            },
            {
                'prompt': 'How to optimize your LinkedIn profile for search?',
                'response': 'To optimize your LinkedIn profile for search, you should use relevant keywords in your headline, summary, and experience sections. You should also include a professional profile photo and complete all sections of your profile to increase your visibility in search results.',
            },
        ],
    },
]

const phaseText = {
    [Phase.SelectExpert]: {
        'title': 'Select an Expert',
        'description': 'Choose an expert to generate a response from',
    },
    [Phase.SelectPrompt]: {
        'title': 'Select a Prompt',
        'description': 'Choose a prompt to generate a response for',
    },
    [Phase.GeneratingResponse]: {
        'title': 'Generating Response',
        'description': 'Generating a response from the selected expert...',
    },
    [Phase.ResponseGenerated]: {
        'title': 'Response Generated',
        'description': 'The response has been generated successfully',
    },
}

export const AiExpert = () => {
    const navigate = useNavigate()
    const [phase, setPhase] = useState<Phase>(Phase.SelectExpert)
    const [selectedExpert, setSelectedExpert] = useState<number>(-1)
    const [selectedPrompt, setSelectedPrompt] = useState<number>(-1)
    const [generatedResponse, setGeneratedResponse] = useState<string>('')
    const defaultNum3Text = {
        'title': phaseText[Phase.GeneratingResponse].title,
        'description': phaseText[Phase.GeneratingResponse].description,

    }
    const [num3Text, setNum3Text] = useState<any>(defaultNum3Text)

    useEffect(() => {
        if (phase === Phase.GeneratingResponseLoading) {
            setGeneratedResponse('')
            setNum3Text(defaultNum3Text)
            setTimeout(() => {
                setPhase(Phase.GeneratingResponse)
            }, 1500)
        }
        if (phase === Phase.GeneratingResponse) {
            const response = expertList[selectedExpert].prompts[selectedPrompt].response
            let index = -1
            const appendNextLetter = () => {
                if (index < response.length - 1) {
                    setGeneratedResponse((prev) => prev + response[index])
                    index++
                    setTimeout(appendNextLetter, 15)
                }
            }

            appendNextLetter()

            setNum3Text({
                'title': phaseText[Phase.ResponseGenerated].title,
                'description': phaseText[Phase.ResponseGenerated].description,
            })
        }

    }, [phase])

    return (
        <div className='funToolComponent aiExpert'>
            <FunToolItem
                num={1}
                title={phaseText[Phase.SelectExpert].title}
                description={phaseText[Phase.SelectExpert].description}
                active={phase === Phase.SelectExpert}
            >
                {phase === Phase.SelectExpert && (
                    <div className='funToolComponent-expertList'>
                        {expertList.map((item, index) => (
                            <AgentCard agent={item.expert}
                                       key={index}
                                       onClick={() => {
                                           setSelectedExpert(index)
                                           setPhase(Phase.SelectPrompt)
                                       }}
                                       onClickRedirect={false}
                            />
                        ))}
                    </div>
                )}
                {phase !== Phase.SelectExpert && (
                    <div className='funToolComponent-expertList'>
                        <AgentCard agent={expertList[selectedExpert].expert}
                                   onClick={() => {
                                       setSelectedExpert(-1)
                                       setPhase(Phase.SelectExpert)
                                   }}
                                   onClickRedirect={false}
                        />
                    </div>
                )}
            </FunToolItem>
            <FunToolItem
                num={2}
                title={phaseText[Phase.SelectPrompt].title}
                description={phaseText[Phase.SelectPrompt].description}
                active={phase === Phase.SelectPrompt}
            >
                {phase === Phase.SelectPrompt && (
                    <div className='funToolComponent-promptList'>
                        {expertList[selectedExpert].prompts.map((item, index) => (
                            <div className='funToolComponent-promptList-item'
                                 onClick={() => {
                                     setSelectedPrompt(index)
                                     setPhase(Phase.GeneratingResponseLoading)
                                 }}
                            >
                                {item.prompt}
                            </div>
                        ))}
                    </div>
                )}
                {phase.valueOf() > Phase.SelectPrompt.valueOf() && (
                    <div className='funToolComponent-generatingResponse'
                         onClick={() => {
                             setSelectedPrompt(-1)
                             setPhase(Phase.SelectPrompt)
                         }}
                    >
                        <div className='funToolComponent-generatingResponse-inline'>
                            <div className='funToolComponent-generatingResponse-loading-sender'>
                                <AiOutlineUser className='message-icon' />
                                <div
                                    className='message-icon-sender'>User
                                </div>
                            </div>
                            <h1>{expertList[selectedExpert].prompts[selectedPrompt].prompt}</h1>
                        </div>
                    </div>
                )}
            </FunToolItem>
            <FunToolItem
                num={3}
                title={num3Text.title}
                description={num3Text.description}
                active={phase === Phase.GeneratingResponse || phase === Phase.GeneratingResponseLoading}
            >
                {(phase === Phase.GeneratingResponseLoading || phase === Phase.GeneratingResponse) && (
                    <div className='funToolComponent-generatingResponse'>
                        {phase === Phase.GeneratingResponseLoading &&
                            <AiOutlineLoading className='funToolComponent-generatingResponse-loading-spinner' />}
                        {phase === Phase.GeneratingResponse &&
                            <div className='funToolComponent-generatingResponse-inline'>
                                <div className='funToolComponent-generatingResponse-loading-sender'>
                                    <FaRobot className='message-icon'
                                             style={{
                                                 // @ts-ignore
                                                 color: expertList[selectedExpert].expert.logoColorHex ? expertList[selectedExpert].expert.logoColorHex : '',
                                             }}
                                    />
                                    <div
                                        className='message-icon-sender'>{expertList[selectedExpert].expert.name}</div>
                                </div>
                                <h1 className='message-text'>
                                    <Parser>
                                        {generatedResponse}
                                    </Parser>
                                </h1>
                            </div>
                        }
                    </div>
                )}

            </FunToolItem>
        </div>
    )
}