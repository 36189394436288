import './StaticGrid.scss'
import React, { useState } from 'react'

interface StaticGridItemInterface {
    title: string
    text: string
    description?: string
    img?: string
}

interface StaticGridInterface {
    items?: StaticGridItemInterface[]
    title: string
}

export const StaticGrid = (props: StaticGridInterface) => {
    return (
        <div className='staticGrid'>
            <h1>{props.title}</h1>
            <div className='staticGrid-itemsWrapper'>
                {props.items?.map((item, index) => (
                    <StaticPropsItem key={index} {...item} />
                ))}
            </div>
        </div>
    )
}

const StaticPropsItem = (props: StaticGridItemInterface) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <div
            className={`staticGrid-item`} 
            style={{
                backgroundImage: isExpanded ? undefined : `url(${props.img})`,
            }}            
        >
        {isExpanded ?
        <div className='staticGrid-item-description'>
            <h2>{props.description}</h2>
            <button onClick={handleClick}>Show less</button>
        </div>
        :
        <div className='staticGrid-item-inside'>
                <h1>{props.title}</h1>
                <p>{props.text}</p>
                <button onClick={handleClick}>Read more</button>
            </div>
        }    
        </div>
    )
}