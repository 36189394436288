import './DummyFormDesc.scss'
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export interface DummyFormProps {
    onClick: (name: string, shortDesc: string) => void;
    active: boolean;
}

export const DummyFormDesc = (props: DummyFormProps) => {
    const [name, setName] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');

    return (
        <div className={props.active ? 'dummyFormLeft' : 'dummyFormLeft inactive'}>
            <div className='dummyFormLeft-forms'>
                <div className='dummyFormLeft-forms-name'>
                    <div className='dummyFormLeft-forms-element'>
                        <label className='dummyFormLeft-forms-label'>Name</label>
                        <input 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className='dummyFormLeft-forms-input' 
                            required={true} 
                            disabled={!props.active}
                        />
                    </div>
                    <div className='dummyFormLeft-forms-element'>
                        <label className='dummyFormLeft-forms-label'>Short Description</label>
                        <TextareaAutosize value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} minRows={4} maxRows={4}
                                        className='dummyFormLeft-forms-input'
                                        required={true} 
                                        maxLength={160}
                                        disabled={!props.active}
                                        >
                        </TextareaAutosize>
                        <p className='dummyFormLeft-forms-input-charcount'>{shortDescription.length}/160</p>
                    </div>
                </div>
                <div className='dummyFormLeft-forms-element'>
                <label className='dummyFormLeft-forms-label'>Description</label>
                    <TextareaAutosize value={description} onChange={(e) => setDescription(e.target.value)} minRows={9} maxRows={9}
                                    className='dummyFormLeft-forms-input'
                                    required={true} 
                                    maxLength={500}
                                    disabled={!props.active}
                                    >
                    </TextareaAutosize>
                    <p className='dummyFormLeft-forms-input-charcount'>{description.length}/500</p>
                </div>
            </div>
            <button className='dummyFormLeft-button' disabled={!props.active} onClick={() => props.onClick(name, shortDescription)}>Continue</button>
        </div>
    );
}
