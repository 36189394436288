import './StaticTitle.scss'
import FreeBlobEnv from '../../../../Components/Blobs/FreeBlob/FreeBlobEnv'
import React from 'react'

interface StaticTitleInterface {
    title: string
    subtitle?: string
    buttons: {
        main: string
        secondary: string
        mainLink?: string
        secondaryLink?: string
    }
}

export const StaticTitle = (props: StaticTitleInterface) => {

    const handleClickSecondary = () => {
        window.scrollBy({
            left: 0,
            top: window.innerHeight * 0.7,
            behavior: 'smooth',
        })
    }
    return (
        <div className='staticTitle-wrapper'>
            <FreeBlobEnv width={'100%'} height={'100%'} min={30} max={30} />
            <div className='staticTitle'>
                <h1 className='staticTitle-title'>{props.title}</h1>
                <h2 className='staticTitle-subtitle'>{props.subtitle}</h2>

                <div className='staticTitle-buttons'>
                    <button
                        className='staticTitle-buttons-button staticTitle-buttons-button-main'>{props.buttons.main}</button>
                    <button className='staticTitle-buttons-button'
                            onClick={() => handleClickSecondary()}
                    >{props.buttons.secondary}</button>
                </div>
            </div>
        </div>
    )
}