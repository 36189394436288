import './StaticContactUs.scss'
import { Contact, ContactComponent } from '../../Contact/Contact'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

interface StaticContactUs {
    title: string
    subtitle?: string
}

interface StaticContactUsConfigInterface {
    image: string
    title: string
    description: string
    link: string
    buttonText: string
}

const StaticContactUsConfig: StaticContactUsConfigInterface[] = [
    {
        title: 'Contact Us',
        description: 'Get in touch with us for any questions or concerns.',
        link: '/contact',
        buttonText: "Let's talk",
        image: '',
    },
    {
        title: 'Book a Demo',
        description: 'Schedule a demo with us to see how our platform works.',
        link: '/contact',
        buttonText: 'Choose a time',
        image: '',
    },
    {
        title: 'Browse marketplace',
        description: 'Jump right in!',
        link: '/agents',
        buttonText: 'Discover AI experts',
        image: '',
    },
]

export const StaticContactUs = (props: StaticContactUs) => {
    return (
        <div className='staticContactUs'>
            <h1 className='staticContactUs-title'>{props.title}</h1>
            <div className='staticContactUs-content'>
                {StaticContactUsConfig.map((item, index) => (
                    <div key={index} className='staticContactUs-item'>
                        <img src={item.image} alt='readMore' className='staticContactUs-item-image' />
                        <div className='staticContactUs-item-title'>{item.title}</div>
                        <div className='staticContactUs-item-description'>{item.description}</div>
                        <a href={item.link} className='staticContactUs-item-button'>
                            {item.buttonText}
                            <MdOutlineKeyboardArrowRight className='staticContactUs-item-button-icon' />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}