import React, { useEffect, useState } from 'react'
import Message from '../Message/Message'
import './Conversation.scss'
import './ConversationMobile.scss'
import { ConversationState, MessageFlow } from '../../../Utils/enums'
import { AiOutlineLoading } from 'react-icons/ai'
import { Heap, HeapNode } from './Heap'
import { Starter } from './Starter/Starter'
import { MessageInterface, MessageInterfaceApi } from '../../../Interfaces/messageInterfaces'
import { useChatContext } from '../ChatPropsManager'


interface ConversationInterface {
    editPrompt: Function
    languages: any[]
    scrollDown: Function
    choose: Function
    chooseFinal: Function
}

export enum ModalOpen {
    NULL,
    MODELS,
    LANGUAGES,
    CUSTOM_INSTRUCTIONS
}

export const Conversation = ({
                                 editPrompt,
                                 languages,
                                 scrollDown,
                                 choose,
                                 chooseFinal
                             }: ConversationInterface) => {

    const {
        messages,
        conversationState,
        selectedAnswers,
        setSelectedAnswers,
        finalAnswer,
        setFinalAnswer,
        customProfiles,
        selectedCustomInstruction,
        setSelectedCustomInstruction,
        CustomProfilesOpen,
        setCustomProfilesOpen,
        loadingConversation,
        models,
        selectedModel,
        setSelectedModel,
        forest,
        setForest,
        flow,
        setFlow,
        chosenMessages,
        setChosenMessages,
        selectedLanguage,
        setSelectedLanguage,
        setConversationState,
        conversationContainerRef,
        generationStage,
        setGenerationStage,
        agentsList,
        summarizersList,
        setAgentsSelected,
        setSummarizer,
    } = useChatContext()

    const [modal, setModal] = useState<ModalOpen>(ModalOpen.NULL)
    const searchNode = (node: MessageInterface, depth: number) => {
        if (node.nextMessagesIds.length === 0)
            return [[], depth]
        else {
            let children: MessageInterface[] = []
            for (const id of node.nextMessagesIds) {
                const child = messages.find((message) => message.id === id)
                if (child) {
                    const res = searchNode(child, depth)
                    child.children = res[0] as MessageInterface[]
                    child.versions = node.nextMessagesIds.length
                    depth = Math.max(depth, res[1] as number)
                    child.depth = depth
                }
                children.push(child as MessageInterface)
            }
            return [children, depth + 1]
        }
    }

    const buildMessageForest = (messages: MessageInterface[]) => {
        const heap = new Heap()
        for (const message of messages) {
            if (message.previousMessageId === null)
                heap.insertAsRoot(message.id, message)
            else
                heap.insert(message.id, message.previousMessageId, message)
        }
        const maxDepth = heap.maxDepth()
        const flow = Array(maxDepth).fill(0)
        return { heap, flow }
    }

    const nodeToMessage = (node: HeapNode) => {
        const message = node.message
        message.children = node.children.map((child: HeapNode) => child.message)
        message.depth = node.depth
        message.versions = node.variants
        return message
    }

    useEffect(() => {
        if (messages.length > 0) {
            const res = buildMessageForest(messages)
            setForest(res.heap)
            if (flow.length === 0 || flow.length === 1) {
                setFlow(res.flow)
            }
        } else
            setForest(new Heap())
    }, [messages])


    useEffect(() => {
        const newChosenMessages: MessageInterface[] = []
        let current = forest.roots[forest.roots.length - flow[0] - 1]
        let idx = 0
        if (current !== undefined) {
            newChosenMessages.push(nodeToMessage(current))
        }
        while (1) {
            idx += 1
            if (current && current.children && current.children.length !== 0 && flow[idx] !== undefined && flow[idx] < current.children.length) {
                current = current.children[current.children.length - flow[idx] - 1]
                newChosenMessages.push(nodeToMessage(current))
            } else
                break
        }
        setChosenMessages(newChosenMessages)
        const last_message = newChosenMessages[newChosenMessages.length - 1]
        if (last_message) {
            if (last_message.finished) setConversationState(ConversationState.READY)
            else {
                if (last_message.agentAnswers && last_message.agentAnswers?.length === 0 && last_message.agentAnswerIds?.length !== 0)
                    setConversationState(ConversationState.LOADING_AGENT_RESPONSE)
                else if ((last_message.agentAnswers.length !== 0 && (last_message.messageFlow === MessageFlow.NO_SUMMARY || last_message.messageFlow === MessageFlow.NO_SUMMARY_ONE_EXPERT))
                    || (last_message.agentAnswers.length !== 0 && last_message?.summarizerAnswer !== null))
                    chooseFinal()
                else if (last_message.agentAnswers.length !== 0 && last_message?.summarizerAnswerIds?.length !== 0) {
                    setConversationState(ConversationState.LOADING_SUMMARIZER_RESPONSE)
                } else if (last_message.agentAnswers.length !== 0 && last_message.agentAnswers.length === 0)
                    choose()
            }
        }

    }, [forest, flow])


    useEffect(() => {
        scrollDown(false)
        if (chosenMessages.length === 0) return
        const last_experts = chosenMessages[chosenMessages.length - 1]?.agentAnswers.map((answer) => answer.expertResponding.id)
        const last_summarizer = chosenMessages[chosenMessages.length - 1]?.summarizerAnswer?.sender.id
        setAgentsSelected(agentsList.filter((agent: any) => last_experts.includes(agent.id)))
        if (last_summarizer)
            setSummarizer(summarizersList.find((agent: any) => agent.id === last_summarizer))
    }, [chosenMessages])

    return (
        <div className="chat-mainArea-chat" ref={conversationContainerRef} onClick={() => {
            setModal(ModalOpen.NULL)
            setCustomProfilesOpen(false)
        }}>
            <div className="chat-mainArea-chat-dummy" />
            {loadingConversation && <AiOutlineLoading className="chat-mainArea-chat-loading" />}
            {(messages.length > 0 && selectedCustomInstruction.name !== 'No custom profile') &&
                <div className="chat-mainArea-chat-instructionHeader">
                    Custom
                    Profile: {selectedCustomInstruction?.name}
                </div>}
            {chosenMessages && chosenMessages.map((message, index: number) =>
                (message &&
                    <Message message={message} key={'message' + index}
                             selectedMessages={selectedAnswers}
                             setSelectedMessages={setSelectedAnswers}
                             conversationState={conversationState}
                             setFinalAnswer={setFinalAnswer}
                             finalAnswer={finalAnswer}
                             editPrompt={editPrompt}
                             setFlow={setFlow}
                             flow={flow}
                             generationStage={index === chosenMessages.length - 1 ? generationStage : undefined}
                    />
                ))}

            {(messages.length === 0 && !loadingConversation) && <Starter
                customProfiles={customProfiles}
                selectedCustomInstruction={selectedCustomInstruction}
                setSelectedCustomInstruction={setSelectedCustomInstruction}
                CustomProfilesOpen={CustomProfilesOpen}
                setCustomProfilesOpen={setCustomProfilesOpen}
                models={models}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
                languages={languages}
                modal={modal}
                setModal={setModal}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
            />}
        </div>
    )
}
