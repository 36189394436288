import { InspectorMode, useChatContext } from '../ChatPropsManager'
import './Inspector.scss'
import { useEffect, useState } from 'react'
import { getDocuments, getExpertDocumentFile } from '../../../Requests/documentsRequests'
import {
    ContextInterface,
    ContextPartInterface,
    ContextPartType,
    DocumentInterface,
} from '../../../Interfaces/documentInterfaces'
import { PdfViewer } from '../../Static/PdfViewer/PdfViewer'
import { displayContext, DisplayIcon } from '../Utils/DisplayContext'
import { InlineMath } from 'react-katex'
import { GetContextImage } from '../../../Requests/conversationRequests'

class InspectorInterface {
    images: boolean = false
    setChunk?: Function
    highlighted?: number
}

export const Inspector = ({ images, setChunk, highlighted }: InspectorInterface) => {

    const {
        inspectorContext,
        inspectorWidth,
        setInspectorWidth,
        inspectorMode,
        setInspectorMode,
        setInspectorContext,
    } = useChatContext()


    const [documents, setDocuments] = useState<DocumentInterface[]>([])
    const [selectedDocument, setSelectedDocument] = useState<any>(null)
    const [selectedDocumentFile, setSelectedDocumentFile] = useState<string | undefined>(undefined)
    const [selectedDocumentMeta, setSelectedDocumentMeta] = useState<any>(null)
    const [selectedChunk, setSelectedChunk] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const expertId = (inspectorContext && inspectorContext[0]) ? inspectorContext[0].expertId : null
    useEffect(() => {
        setInspectorMode(InspectorMode.BROWSER)
    }, [inspectorContext])

    useEffect(() => {
        if (expertId) {
            getDocuments(expertId).then((response: any) => {
                setDocuments(response)
            })
        }

    }, [inspectorContext]) //TODO: move images to cache

    useEffect(() => {
        if (inspectorMode === InspectorMode.DOCUMENT) setInspectorWidth('90%')
        else setInspectorWidth('50%')
    }, [inspectorMode])

    useEffect(() => {
        if (selectedDocument) {
            const objectURL = URL.createObjectURL(selectedDocument)
            setSelectedDocumentFile(objectURL)
        }
    }, [selectedDocument])

    return (
        <div className="chat-inspector" style={{ width: inspectorWidth }}>
            <h1 className="chat-inspector-title">Inspector</h1>
            {(!loading && inspectorMode === InspectorMode.BROWSER) && inspectorContext?.map((context: ContextInterface, index: number) => {
                    const document_candidate = documents.find((doc: any) => doc.id === context.documentId)
                    const document_item = document_candidate ? document_candidate : {
                        documentName: 'Document not found',
                        documentUrl: '',
                    }
                    const meta = context.contextFragments.map((contextPart) => {
                        return {
                            'bounding_box': {
                                x1: contextPart.boundingBoxUpperLeftPoint.x,
                                y1: contextPart.boundingBoxUpperLeftPoint.y,
                                x2: contextPart.boundingBoxBottomRightPoint.x,
                                y2: contextPart.boundingBoxBottomRightPoint.y,
                                selected: false,
                            },
                            'page': contextPart.page ? contextPart.page : '',
                        }
                    })
                    return (
                        <div className="chat-inspector-document" key={index}>
                            <h2 className="chat-inspector-document-title">{document_item?.documentName}</h2>
                            {context.contextFragments.map((contextPart, index2: number) => {
                                return (
                                    <div
                                        className={highlighted === index2 ? 'chat-inspector-document-part chat-inspector-document-part-highlighted' : 'chat-inspector-document-part'}
                                        key={index2}
                                        onClick={() => {
                                            if (!images) {
                                                setLoading(true)
                                                getExpertDocumentFile(context.documentId).then((response: any) => {
                                                    setSelectedDocument(response)
                                                    setInspectorMode(InspectorMode.DOCUMENT)
                                                    setSelectedDocumentMeta(meta)
                                                    setSelectedChunk(index2)
                                                    document.location.hash = context.documentId + '-' + index2
                                                    setLoading(false)
                                                })
                                            } else if (setChunk) setChunk(index2)
                                        }}
                                    >
                                        <h3 className="chat-inspector-document-part-index">[{index2 + 1}] {contextPart.fragment.messagePartType}</h3>
                                        {contextPart.page !== null &&
                                            <h3 className="chat-inspector-document-part-title">{contextPart.fragment.messagePartType === ContextPartType.FORMULA ?
                                                <InlineMath>{contextPart.fragment.messagePartContent_TextAlt}</InlineMath> : displayContext(contextPart, true)} </h3>
                                        }
                                        {contextPart.page !== null &&
                                            <p className="chat-inspector-document-part-page">P: {contextPart.page + 1}</p>}
                                    </div>
                                )
                            })}
                        </div>
                    )
                },
            )}
            {(!loading && inspectorMode === InspectorMode.DOCUMENT) &&
                <PdfViewer file={selectedDocumentFile ? selectedDocumentFile : ''}
                           metadata={selectedDocumentMeta}
                           selected={selectedChunk} setSelectedChunk={setSelectedChunk} />}
            {(!loading && !expertId) && <h1 className="chat-inspector-nothing">Select context</h1>}
            {loading && <h1 className="chat-inspector-nothing">Loading...</h1>}
        </div>
    )
}