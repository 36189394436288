import './ReadMore.scss'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const img1 = require('../../../Images/Assets/About/img1.png')
const img2 = require('../../../Images/Assets/About/img2.png')
const img3 = require('../../../Images/Assets/About/img3.png')



export const ReadMore = () => {
    return (
        <div className='readMore'>
            <div className='readMore-title'>Read more</div>
            <div className='readMore-content'>
                {ReadMoreConfig.map((item, index) => (
                    <div key={index} className='readMore-content-item' style={{
                        backgroundImage: `url(${item.image})`,
                    }}>
                        {/*<img src={item.image} alt='readMore' className='readMore-content-item-image' />*/}
                        <div className='readMore-content-item-title'>{item.title}</div>
                        <div className='readMore-content-item-description'>{item.description}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

interface ReadMoreConfigInterface {
    title: string
    description: string
    image: any
    link: string
    buttonText: string
}

const ReadMoreConfig: ReadMoreConfigInterface[] = [
    {
        title: 'Individual users',
        description: 'Description',
        image: img1,
        link: '/solutions/individual',
        buttonText: 'Individual users',
    },
    {
        title: 'Developers',
        description: 'Description',
        image: img2,
        link: 'developer/custom-agents',
        buttonText: 'Developers',
    },
    {
        title: 'Businesses',
        description: 'Description',
        image: img3,
        link: 'solutions/businesses',
        buttonText: 'Businesses',
    },

]