import './SolutionsBusinesses.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import { StaticTextWithImage } from '../../Components/StaticTextWithImage/StaticTextWithImage'
import { StaticPrompt } from '../../Components/StaticPrompt/StaticPrompt'
import { StaticStats } from '../../Components/StaticStats/StaticStats'
import Footer from '../../../MainSection/Footer/Footer'
import { StaticList } from '../../Components/StaticList/StaticList'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { StaticFlow } from '../../Components/StaticFlow/StaticFlow'
import { StaticContactUs } from '../../Components/StaticContactUs/StaticContactUs'

const img1 = require('../../../../Images/Assets/Previews/AgentsPreview.png');

export const SolutionsBusinesses = () => {

    const statsToDisplay = [
        {
            percentage: 0,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
            source: '',
        },
        {
            percentage: 50,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
            source: '',
        },
        {
            percentage: 100,
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
            source: '',
        },
    ]

    return (
        <div className='solutionsBusinesses-wrapper'>
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className='solutionsBusinesses'>
                <StaticTitle
                    title={'Supercharge your business, with our solutions'}
                    subtitle={'APPI makes it easy to apply Genrative AI into your business'}
                    buttons={{
                        main: 'Contact us',
                        secondary: 'Read more',
                    }}
                />
                <StaticStats statsConfig={statsToDisplay}/>
                <StaticTextWithImage
                    title={'Meet Your New AI Team'}
                    text={'Step into the future with A{P}PI\'s AI Experts, your go-to team for anything from crunching numbers to spinning stories. Our AI maestros are here to make your work life easier, smarter, and a lot more innovative. They\'re not just algorithms; they\'re your digital colleagues, ready to dive into the deep end of any project. With a blend of expertise in various fields, they\'re here to push your business forward, making sure every decision and creative endeavor is backed by top-notch intelligence.'}
                    image={img1}
                    side={true}
                    bullets={[
                        {
                            'title': 'Expertise Across the Board',
                            'text': 'Whether it\'s legal advice, market analysis, or creative campaigns, our AI Experts have got you covered.',
                        },
                        {
                            'title': 'Smart and Creative',
                            'text': 'Get ready for solutions that don\'t just solve problems but do it with flair, turning challenges into opportunities.',
                        },
                    ]}
                />
                <StaticTextWithImage
                    title={'Your own AI expert'}
                    text={'Imagine having an AI so in tune with your business it feels like it was made just for you. That\'s what we do here at A{P}PI. We\'re all about crafting AI solutions that fit your business like a glove. From streamlining operations to unlocking new insights, we work with you to build AI tools that not only fit into your workflow but also propel your business forward. It\'s not just about automation; it\'s about creating smart, adaptive solutions that grow with you.'}
                    image={'https://via.placeholder.com/700x500'} // Replace with your chosen image
                    side={false}
                    bullets={[
                        {
                            'title': 'Tailor-Made for You',
                            'text': 'Your business is unique, and your AI solutions should be too. We\'re here to build your ideal AI companion.',
                        },
                        {
                            'title': 'Smooth Operators',
                            'text': 'These AI solutions slip right into your daily operations, boosting efficiency without skipping a beat.',
                        },
                    ]}
                />

                <StaticList
                    title={'Built for Performance, Designed for Innovation'}
                    points={[
                        {
                            'title': 'Custom Document Query System',
                            'text': 'Dive deep with precision. Our custom document query system allows you to retrieve information with unmatched accuracy and speed, ensuring you get exactly what you need, when you need it.',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                        {
                            'title': 'Diverse LLM Integrations',
                            'text': 'A mosaic of intelligence. A{P}PI seamlessly integrates multiple Large Language Models, offering you a broad spectrum of expertise and perspectives in one platform.',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                        {
                            'title': 'User-Friendly Interface',
                            'text': 'Simplicity meets sophistication. Our intuitive interface ensures that harnessing the power of AI is as straightforward as it is powerful, making advanced technology accessible to all.',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                        {
                            'title': 'Transparent Pricing',
                            'text': 'Clarity in every transaction. With A{P}PI, you\'re always in the know with transparent pricing, ensuring that you can make informed decisions without any surprises.',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                    ]}
                    flipped={true}
                />


                <StaticFlow title={'Our workflow'}
                            bullets={[
                                {
                                    'title': 'Initial Consultation',
                                    'text': 'The journey begins with a one-on-one meeting to understand the client\'s vision and objectives. This is a critical phase where we gather all the necessary information, including the client\'s business goals, target audience, and desired outcomes, setting the stage for a successful partnership.',
                                },
                                {
                                    'title': 'Strategy Formulation',
                                    'text': 'Based on the insights from the initial consultation, we develop a customized strategy that aligns with the client\'s goals. This plan outlines the project\'s roadmap, timelines, and milestones, ensuring both parties are on the same page and working towards a common goal.',
                                },
                                {
                                    'title': 'Creative Development',
                                    'text': 'Our team dives into the creative process, designing concepts and solutions that resonate with the client\'s brand identity. This stage is all about innovation and bringing fresh ideas to the table, which will be refined through client feedback and collaborative brainstorming sessions.',
                                },
                                {
                                    'title': 'Implementation Phase',
                                    'text': 'With the client\'s approval of the creative concepts, we move into the implementation phase. This involves the actual creation of the deliverables, whether it\'s a digital product, marketing campaign, or any other service, executed with precision and attention to detail.',
                                },
                                {
                                    'title': 'Performance Review',
                                    'text': 'After the implementation, we assess the project\'s performance against the initial objectives. We provide a comprehensive report to the client, review the outcomes, and discuss any adjustments or ongoing support that may be needed to ensure sustained success and return on investment.',
                                },
                            ]}
                />
                <StaticTextWithImage 
                    title='Personalized approach'
                    text='wdrożenia wdrożenia Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.'
                    image={'https://via.placeholder.com/700x500'}
                    side={false} 
                />
                
                <StaticList
                    title={'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'}
                    points={[
                        {
                            'title': 'Language model diversification',
                            'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                        {
                            'title': 'Giving context through documents',
                            'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                        {
                            'title': 'Customizable through special parameters',
                            'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra.',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                        {
                            'title': 'And more features coming soon',
                            'text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent consectetur odio sed feugiat viverra',
                            'icon': <HiOutlinePresentationChartLine />,
                        },
                    ]}
                    flipped={false}
                />

                <StaticContactUs title={'Ready to get started?'}
                                 subtitle={'Supercharge your business, with our solutions. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam faucibus, nisl quam aliquet odio, quis aliquam odio nisl quis odio'} />
                <Footer />
            </div>
        </div>
    )
}