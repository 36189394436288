import './SolutionsIndividual.scss'
import { StaticTitle } from '../../Components/StaticTitle/StaticTitle'
import { StaticTextWithImage } from '../../Components/StaticTextWithImage/StaticTextWithImage'
import { StaticPrompt } from '../../Components/StaticPrompt/StaticPrompt'
import Footer from '../../../MainSection/Footer/Footer'
import { StaticList } from '../../Components/StaticList/StaticList'
import { HiOutlinePresentationChartLine } from 'react-icons/hi'
import { AnimatedBlob } from '../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { StaticFlow } from '../../Components/StaticFlow/StaticFlow'
import { StaticGrid } from '../../Components/StaticGrid/StaticGrid'
import { StaticProps } from '../../Components/StaticProps/StaticProps'
import { RiTeamLine } from 'react-icons/ri'
import { IoBarbell } from 'react-icons/io5'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'

export const SolutionsIndividual = () => {
    return (
        <div className='solutionIndividuals-wrapper'>
            <AnimatedBlob number={2} top={'130vh'} right={'20vh'} scale={7} rotate={'90deg'} />
            <AnimatedBlob number={14} top={'180vh'} left={'20vh'} scale={3} rotate={'90deg'} />
            <AnimatedBlob number={3} top={'250vh'} left={'10vh'} scale={4} rotate={'45deg'} />
            <AnimatedBlob number={6} top={'300vh'} right={'40vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={7} top={'350vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={8} top={'400vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={9} top={'450vh'} left={'10vh'} scale={5} rotate={'45deg'} />
            <AnimatedBlob number={14} top={'500vh'} right={'10vh'} scale={5} rotate={'45deg'} />
            <div className='solutionIndividuals'>
                <div className='solutionIndividuals'>
                    <StaticTitle
                        title={'Empower Your Potential'}
                        subtitle={'Harness AI with A{P}PI to unlock new levels of productivity and creativity'}
                        buttons={{
                            main: 'Explore AI Tools',
                            secondary: 'Learn More',
                        }}
                    />

                    <StaticTextWithImage
                        title={'Elevate Your Work'}
                        text={'Transform the way you work with A{P}PI’s AI Experts. From optimizing daily tasks to exploring creative projects, discover how AI can be your ultimate productivity partner.'}
                        image={'https://via.placeholder.com/700x500'}
                        side={true}
                        bullets={[
                            {
                                'title': 'Boost Productivity',
                                'text': 'Maximize efficiency with AI-driven insights and automation.',
                            },
                            {
                                'title': 'Creative Freedom',
                                'text': 'Unleash your creativity with AI that understands your vision.',
                            },
                        ]}
                    />

                    <StaticTextWithImage
                        title={'Custom AI Just For You'}
                        text={'With A{P}PI, you’re not just using AI; you’re shaping it. Tailor AI tools to fit your unique workflow and see how custom solutions can elevate your projects.'}
                        image={'https://via.placeholder.com/700x500'}
                        side={false}
                        bullets={[
                            {
                                'title': 'Personalized Tools',
                                'text': 'Create AI solutions that adapt to your way of working.',
                            },
                            {
                                'title': 'Adaptive Learning',
                                'text': 'Grow with AI that learns from your preferences and styles.',
                            },
                        ]}
                    />

                    <StaticList
                        title={'Built for You'}
                        points={[
                            {
                                'title': 'Intuitive Design',
                                'text': 'Engage with an easy-to-use interface that makes AI less intimidating and more accessible.',
                                'icon': <HiOutlinePresentationChartLine />,
                            },
                            {
                                'title': 'Transparent Costs',
                                'text': 'Enjoy clear, straightforward pricing without any hidden fees. Know exactly what you’re paying for and why.',
                                'icon': <HiOutlinePresentationChartLine />,
                            },
                            {
                                'title': 'Diverse Expertise',
                                'text': 'Access a broad range of AI expertise, from data analysis to creative writing, all in one place.',
                                'icon': <HiOutlinePresentationChartLine />,
                            },
                            {
                                'title': 'Scalable Solutions',
                                'text': 'Whether you’re tackling a small project or a large-scale operation, our AI scales with your needs.',
                                'icon': <HiOutlinePresentationChartLine />,
                            },
                        ]}
                    />

                    <StaticProps
                        title='Grow with A{P}PI'
                        items={[
                            {
                                'title': 'Continuous Learning',
                                'text': 'Advance your skills with AI that evolves and adapts to the latest trends and technologies.',
                                'icon': <RiTeamLine />,
                            },
                            {
                                'title': 'Community and Support',
                                'text': 'Join a vibrant community of AI enthusiasts and professionals. Get the support you need, whenever you need it.',
                                'icon': <IoBarbell />,
                            },
                            {
                                'title': 'Certification and Recognition',
                                'text': 'Gain recognition for your AI skills with certificates that showcase your expertise.',
                                'icon': <AiOutlineSafetyCertificate />,
                            },
                        ]}
                    />

                    <StaticPrompt title={'Start Your AI Journey'}
                                  subtitle={'Dive into the world of AI with A{P}PI and discover how our tools can transform your work and creativity. Join us today.'} />

                    <Footer />
                </div>
            </div>
        </div>
    )
}