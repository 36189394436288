import React, { useEffect, useState, useRef } from 'react'
import './AnimatedBlob.scss'

interface AnimatedBlobInterface {
    number: number;
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
    width?: number | string;
    height?: number | string;
    scale?: number | string;
    rotate?: number | string;
    animated?: boolean;
    blobStyle?: React.CSSProperties;
    id?: string;
}

export const AnimatedBlob = ({
                                 number,
                                 top,
                                 left,
                                 right,
                                 bottom,
                                 scale,
                                 rotate,
                                 blobStyle = {},
                                 animated = true,
                                 width = '20vh',
                                 height = '20vh',
                                 id = 'default',
                             }: AnimatedBlobInterface) => {
    const path = require('../../../Images/BlobsWebp/blob' + number + '.webp')
    const [movement, setMovement] = useState({ x: '0px', y: '0px' })
    const blobRef = useRef<HTMLDivElement>(null)
    const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && animated) {
                    startAnimation()
                } else {
                    stopAnimation()
                }
            })
        }, { threshold: 0.1 })

        if (blobRef.current) {
            observer.observe(blobRef.current)
        }

        return () => {
            if (blobRef.current) {
                observer.unobserve(blobRef.current)
            }
            stopAnimation()
        }
    }, [animated])

    const startAnimation = () => {
        setMovement({ x: getRandomMovement(), y: getRandomMovement() })
        intervalIdRef.current = setInterval(() => {
            setMovement({ x: getRandomMovement(), y: getRandomMovement() })
        }, 5000)
    }

    const stopAnimation = () => {
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current)
            intervalIdRef.current = null
        }
    }

    const getRandomMovement = () => {
        const maxDistance = 20
        return `${Math.random() * maxDistance * 2 - maxDistance}px`
    }

    const style = {
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        width: width,
        height: height,
        transition: 'all 5s ease-in-out',
        transform: `scale(${scale}) rotate(${rotate}) translate(${movement.x}, ${movement.y})`,
    }
    return (
        <div ref={blobRef} className='animatedBlob-wrapper' style={style as React.CSSProperties} id={id}>
            <img className={animated ? 'animatedBlob' : 'animatedBlob animatedBlob-noAnim'} src={path}
                 alt='Animated blob' style={blobStyle} />
        </div>
    )
}
