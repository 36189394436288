import { ConfigDroplist } from './ConfigDroplist/ConfigDroplist'
import React, { useState } from 'react'
import { ModalOpen } from '../Conversation'

const APPI_icon = require('../../../../Images/Logo/APPI_icon.png')


interface StarterInterface {
    customProfiles: any
    selectedCustomInstruction: any
    setSelectedCustomInstruction: Function
    CustomProfilesOpen: boolean
    setCustomProfilesOpen: Function
    models: any[]
    selectedModel: any
    setSelectedModel: Function
    languages: any[]
    modal: ModalOpen
    setModal: Function
    selectedLanguage: any
    setSelectedLanguage: Function
}

export const Starter = ({
                            customProfiles,
                            selectedCustomInstruction,
                            setSelectedCustomInstruction,
                            CustomProfilesOpen,
                            setCustomProfilesOpen,
                            models,
                            selectedModel,
                            setSelectedModel,
                            languages,
                            selectedLanguage,
                            setSelectedLanguage,
                            modal,
                            setModal,
                        }: StarterInterface) => {

    const handleSelect = (modalTarget: ModalOpen, close: boolean) => {
        if (modal === modalTarget || close)
            setModal(ModalOpen.NULL)
        else
            setModal(modalTarget)
    }
    return (
        <div className="conversationStarter">
            <div className="chat-mainArea-chat-welcome">
                <img src={APPI_icon} alt="appi-icon" />
            </div>
            <div className="chat-mainArea-chat-config">
                {/*<ConfigDrolist className='chat-mainArea-chat-config-instructions'*/}
                {/*               string='Custom instructions'*/}
                {/*               open={CustomProfilesOpen}*/}
                {/*               setOpen={setCustomProfilesOpen}*/}
                {/*               selected={selectedCustomInstruction}*/}
                {/*               options={CustomProfiles}*/}
                {/*               setSelected={setSelectedCustomInstruction}*/}
                {/*               defaultSelection={{*/}
                {/*                   id: '-1',*/}
                {/*                   name: 'No instruction',*/}
                {/*                   instruction: '',*/}
                {/*               }}*/}
                {/*               displayname={false}*/}
                {/*/>*/}
                {/*<ConfigDroplist className="chat-mainArea-chat-config-models"*/}
                {/*                string="Model: "*/}
                {/*                open={modal === ModalOpen.MODELS}*/}
                {/*                setOpen={(close: boolean) => handleSelect(ModalOpen.MODELS, close)}*/}
                {/*                selected={selectedModel}*/}
                {/*                options={models}*/}
                {/*                setSelected={setSelectedModel}*/}
                {/*                defaultSelection={models[0]}*/}
                {/*                category={'creator'}*/}
                {/*/>*/}
                {/*<ConfigDroplist className="chat-mainArea-chat-config-models"*/}
                {/*                string="Language: "*/}
                {/*                open={modal === ModalOpen.LANGUAGES}*/}
                {/*                setOpen={(close: boolean) => handleSelect(ModalOpen.LANGUAGES, close)}*/}
                {/*                selected={selectedLanguage}*/}
                {/*                options={languages}*/}
                {/*                setSelected={setSelectedLanguage}*/}
                {/*                defaultSelection={languages[0]}*/}
                {/*/>*/}
            </div>
        </div>
    )
}