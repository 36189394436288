import { useState, useRef } from 'react'
import './SidebarHoverComponent.scss'

interface SidebarHoverComponentInterface {
    children: any,
    text: string,
}

export const SidebarHoverComponent = ({ children, text }: SidebarHoverComponentInterface) => {
    const [visible, setVisible] = useState<boolean>(false)
    const hoverTimeout = useRef<NodeJS.Timeout | null>(null)

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setVisible(true)
        }, 500) // Set to visible after 1 second
    }

    const handleMouseLeave = () => {
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current) // Clear the timeout if mouse leaves before 1 second
        }
        setVisible(false)
    }

    return (
        <div className='sidebar-hover-component'
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
        >
            {children}
            {visible && <div className='sidebar-hover-component-text'>{text}</div>}
        </div>
    )
}
