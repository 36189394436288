import './Chat.scss'
import './ChatMobile.scss'
import React, { useEffect, useState } from 'react'
// import MessageInterface, { MessageType, SubMessageInterface } from './Message/MessageInterface'
import { getExperts, getSummarizers } from '../../Requests/agentRequests'
import { getMyUserAccount } from '../../Requests/userRequests'
import { Conversation } from './Conversation/Conversation'
import { UserInput } from './UserInput/UserInput'
import { Sidebar } from './Sidebar/Sidebar'
import {
    AddNextPrompt,
    deleteAllConversations,
    deleteConversation,
    getConversation,
    SelectAnswerAsFinalResponse,
    SelectExpertsAnswers,
    startConversation,
} from '../../Requests/conversationRequests'
import { ConfirmModal, Modal } from '../../Components/Modal/Modal'
import { ConversationState, MessageFlow, SidebarPages } from '../../Utils/enums'
import TextareaAutosize from 'react-textarea-autosize'
import {
    createCustomInstruction,
    editCustomInstruction,
    getCustomProfiles,
} from '../../Requests/customProfilesRequests'
import { AgentInterface } from '../../Interfaces/agentsInterfaces'
import { usePageBottom } from '../../Utils/hooks'
import Cookies from 'js-cookie'
import { CustomInstruction } from '../../Interfaces/chatInterfaces'
import { getModels } from '../../Requests/aiModelRequests'
import SEO from '../../Components/SEO'
import { AlertSeverity, AlertType } from '../../Interfaces/alertsInterfaces'
import { useStreamHandler } from './StreamHandler'
import {
    ConversationInterfaceApi,
    ExpertAnswerInterface,
    MessageInterface,
    MessageInterfaceApi,
    MessagePartType,
    MessageType,
    ResponseInterface,
} from '../../Interfaces/messageInterfaces'
import { GenerationStage, InspectorMode, useChatContext } from './ChatPropsManager'
import { Inspector } from './Inspector/Inspector'
import { handleError } from '../../Utils/RequestsUtil'
import { chatPresets } from '../../Dev/chatPresets'
import { ChunkType } from './Message/MessageInterface'

const languages = require('./languages.json')
const APPI = require('../../Images/Logo/APPI_icon.png')

const randomColor = () => {
    const hue = Math.floor(Math.random() * 360)
    const saturation = 30 + Math.random() * 40
    const lightness = 70 + Math.random() * 10
    return `hsl(${hue},${saturation}%,${lightness}%)`
}

interface ChatInterface {
    sendAlert: Function,
}


const Chat = ({ sendAlert }: ChatInterface) => {

    const defaultInstruction: CustomInstruction = {
        id: '-1',
        name: 'No profile',
        instruction: '',
    }
    const {
        dims,
        agentsList,
        setAgentsList,
        mobile,
        summarizersList,
        setSummarizersList,
        location,
        navigate,
        agentsSelected,
        setAgentsSelected,
        summarizer,
        setSummarizer,
        sidebar,
        setSidebar,
        history,
        setHistory,
        selectedConversation,
        setSelectedConversation,
        messages,
        setMessages,
        conversationState,
        setConversationState,
        selectedItem,
        setSelectedItem,
        historyPing,
        setHistoryPing,
        historyModal,
        setHistoryModal,
        selectedAnswers,
        setSelectedAnswers,
        summarizerId,
        setSummarizerId,
        finalAnswer,
        setFinalAnswer,
        models,
        setModels,
        selectedModel,
        setSelectedModel,
        customProfiles,
        setCustomProfiles,
        selectedCustomInstruction,
        setSelectedCustomInstruction,
        newInstruction,
        setNewInstruction,
        editInstruction,
        setEditInstruction,
        setLoadingHistory,
        loadingConversation,
        setLoadingConversation,
        setCustomProfilesOpen,
        setSidebarModal,
        customInstructionNameRef,
        customInstructionRef,
        userMessageRef,
        setFlow,
        chosenMessages,
        setChosenMessages,
        selectedLanguage,
        messagesLoaded,
        setMessagesLoaded,
        inspectorOpen,
        setInspectorOpen,
        globalState,
        inspectorMode,
        setInspectorMode,
        flow,
        conversationContainerRef,
        setInspectorContext,
        setGenerationStage,
    } = useChatContext()
    const reachedBottom = usePageBottom()
    const streamHandler = useStreamHandler()

    const [progressiveText, setProgressiveText] = useState<{ [id: string]: string }>({})

    useEffect(() => {
        if (customProfiles.length === 1)
            getCustomProfiles().then((response: any) => {
                setCustomProfiles(customProfiles.concat(response))
            })
        if (agentsList.length === 0 || summarizersList.length === 0 || globalState.user.id) {
            getExperts(globalState.developer).then((response: any) => {
                response.map((agent: any) => {
                    if (!agent.hasOwnProperty('img') && (!agent.hasOwnProperty('logoColorHex') || agent.logoColorHex === ''))
                        agent.logoColorHex = randomColor()
                    return agent
                })
                setAgentsList(response)
            })
            getSummarizers().then((response: any) => {
                response.map((agent: any) => {
                    if (!agent.hasOwnProperty('img') && !agent.hasOwnProperty('logoColorHex'))
                        agent.logoColorHex = randomColor()
                    return agent
                })
                setSummarizersList(response)
            })
        }

        if (models.length === 0) {
            getModels().then((response: any) => {
                setModels(response)
                setSelectedModel(response[0])
            })
        }
    }, [globalState.developer, globalState.user])

    useEffect(() => {
        const id = location.search.slice(1).split('&')[0]
        setSidebarModal(false)
        setMessagesLoaded(false)
        document.body.style.overflow = 'auto'
        if (id !== 'new') {
            setSelectedConversation(id)
        } else {
            setSelectedConversation('-1')
        }
    }, [location])

    useEffect(() => {
        if (selectedConversation !== '-1' && selectedConversation) {
            setLoadingConversation(true)
            fetchFullConversation().then(r => {
                    scrollDown()
                    setGenerationStage(GenerationStage.NONE)
                },
            )
        }
        if (selectedConversation === '' || selectedConversation === '-1') {
            setLoadingConversation(false)
            setCustomProfilesOpen(false)
            setFlow([])
            setMessages([])
            setChosenMessages([])
            setSelectedCustomInstruction(defaultInstruction)
            setSummarizerId([null, null])
            setAgentsSelected(globalState.user?.defaultExperts ? globalState.user.defaultExperts : [])
            setSummarizer(globalState.user?.defaultSummarizer ? globalState.user.defaultSummarizer : null)
            setConversationState(ConversationState.READY)
            setInspectorContext([])
            setInspectorOpen(false)
            setGenerationStage(GenerationStage.NONE)
        }
    }, [selectedConversation])

    useEffect(() => {
        const agentToSelect = location.search.slice(1).split('&')[1]
        const type = agentToSelect?.split('=')[0]
        const agent_id = agentToSelect?.split('=')[1]
        if (type === 'expert') {
            const agent: AgentInterface | undefined = agentsList.find((agent: any) => agent.id === agent_id)
            if (agent)
                setAgentsSelected([agent])
        }
        if (type === 'summarizer') {
            const agent: AgentInterface | undefined = summarizersList.find((agent: any) => agent.id === agent_id)
            if (agent)
                setSummarizer(agent)
        }
    }, [agentsList, summarizersList])

    useEffect(() => {
        if (history.length === 0)
            setLoadingHistory(true)
        getMyUserAccount().then((response: any) => {
            setHistory(response.conversations)
            setLoadingHistory(false)
        })
    }, [historyPing])

    useEffect(() => {
        if (selectedConversation === '')
            setAgentsSelected(globalState.user?.defaultExperts ? globalState.user.defaultExperts : [])
    }, [globalState.user])

    useEffect(() => {
        const intervals: { [id: string]: any } = {}
        for (const [key, value] of Object.entries(streamHandler.conversations)) {
            const conversation_id = key.split('_')[0]
            const user_message_id = key.split('_')[1]
            const agent_answer_id = key.split('_')[2]
            const agent_id = key.split('_')[3]
            const target_message = chosenMessages.find((message: MessageInterface) => message.id === user_message_id)
            if (target_message) {
                if (reachedBottom) scrollDown()
                const target_submessage = target_message?.agentAnswers.find((submessage) => submessage.id === agent_answer_id)
                if (target_submessage) {
                    if (!progressiveText[agent_answer_id]) {
                        setProgressiveText((prev) => ({ ...prev, [agent_answer_id]: '' }))
                    }
                    if (!intervals[agent_answer_id]) {
                        intervals[agent_answer_id] = setInterval(() => {
                            setProgressiveText((prev) => {
                                const currentText = prev[agent_answer_id] || ''
                                if (currentText.length < value.chunk.Content.length) {
                                    return {
                                        ...prev,
                                        [agent_answer_id]: value.chunk.Content.slice(0, currentText.length + 1),
                                    }
                                } else {
                                    clearInterval(intervals[agent_answer_id])
                                    return prev
                                }
                            })
                        }, 5)
                    }
                    if (target_submessage.context?.length === 0) {
                        target_submessage.context = streamHandler.contexts[agent_id] ? [streamHandler.contexts[agent_id][0]] : []
                    }
                } else {
                    const new_response: ResponseInterface = {
                        id: '0',
                        text: value.chunk.Content,
                        messagePartContent_TextAlt: value.chunk.Content,
                        messagePartType: MessagePartType.TEXT,
                        senderId: value.agentId,
                    }
                    const context = streamHandler.contexts[agent_id] ? [streamHandler.contexts[agent_id][0]] : []
                    const new_submessage: ExpertAnswerInterface = {
                        id: agent_answer_id,
                        response: [new_response],
                        expertResponding: {
                            'name': value.name,
                            'id': value.agentId,
                        },
                        createdAt: new Date(),
                        agentAnswerType: MessageType.EXPERT_ANSWER,
                        chosen: false,
                        chosenFinal: false,
                        finished: false,
                        chunk: 0,
                        conversationId: conversation_id,
                        context: context,
                    }
                    target_message?.agentAnswers.push(new_submessage)
                    setProgressiveText((prev) => ({ ...prev, [agent_answer_id]: '' }))
                    intervals[agent_answer_id] = setInterval(() => {
                        setProgressiveText((prev) => {
                            const currentText = prev[agent_answer_id] || ''
                            if (currentText.length < value.chunk.Content.length) {
                                return {
                                    ...prev,
                                    [agent_answer_id]: value.chunk.Content.slice(0, currentText.length + 1),
                                }
                            } else {
                                clearInterval(intervals[agent_answer_id])
                                return prev
                            }
                        })
                    }, 5)
                }
                // } else {
                //     const target_submessage = target_message.summarizerAnswer
                //     if (target_submessage) {
                //         if (target_submessage.chunk !== undefined) {
                //             target_submessage.text = value.chunk
                //         }
                //     } else {
                //         if (target_message)
                //             target_message.summarizerAnswer = {
                //                 id: value.agentAnswerId,
                //                 text: value.chunk,
                //                 sender: {
                //                     'name': value.name,
                //                     'id': value.agentId,
                //                 },
                //                 timestamp: new Date(),
                //                 messageType: MessageType.SUMMMARIZER_ANSWER,
                //                 chosen: false,
                //                 chosenFinal: false,
                //                 finished: false,
                //                 chunk: 0,
                //             }
                //     }
                // }
            } else {
                console.log('target_message not found', user_message_id)
            }
        }
        return () => {
            Object.values(intervals).forEach(clearInterval)
        }
    }, [streamHandler.conversations])

    useEffect(() => {
        chosenMessages.forEach((message) => {
            message.agentAnswers.forEach((submessage) => {
                if (progressiveText[submessage.id]) {
                    submessage.response[0].text = progressiveText[submessage.id]
                }
            })
            if (message.summarizerAnswer && progressiveText[message.summarizerAnswer.id]) {
                message.summarizerAnswer.text = progressiveText[message.summarizerAnswer.id]
            }
        })
    }, [progressiveText])

    // useEffect(() => {
    //     if (messagesLoaded) {
    //         const last_message = chosenMessages[chosenMessages.length - 1]
    //     }
    // }, [messagesLoaded])

    const fetchFullConversation = async (response: null | ConversationInterfaceApi = null) => {
        let response_messages: string | any[] = []
        const fetchFullConversationLogin = async (response: ConversationInterfaceApi) => {
            // if (response.customUserInstructionDto) {
            //     const id = response.customUserInstructionDto.id
            //     const customInstruction: CustomInstruction | undefined = customProfiles.find(item => item.id === id)
            //     // @ts-ignore
            //     setSelectedCustomInstruction(customInstruction)
            // } else {
            //     setSelectedCustomInstruction(defaultInstruction)
            // }
            // @ts-ignore
            response_messages = response.message.sort((a: MessageInterfaceApi, b: MessageInterfaceApi) => new Date(a.createdAt) - new Date(b.createdAt))
            await fetchMessages(response_messages)
            setLoadingConversation(false)
        }
        if (!response)
            getConversation(selectedConversation).then(async (response: ConversationInterfaceApi) => {
                await fetchFullConversationLogin(response)
            })
        else {
            await fetchFullConversationLogin(response)
        }
    }
    const fetchMessages = async (submessages: MessageInterfaceApi[]) => {
        const new_messages: MessageInterface[] = []
        const message_ids = messages?.map((message: MessageInterfaceApi) => message.id)
        const previous_messages = messages
        for (const submessage of submessages) {
            if (message_ids.includes(submessage.id)) {
                continue
            }
            if (submessage.messageType === MessageType.CONTEXT_MESSAGE) {
                continue
            }
            const temp_ids = submessage.listOfAgentIds.split('&')
            const agent_ids = temp_ids[0].split(',')
            const summarizer_id = temp_ids[1] ? temp_ids[1] : ''
            submessage.agentAnswerIds = agent_ids
            submessage.summarizerAnswerIds = summarizer_id
            // let summarizerAnswer: SubMessageInterface | null = null
            // if (submessage.summarizationDto)
            //     await setSummarizerId([submessage.summarizationDto.id, submessage.id, submessage.summarizationDto.summarizationExpert.name, submessage.summarizationDto.summarizationExpert.id])
            //
            // if (submessage.AgentAnswers)
            //     for (const response of submessage.AgentAnswers) {
            //         if (response.answer !== null)
            //             submessage.AgentAnswers.push({
            //                 id: response.id,
            //                 text: response.answer,
            //                 sender: response.ExpertResponding,
            //                 timestamp: new Date(),
            //                 messageType: MessageType.EXPERT_ANSWER,
            //                 chosen: false,
            //                 chosenFinal: false,
            //                 finished: submessage.IsAnswered,
            //             })
            //     }

            // if (submessage.summarizationDto && submessage.summarizationDto.summary) {
            //     summarizerAnswer = {
            //         id: submessage.summarizationDto.id,
            //         text: submessage.summarizationDto.summary,
            //         sender: submessage.summarizationDto.summarizationExpert,
            //         timestamp: new Date(),
            //         messageType: MessageType.SUMMARIZER,
            //         chosenFinal: submessage.summarizationDto.ifChosen,
            //         chosen: submessage.summarizationDto.ifUsedForSummarization,
            //         finished: submessage.isAnswered,
            //     }
            // }
            let flow = MessageFlow.FULL
            if (submessage.agentAnswerIds?.length === 1) {
                if (!submessage.summarizerAnswerIds || submessage.summarizerAnswerIds.length === 0)
                    flow = MessageFlow.NO_SUMMARY_ONE_EXPERT
                else
                    flow = MessageFlow.ONE_EXPERT
            } else if (submessage.summarizerAnswerIds.length === 0)
                flow = MessageFlow.NO_SUMMARY

            const finished = (submessage.selectedExpertAnswerId !== null || submessage.selectedSummarizerAnswerId !== null)
            const agentAnswers: ExpertAnswerInterface[] = []
            if (submessage.agentAnswers) {
                for (const response of submessage.agentAnswers) {
                    if (response.id === submessage?.selectedExpertAnswerId) {
                        response.chosenFinal = true
                    } else {
                        response.chosenFinal = false
                    }

                    if (finished) {
                        response.finished = true
                    } else {
                        response.finished = false
                    }
                    agentAnswers.push(response)
                }
            }


            const temp_message: MessageInterface = {
                ...submessage,
                agentAnswers: agentAnswers,
                messageFlow: flow,
                messageType: MessageType.USER_MESSAGE,
                summarizerAnswer: null,
                versions: 0,
                children: [],
                depth: 0,
                finished: finished,
            }
            new_messages.push(temp_message)
        }
        setMessages(previous_messages.concat(new_messages))
    }

    useEffect(() => {
        const last_message: MessageInterface = chosenMessages[chosenMessages.length - 1]
        if (conversationState === ConversationState.LOADING_AGENT_RESPONSE && chosenMessages.length > 0 && !last_message.finished) {
            setGenerationStage(GenerationStage.FETCHING_CONTEXT)
            expertStream().then(() => {
                const last_message_flow = last_message.messageFlow
                if (last_message_flow === MessageFlow.NO_SUMMARY_ONE_EXPERT || last_message_flow === MessageFlow.NO_SUMMARY) {
                    chooseFinal()
                } else
                    choose()
            }).catch((e) => {
                console.log(e)
                setConversationState(ConversationState.READY)
                setGenerationStage(GenerationStage.NONE)
            })
        }
    }, [conversationState, chosenMessages])


    const expertStream = async () => {
        const last_message = chosenMessages[chosenMessages.length - 1]
        if (last_message && last_message.agentAnswerIds) {
            const generated_answers = last_message.agentAnswers.map((answer: any) => answer.expertResponding.id)
            const streamPromises = last_message.agentAnswerIds.map(response => {
                let expertName = ''
                const expert = agentsSelected.find((agent: any) => agent.id === response)
                if (expert)
                    expertName = expert.name
                else
                    expertName = 'Expert'
                if (response && !generated_answers.includes(response)) {
                    try {
                        return streamResponse('ExpertResponseStreamWithPythonModule', response, last_message.id, '0', expertName)
                    } catch (e) {
                        throw new Error('There was an issue with streaming response. Please try again later.')
                    }
                }
            })

            await Promise.all(streamPromises)
        }
    }

    const choose = () => {
        const last_message: MessageInterface = chosenMessages[chosenMessages.length - 1]
        if (last_message.messageFlow === MessageFlow.NO_SUMMARY_ONE_EXPERT && last_message.agentAnswers[0]) {
            sendFinalAnswer([last_message.agentAnswers[0].id, 1]).then(() => {
                setConversationState(ConversationState.READY)
            })
        } else if (last_message.messageFlow === MessageFlow.ONE_EXPERT && last_message.agentAnswers[0]) {
            sendAnswers([last_message.agentAnswers[0].id]).then(r => {
                setConversationState(ConversationState.LOADING_SUMMARIZER_RESPONSE)
            })
        } else
            setConversationState(ConversationState.CHOOSE)
    }

    const sendAnswers = async (answers: any = null) => {
        if (answers.length === 0)
            return
        else {
            SelectExpertsAnswers(answers).then(async (response: any) => {
                    if (messages.length > 0) {
                        const last_message: MessageInterface = messages[messages.length - 1]
                        if (answers) {
                            for (const message of last_message.agentAnswers) {
                                if (answers.includes(message.id)) {
                                    message.chosen = true
                                }
                            }
                            setSelectedAnswers([])
                            setConversationState(ConversationState.LOADING_SUMMARIZER_RESPONSE)
                        }
                    }
                },
            )
        }
    }
    //
    // useEffect(() => {
    //     if (conversationState === ConversationState.LOADING_SUMMARIZER_RESPONSE && chosenMessages.length > 0) {
    //         summaryStream().then(() => {
    //             chooseFinal()
    //         })
    //     }
    // }, [conversationState, chosenMessages])
    //
    // const summaryStream = async () => {
    //     const last_message = chosenMessages[chosenMessages.length - 1]
    //     if (last_message && last_message.id && summarizerId[0]) {
    //         await streamResponse('ExpertSummaryStream?summarizerAnswerId=', summarizerId[0], last_message.id, '1', summarizerId[2], summarizerId[3]).catch((e) => {
    //             console.log(e)
    //         })
    //     }
    // }
    //
    const chooseFinal = () => {
        const last_message = chosenMessages[chosenMessages.length - 1]
        if (last_message && last_message.messageFlow === MessageFlow.NO_SUMMARY_ONE_EXPERT && last_message.agentAnswers[0]) {
            sendFinalAnswer([last_message.agentAnswers[0].id, 1]).then(() => {
                setConversationState(ConversationState.READY)
            }).catch((e) => {
                setConversationState(ConversationState.CHOOSE_FINAL)
            })
        } else
            setConversationState(ConversationState.CHOOSE_FINAL)

    }

    const sendFinalAnswer = async (final: any = null) => {
        await SelectAnswerAsFinalResponse(final[0]).then(async (res: any) => {
            const temp = [...messages]
            const last_message = temp[temp.length - 1]
            last_message.finished = true
            if (last_message.summarizerAnswer && last_message.summarizerAnswer.id === final[0]) {
                last_message.summarizerAnswer.chosenFinal = true
            }
            if (last_message.summarizerAnswer)
                last_message.summarizerAnswer.finished = true
            for (let i = 0; i < last_message.agentAnswers.length; i++) {
                if (last_message.agentAnswers[i].id === final[0]) {
                    last_message.agentAnswers[i].chosenFinal = true
                }
                last_message.agentAnswers[i].finished = true
            }
            setMessages(temp)
        })
        setConversationState(ConversationState.READY)
    }

    const streamResponse = async (URL: string, expert_id: string, last_message_id: string, type: string, name: string) => {
        let response = null
        const request_url = process.env.REACT_APP_API_URL + 'StreamingResponse/' + URL + '?expertId=' + expert_id + '&userMessageId=' + last_message_id
        response = await fetch(request_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Cookies.get('jwt'),
            },
            // body: JSON.stringify({
            //     'aiModelId': '801257af-87b4-46b9-8ed0-3d7404804e5e',
            //     'temperature': 1,
            //     'topP': .95,
            //     'frequencyPenalty': 0,
            //     'presencePenalty': 0,
            // }),
            body: '{}',
        })
        console.log(response)
        // response = mockStreamResponse()

        if (!response.ok) {
            console.log('SENDING ALERT')
            sendAlert(
                AlertType.UNKNOWN,
                'There was an issue with streaming response. Please try again later.',
                AlertSeverity.ERROR,
            )
            throw new Error('There was an issue with streaming response. Please try again later.')
        }

        if (response && response.body) {
            const reader = response.body.getReader()
            const decoder = new TextDecoder()
            let iter = 0
            setGenerationStage(GenerationStage.GENERATING_RESPONSE)
            const delay = (ms: any) => new Promise(res => setTimeout(res, ms))
            while (iter < 2) {
                let done, value
                try {
                    while (true) {
                        ({ done, value } = await reader.read())
                        if (done) {
                            break
                        }
                        const decoded_chunk = decoder.decode(value, { stream: true })
                        const chunks_splitted = decoded_chunk.split('}}{')
                        for (let i = 0; i < chunks_splitted.length; i++) {
                            if (i === 0) {
                                chunks_splitted[i] = chunks_splitted[i] + '}}'
                            } else if (i === chunks_splitted.length - 1) {
                                chunks_splitted[i] = '{' + chunks_splitted[i]
                            } else {
                                chunks_splitted[i] = '{' + chunks_splitted[i] + '}}'
                            }
                        }
                        for (const chunk of chunks_splitted) {
                            processChunk(chunk, last_message_id, type, name, expert_id)
                        }
                    }

                } catch (NSError) {
                    console.log(NSError)
                    streamHandler.flush()
                }
                await delay(200)
                iter++
            }
            console.log('FINAL FLUSH')
            streamHandler.flush()
            setGenerationStage(GenerationStage.NONE)
        }

    }

    const sendMessage = async (messagePassed?: string) => {
        if (userMessageRef && userMessageRef.current) {
            let message
            if (messagePassed === undefined || messagePassed === null) {
                // @ts-ignore
                message = userMessageRef.current.value
            } else
                message = messagePassed
            if (message === '')
                return
            const agents_ids = agentsSelected.map((agent: AgentInterface) => agent.id)
            let summarizer_id = null
            if (summarizer)
                summarizer_id = summarizer.id
            streamHandler.resetLastNumberChunks()
            if (messages.length === 0) {
                const selectedCustomInstructionToParse = selectedCustomInstruction.id !== '-1' ? selectedCustomInstruction.id : null
                startConversation(message.toString(), agents_ids, summarizer_id, selectedCustomInstructionToParse, selectedModel.id, selectedLanguage.name)
                    .then((response: any) => {
                        setHistory([{
                            'id': response.id,
                            'name': response.name,
                            'startedAt': response.startedAt,
                        }, ...history])
                        if (process.env.REACT_APP_DEV === 'true')
                            navigate(`/dev-chat?${response.id}`)
                        else
                            navigate(`/?${response.id}`)
                        setConversationState(ConversationState.LOADING_AGENT_RESPONSE)
                    })
                    .catch((error: any) => {
                        console.log(error)
                    })
            } else {
                const agents_ids = agentsSelected.map((agent: any) => agent.id)
                let summarizer_id = null
                if (summarizer)
                    summarizer_id = summarizer.id
                const previous_message = chosenMessages[chosenMessages.length - 1].id
                setConversationState(ConversationState.LOADING_AGENT_RESPONSE)
                AddNextPrompt(selectedConversation, message.toString(), agents_ids, summarizer_id, previous_message ? previous_message : '').then(async (response: any) => {
                    await fetchFullConversation(response)
                    setFlow([...flow, 0])
                    // setConversationState(ConversationState.UNKNOWN)
                }).catch(error => {
                    handleError(error, sendAlert)
                    navigate(`/?new`)
                })
            }
            // @ts-ignore
            userMessageRef.current.value = ''
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        }
    }

    const createCustomInstructionFunc = () => {
        const name = customInstructionNameRef.current.value
        const instruction = customInstructionRef.current.value
        if (name === '' || instruction === '')
            return
        createCustomInstruction(name, instruction).then((response: any) => {
            setCustomProfiles([...customProfiles, response])
            setNewInstruction(false)
        })
    }

    const editCustomInstructionFunc = () => {
        const name = customInstructionNameRef.current.value
        const instruction = customInstructionRef.current.value
        if (name === '' || instruction === '')
            return
        editCustomInstruction(editInstruction.id, name, instruction).then((response: any) => {
            const index = customProfiles.findIndex((instruction: any) => instruction.id === editInstruction.id)
            const newCustomProfiles = [...customProfiles]
            newCustomProfiles[index] = response
            setCustomProfiles(newCustomProfiles)
            setEditInstruction(null)
        })
    }

    const handleEditPrompt = (previousMessageId: string | null, prompt: string, experts_ids: string[], summarizer_id: string) => {
        AddNextPrompt(selectedConversation, prompt.toString(), experts_ids, summarizer_id, previousMessageId).then(async (response: any) => {
            await fetchFullConversation(response)
        })
    }

    const processChunk = (chunk: any, last_message_id: string, type: string, name: string, agentId: string) => {
        let chunkParsed
        try {
            chunkParsed = JSON.parse(chunk)
        } catch (e) {
            return
        }
        chunkParsed['lastMessageId'] = last_message_id
        chunkParsed['type'] = type
        chunkParsed['name'] = name
        chunkParsed['agentId'] = agentId
        chunkParsed['conversationId'] = selectedConversation

        switch (chunkParsed.chunk.Type) {
            case ChunkType.CONTEXT:
                streamHandler.addContext(chunkParsed)
                break
            case ChunkType.RESPONSE:
                streamHandler.addChunk(chunkParsed)
                break
            case ChunkType.STATUS:
                console.log('STATUS', chunkParsed)
                break
        }
    }

    const executeChatPreset = async (index: number) => {
        const preset = chatPresets[index]
        const agentsToSelect = agentsList.filter((agent: any) => preset.experts_ids.includes(agent.id))
        setAgentsSelected(agentsToSelect)
        await sendMessage(preset.prompt)
    }

    const scrollDown = (smooth: boolean = true) => {
        const container = conversationContainerRef.current
        if (container)
            container.scrollTo({
                top: container.scrollHeight,
                behavior: smooth ? 'smooth' : 'auto',
            })
    }

    return (
        <div className="chat">
            <SEO
                title="APPI | Chat"
                description="APPI chat page"
                name="APPI Chat"
                keywords={['APPI', 'chat', 'chatbot', 'artificial intelligence', 'AI', 'chat page']}
                type="website"
            />
            {(historyModal !== null && historyModal === '') && <ConfirmModal
                title="Clear history"
                description="This action will delete all of your conversations"
                close={() => setHistoryModal(null)}
                proceed={() => {
                    deleteAllConversations()
                        .then(() => {
                            setHistoryPing(!historyPing)
                            setHistoryModal(null)
                            navigate(`/?new`)
                            window.location.reload()
                        })
                }}
            />}

            {(historyModal !== null && historyModal !== '') && <ConfirmModal
                title="Delete conversation"
                description="This action will delete selected conversation"
                close={() => setHistoryModal(null)}
                proceed={() => {
                    deleteConversation(selectedConversation).then(() => {
                        setHistoryPing(!historyPing)
                        setHistoryModal(null)
                        if (process.env.REACT_APP_DEV === 'true')
                            navigate(`/dev-chat?new`)
                        else
                            navigate(`/?new`)
                    })
                }}
            />}

            {newInstruction && <Modal close={() => setNewInstruction(false)}>
                <h1 className="CustomProfiles-header">Create new custom profile</h1>
                <form className="CustomProfiles-form">
                    <input type="text" placeholder="Profile name" ref={customInstructionNameRef} />
                    <TextareaAutosize minRows={10} maxRows={15} className="CustomProfiles-form-textarea"
                                      placeholder="Profile instruction" ref={customInstructionRef} />
                    <div className="CustomProfiles-form-buttons">
                        <div className="CustomProfiles-form-buttons-cancel"
                             onClick={() => setNewInstruction(false)}>Cancel
                        </div>
                        <div className="CustomProfiles-form-buttons-proceed"
                             onClick={() => {
                                 createCustomInstructionFunc()
                             }}>Proceed
                        </div>
                    </div>
                </form>
            </Modal>}

            {editInstruction && <Modal close={() => setEditInstruction(null)}>
                <h1 className="CustomProfiles-header">Edit instruction</h1>
                <form className="CustomProfiles-form">
                    <input type="text" placeholder="Profile name" ref={customInstructionNameRef}
                           defaultValue={editInstruction.name} />
                    <TextareaAutosize minRows={10} maxRows={15} className="CustomProfiles-form-textarea"
                                      placeholder="Profile Instruction" ref={customInstructionRef}
                                      defaultValue={editInstruction.instruction} />
                    <div className="CustomProfiles-form-buttons">
                        <div className="CustomProfiles-form-buttons-cancel"
                             onClick={() => setEditInstruction(null)}>Cancel
                        </div>
                        <div className="CustomProfiles-form-buttons-proceed"
                             onClick={() => {
                                 editCustomInstructionFunc()
                             }}>Proceed
                        </div>
                    </div>
                </form>
            </Modal>}

            <Sidebar
                navigate={navigate}
                APPI={APPI}
                executeChatPreset={executeChatPreset}
            />
            {/*{(messages.length === 0 && !loadingConversation) && <FreeBlobEnv*/}
            {/*    width={'100%'} height={'100%'} min={30} max={10}*/}
            {/*/>}*/}
            <div className="chat-conversation" style={(sidebar !== SidebarPages.None && !mobile) ? {
                width: 'calc(100% - 21rem)',
                marginLeft: '21rem',
            } : { width: '100%' }}>
                <div
                    className={(messages.length === 0 && !loadingConversation) ? 'chat-mainArea chat-mainArea-welcome' : 'chat-mainArea'}>
                    <Conversation
                        editPrompt={handleEditPrompt}
                        languages={languages}
                        scrollDown={scrollDown}
                        choose={choose}
                        chooseFinal={chooseFinal}

                    />
                    <form className="userInput-form">
                        <UserInput
                            SidebarInterface={SidebarPages}
                            sendAnswers={sendAnswers}
                            sendFinalAnswer={sendFinalAnswer}
                            sendMessage={sendMessage}
                            user_message_ref={userMessageRef}
                        />
                        <h1 className="userInput-form-notice">LLM's can make mistakes. Consider checking important
                            information.</h1>
                    </form>
                </div>
            </div>
            <div className="chat-inspector-icons">
                {(inspectorOpen && inspectorMode === InspectorMode.DOCUMENT) &&
                    <div className="chat-inspector-icon chat-inspector-close" onClick={
                        () => setInspectorMode(InspectorMode.BROWSER)

                    }>
                        Back
                    </div>}
                <div className="chat-inspector-icon" onClick={
                    () => setInspectorOpen(!inspectorOpen)
                }>
                    {inspectorOpen ? 'Close' : 'Inspector'}
                </div>
            </div>
            {inspectorOpen && <Inspector images={false} />}

        </div>

    )

}
export default Chat