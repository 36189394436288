import React, { useEffect } from 'react'
import './Payment.scss'
import { useGlobalState } from '../../../../GlobalStateContext'
import { AiOutlineDollarCircle } from 'react-icons/ai'

interface PaymentInterface {
    user: any;
}

export const Payment = ({ user }: PaymentInterface) => {

    const [globalState, globalActions] = useGlobalState()

    return (
        <div className="dashboard-content-payment">
            <div className="dashboard-content-payment-balance">
                <h1>Your balance:</h1>
                <p>{globalState.user ? globalState.user?.balance : '0'} </p>
            </div>
            <h1 className="dashboard-content-payment-title">Recharge your balance</h1>
            <div className="dashboard-content-payment-recharge">
                <input placeholder="Enter amount" />
                <button className="dashboard-content-payment-recharge-button">Buy</button>
            </div>
            <p className="dashboard-content-payment-warning">This feature is coming soon! If you need to recharge your
                balance please contact us at:</p>
            <a href="mailto:office@appi-marketplace.com"
               className="dashboard-content-payment-warning-link">office@appi-marketplace.com</a>
        </div>
    )
}