import './Prompts.scss'
import TextareaAutosize from 'react-textarea-autosize'
import { useEffect, useRef, useState } from 'react'
import { getExpertDetails, updateExpert, updateSummarizer } from '../../../../Requests/agentRequests'
import { ErrorType } from '../../../../Utils/enums'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'

interface PromptsInterface {
    agent: any;
    setAgent: Function;
    type: string;
}

export const Prompts = ({ agent, setAgent, type }: PromptsInterface) => {

    const systemPromptRef = useRef(null)
    const userPromptRef = useRef(null)

    useEffect(() => {
        if (agent) {
            // @ts-ignore
            systemPromptRef.current.value = agent.systemPrompt
            // @ts-ignore
            userPromptRef.current.value = agent?.expertPrompt
        }
    }, [agent])

    const updateAgent = () => {
        // @ts-ignore
        let system = systemPromptRef.current.value
        // @ts-ignore
        let user = userPromptRef.current.value

        if (system === agent.systemPrompt && user === agent?.expertPrompt)
            return

        if (type === 'expert') {
            updateExpert({
                id: agent.id,
                sysPrompt: system,
                userPrompt: user,
            }).then((res) => {
                console.log(res)
                if (!Object.values(ErrorType).includes(res)) {
                    setAgent(res)
                }
            })
        } else {
            updateSummarizer({
                id: agent.id,
                sysPrompt: system,
                userPrompt: user,
            }).then((res) => {
                if (!Object.values(ErrorType).includes(res)) {
                    setAgent(res)
                }
            })
        }
    }

    return (
        <div className='prompts'>
            <h1>System prompt</h1>
            <TextareaAutosize minRows={3} maxRows={25} className='input'
                              placeholder={agent?.systemPrompt ? agent?.systemPrompt : ''} ref={systemPromptRef} />
            <span />
            <h1>User prompt</h1>
            <TextareaAutosize minRows={3} maxRows={25} className='input'
                              placeholder={agent?.expertPrompt ? agent?.expertPrompt : ''} ref={userPromptRef} />

            <button className='prompts-button' onClick={() => {
                updateAgent()
            }}>
                Save
            </button>
        </div>
    )
}