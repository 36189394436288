import './UnderConstruction.scss'
import FreeBlobEnv from '../../../Components/Blobs/FreeBlob/FreeBlobEnv'
import React from 'react'

export const UnderConstruction = () => {
    return (
        <div className='underConstruction'>
            <FreeBlobEnv />
            <div className='underConstruction-content'>
                <h1 className='underConstruction-title'>Under construction</h1>
                <h2 className='underConstruction-subtitle'>This page is currently being developed</h2>
                <p className='underConstruction-text'>Contact us, if you need help</p>
                <a href='/contact' className='underConstruction-button'>Contact</a>
            </div>
        </div>
    )
}