import { AgentInterface } from '../Interfaces/agentsInterfaces'

export const  custom_expert: AgentInterface = {
    'id': 'f5e4b3f6-5c5b-4f0e-8c1f-0e1f0c5b7d4b',
    'name': 'Custom Expert',
    'description': 'Custom Expert',
    'shortDescription': 'You can easily create your own expert with our custom creator and start using it right away.',
    'usageInstruction': null,
    'authorId': '7f83422a-c6ac-417d-bce6-879c0a2b1d84',
    'logoUrl': null,
    'logoColorHex': '#ce8b09',
    'isPublic': true,
    'tags': [],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const  tailored_expert: AgentInterface = {
    'id': 'f5e4b3f6-5c5b-4f0e-8c1f-0e1f0c5b7d4b',
    'name': 'Tailored AI expert',
    'description': 'Custom Expert',
    'shortDescription': 'Talk to A{P}PI specialists about custom AI expert for your company.',
    'usageInstruction': null,
    'authorId': '7f83422a-c6ac-417d-bce6-879c0a2b1d84',
    'logoUrl': null,
    'logoColorHex': '#ED1660',
    'isPublic': true,
    'tags': [],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};


export const customer_research_expert: AgentInterface = {
    'id': '2959042c-fa55-4e61-a8b7-00e5c6488e0a',
    'name': 'Customer Research Expert',
    'description': 'I will produce a comprehensive analysis and table of  10 frustrations, 10 desires , 10 dreams, and 10 fears that my audience experiences related to your prompt',
    'shortDescription': 'I will give you 10 examples of frustrations, desires, dreams, and fears of customers towards the thing you are describing.',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#6ac1ec',
    'isPublic': true,
    'tags': [
        'Customer Research',
        'Product Validation',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0

};

export const academic_financial_expert: AgentInterface = {
    'id': '8829b1cf-f38c-475d-9091-032993b10068',
    'name': 'Academic Financial Expert',
    'description': 'Academic Financial Expert',
    'shortDescription': 'Academic Financial Expert',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#AA3768',
    'isPublic': true,
    'tags': [
        'Academic',
        'Financial Management',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const reactseniordeveloper: AgentInterface = {
    'id': 'ab807de3-78f7-4cc2-8b30-1d82190880a0',
    'name': 'React senior developer',
    'description': 'Unlock the full potential of your React applications with our seasoned AI React expert. Our advanced AI technology delves into the intricate layers of React development, effortlessly tackling challenges from performance optimization to state management. With a profound understanding of both fundamental and cutting-edge React features, our AI expert is equipped to enhance your user interfaces, streamline your codebase, and infuse your projects with innovative solutions that captivate users. Embrace a future where complex problems are simplified, and cutting-edge React development is made accessible for you.',
    'shortDescription': 'Revolutionize Your React Projects with AI Expertise',
    'usageInstruction': null,
    'authorId': 'ab9a08d0-a4b2-4b2e-8cd8-694fe87d5356',
    'logoUrl': null,
    'logoColorHex': '#AFFB72',
    'isPublic': true,
    'tags': [
        'Code',
        'React',
        'WebDev',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const value_creationand_management_expert: AgentInterface = {
    'id': '75c73c4c-d03f-4be8-93f5-1ebc1f09ca91',
    'name': 'Value Creation and Management Expert',
    'description': 'Value Creation and Management',
    'shortDescription': 'Value Creation and Management',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#26ED95',
    'isPublic': true,
    'tags': [
        'Value Creation',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const java10xdeveloper: AgentInterface = {
    'id': 'b2deb715-11f4-41a0-a186-21fe19972560',
    'name': 'Java 10x developer',
    'description': 'easy',
    'shortDescription': 'Work smart not hard',
    'usageInstruction': null,
    'authorId': 'fd9e5210-74e8-48c7-88b5-8e2b8212f370',
    'logoUrl': null,
    'logoColorHex': '#21F26B',
    'isPublic': true,
    'tags': [
        'CodingAdventure',
        'JavaMagic',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const psychology_exam_aswerer_expert: AgentInterface = {
    'id': '54aeae69-47f6-4567-8419-2401e5797ed1',
    'name': 'Psychology Exam Aswerer Expert',
    'description': 'Psychology Exam Aswerer Expert',
    'shortDescription': 'Psychology Exam Aswerer Expert',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#838666',
    'isPublic': true,
    'tags': [],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const linked_in_hook_crafting_expert: AgentInterface = {
    'id': '6e4ef4d5-ed6c-4f8c-b32b-31d34c74c914',
    'name': 'LinkedIn Hook Crafting Expert',
    'description': 'Change you LinkedIn headline into a captivating hook.',
    'shortDescription': 'Change you LinkedIn headline into a captivating hook.',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#c53dce',
    'isPublic': true,
    'tags': [
        'LinkedIn',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const talent_optimization_specialist: AgentInterface = {
    'id': '3b224bd0-d3e9-4dca-8e7e-3b7ac99b4e7f',
    'name': 'Talent Optimization Specialist',
    'description': 'The Talent Optimization Specialist excels in aligning talent strategies with organizational goals to enhance workforce productivity and satisfaction. They are data-driven, adaptable, and skilled in crafting holistic solutions for talent management challenges.',
    'shortDescription': 'The Talent Optimization Specialist is an HR expert specializing in aligning organizational goals with talent strategies to maximize workforce productivity.',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#0E3447',
    'isPublic': true,
    'tags': [],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const essay_writer: AgentInterface = {
    'id': 'ad20da6c-6470-40cd-9950-47947ee2a8bb',
    'name': 'Essay Writer',
    'description': 'I will craft for you the perfect essay for any topic',
    'shortDescription': 'I will craft for you the perfect essay for any topic',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#3CFB86',
    'isPublic': true,
    'tags': [
        'essay',
        'writing',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const organizational_culture_expert: AgentInterface = {
    'id': '16761b81-739d-428f-8059-4e90972e40b0',
    'name': 'Organizational Culture Expert',
    'description': 'Organizational Culture Expert',
    'shortDescription': 'Organizational Culture Expert',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#DAD5EE',
    'isPublic': true,
    'tags': [
        'cultural organization',
        'management',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const informationand_knowledgein_management_expert: AgentInterface = {
    'id': 'ac63233e-806b-4cef-bc03-6b12e0cfb374',
    'name': 'Information and Knowledge in Management Expert',
    'description': 'Information and Knowledge in Management Expert',
    'shortDescription': 'Information and Knowledge in Management Expert',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#FF5A5D',
    'isPublic': true,
    'tags': [
        'knowledge management',
        'management',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const senior_java_developer: AgentInterface = {
    'id': '02fc166a-fdfa-45ae-af18-746df968c2ee',
    'name': 'Senior Java Developer',
    'description': 'Unlock the full potential of your Java applications with our seasoned AI Java expert. Our advanced AI technology delves into the intricate layers of Java development, effortlessly tackling challenges from performance optimization to efficient memory management. With a profound understanding of both fundamental and cutting-edge Java features, our AI expert is equipped to enhance your backend systems, streamline your codebase, and infuse your projects with innovative solutions that boost efficiency and scalability. Embrace a future where complex problems are simplified, and cutting-edge Java development is made accessible for you.',
    'shortDescription': 'Enhance Java apps with AI: boost performance, streamline code, and innovate with ease.',
    'usageInstruction': null,
    'authorId': 'ab9a08d0-a4b2-4b2e-8cd8-694fe87d5356',
    'logoUrl': null,
    'logoColorHex': '#0CE9EA',
    'isPublic': true,
    'tags': [
        'Code',
        'Java',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const organizational_life_cycle_expert: AgentInterface = {
    'id': '135ed3d8-577b-4d3f-9aa3-75c98ae2c689',
    'name': 'Organizational Life Cycle Expert',
    'description': 'Organizational Life Cycle Expert',
    'shortDescription': 'Organizational Life Cycle Expert',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#EF50B0',
    'isPublic': true,
    'tags': [
        'Organizational Life Cycle',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const visionin_management_expert: AgentInterface = {
    'id': '410c3df0-5997-4a04-aa5f-9943d34daaf8',
    'name': 'Vision in Management Expert',
    'description': 'Vision in Management Expert, SWOT, SMART analysis',
    'shortDescription': 'Vision in Management Expert, SWOT, SMART analysis',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#3BD9E5',
    'isPublic': true,
    'tags': [
        'smart',
        'swot',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const designdirector: AgentInterface = {
    'id': '911b6698-8325-40db-941e-a3b17cb597ce',
    'name': 'Design director',
    'description': 'I will generate design ideas for various forms of media / art',
    'shortDescription': 'I will generate design ideas for various forms of media / art',
    'usageInstruction': null,
    'authorId': 'ab9a08d0-a4b2-4b2e-8cd8-694fe87d5356',
    'logoUrl': null,
    'logoColorHex': '#56B658',
    'isPublic': true,
    'tags': [
        'Art',
        'Design',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const theoryof_probability_expert: AgentInterface = {
    'id': '088e49e2-fce0-45ee-a5e2-aefd19921282',
    'name': 'Theory of Probability Expert',
    'description': 'Get precise and thorough explanation of any probability problem',
    'shortDescription': 'Get precise and thorough explanation of any probability problem',
    'usageInstruction': null,
    'authorId': 'ab9a08d0-a4b2-4b2e-8cd8-694fe87d5356',
    'logoUrl': null,
    'logoColorHex': '#64CDE5',
    'isPublic': true,
    'tags': [
        'Academic',
        'Math',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const general_chat: AgentInterface = {
    'id': '002efbb4-dd20-4a16-ab50-cfcddf1dee40',
    'name': 'General Chat',
    'description': 'The most general model',
    'shortDescription': '',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#cf4242',
    'isPublic': true,
    'tags': [],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const linkedin_post_curator: AgentInterface = {
    'id': '73469a66-490f-42de-b9b1-dacc33325799',
    'name': 'Linkedin Post Curator',
    'description': 'This expert will help you edit and enhance your Linkedin post',
    'shortDescription': 'This expert will help you edit and enhance your Linkedin post',
    'usageInstruction': null,
    'authorId': 'edd86ec8-902e-4a03-884d-a67b9cf1f26f',
    'logoUrl': null,
    'logoColorHex': '#52A590',
    'isPublic': true,
    'tags': [
        'LinkedIn',
    ],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
};

export const funny_chat: AgentInterface = {
    'id': 'cf32fc62-630b-47a3-9cb4-fcdf8e46c519',
    'name': 'FunnyChat',
    'description': 'Funny, general answer to a question',
    'shortDescription': '',
    'usageInstruction': null,
    'authorId': '7f83422a-c6ac-417d-bce6-879c0a2b1d84',
    'logoUrl': null,
    'logoColorHex': '',
    'isPublic': true,
    'tags': [],
    'suggestedAIModel': null,
    'documentDisplayPermission' : 0
}