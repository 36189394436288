import React, { useState } from 'react'
import './MonthSlider.scss'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

interface MonthSliderInterface {
    date: Date
    setDate: any
}

const MonthSlider = ({ date, setDate }: MonthSliderInterface) => {

    const handlePrevMonth = () => {
        const newDate = new Date(date)
        newDate.setMonth(date.getMonth() - 1)
        setDate(newDate)
    }

    const validMonth = () => {
        const current = new Date();
        return date.getMonth() < current.getMonth()
    }

    const handleNextMonth = () => {
        const newDate = new Date(date)
        if (!validMonth()) return;
        newDate.setMonth(date.getMonth() + 1);
        setDate(newDate)
    }

    return (
        <div className='monthSlider'>
            <IoIosArrowBack onClick={handlePrevMonth} id='button' />
            <h2>
                {date.toLocaleString('default', { month: 'long' })}
                {date.getFullYear() !== new Date().getFullYear() && ` ${date.getFullYear()}`}
            </h2>
            <IoIosArrowForward onClick={handleNextMonth} id='button' />
        </div>
    )
}

export default MonthSlider