import React, { Component, useEffect, useState } from 'react'
import './DevApplication.scss'
import { getExpert, getExpertDetails, getSummarizerDetails } from '../../../Requests/agentRequests'
import { FaRobot } from 'react-icons/fa'
import { IoSettingsSharp, IoStatsChartSharp } from 'react-icons/io5'
import { useSearchParams } from 'react-router-dom'
import { Settings } from './Settings/Settings'
import { Prompts } from './Prompts/Prompts'
import { DevApplicationStats } from './Stats/DevApplicationStats'
import { Documents } from './Documents/Documents'
import * as FaIcons from 'react-icons/fa'
import { IconType } from 'react-icons'
import AgentIcon from '../../../Utils/CustomAgentIcon'
import { AlertSeverity, AlertType } from '../../../Interfaces/alertsInterfaces'

interface DevApplicationInterface {
    location: any;
    navigate: Function;
    sendAlert: Function;
}

const enum Page {
    STATS,
    SETTINGS,
    PROMPTS,
    DOCUMENTS
}

const DevApplication = ({ location, navigate, sendAlert }: DevApplicationInterface) => {
    const [agent, setAgent] = useState<any>()
    const [page, setPage] = useState<Page>(Page.SETTINGS)
    const [searchParams, setSearchParams] = useSearchParams()
    const [type, setType] = useState<string>('')

    useEffect(() => {
        const path = searchParams.get('page')
        // if (path === '' || path === 'stats') {
        //     setPage(Page.STATS)
        // }
        if (path === 'settings') {
            setPage(Page.SETTINGS)
        }
        if (path === 'prompts') {
            setPage(Page.PROMPTS)
        }
        if (path === 'documents') {
            setPage(Page.DOCUMENTS)
        }
    }, [searchParams])

    useEffect(() => {
        const id = location.pathname.split('/')[3]
        getExpertDetails(id).then((r) => {
            setAgent(r)
            setType('expert')
        }).catch((e) => {
            getExpert(id).then((r) => {
                setAgent(r)
                setType('expert')
            }).catch((e) => {
                console.log(e)
            })
        })
    }, [location.pathname])

    return (
        <div className="devApplication">
            <div className="devApplication-sidebar">
                <div className="devApplication-sidebar-title">
                    <AgentIcon className="devApplication-sidebar-title-icon"
                               style={{ color: agent?.logoColorHex }} agent={agent} />
                    <h1>{agent?.name}</h1>
                </div>
                <div className="devApplication-sidebar-menu">
                    {/*<div*/}
                    {/*    className={page === Page.STATS ? 'devApplication-sidebar-menu-item devApplication-sidebar-menu-item-selected' : 'devApplication-sidebar-menu-item'}*/}
                    {/*    onClick={() => navigate(location.pathname + '?page=stats')}>*/}
                    {/*    <IoStatsChartSharp className='devApplication-sidebar-menu-item-img' />*/}
                    {/*    <h1 className='devApplication-sidebar-menu-item-title'>Stats</h1>*/}
                    {/*</div>*/}
                    <div
                        className={page === Page.SETTINGS ? 'devApplication-sidebar-menu-item devApplication-sidebar-menu-item-selected' : 'devApplication-sidebar-menu-item'}
                        onClick={() => navigate(location.pathname + '?page=settings')}>
                        <IoSettingsSharp className="devApplication-sidebar-menu-item-img" />
                        <h1 className="devApplication-sidebar-menu-item-title">Settings</h1>
                    </div>
                    {agent?.systemPrompt !== undefined && <div
                        className={page === Page.PROMPTS ? 'devApplication-sidebar-menu-item devApplication-sidebar-menu-item-selected' : 'devApplication-sidebar-menu-item'}
                        onClick={() => navigate(location.pathname + '?page=prompts')}>
                        <IoStatsChartSharp className='devApplication-sidebar-menu-item-img' />
                        <h1 className='devApplication-sidebar-menu-item-title'>Prompts</h1>
                    </div>}
                    <div
                        className={page === Page.DOCUMENTS ? 'devApplication-sidebar-menu-item devApplication-sidebar-menu-item-selected' : 'devApplication-sidebar-menu-item'}
                        onClick={() => navigate(location.pathname + '?page=documents')}>
                        <IoStatsChartSharp className="devApplication-sidebar-menu-item-img" />
                        <h1 className="devApplication-sidebar-menu-item-title">Documents</h1>
                    </div>
                </div>
            </div>
            <div className="devApplication-content">
                {page === Page.STATS && <DevApplicationStats
                    agent={agent}
                />}
                {page === Page.SETTINGS && <Settings
                    agent={agent}
                    setAgent={setAgent}
                    type={type}
                    sendAlert={sendAlert}
                />}
                {page === Page.PROMPTS && <Prompts
                    agent={agent}
                    setAgent={setAgent}
                    type={type}
                />}
                {page === Page.DOCUMENTS && <Documents
                    agent={agent}
                    sendAlert={sendAlert}
                    perms={agent?.systemPrompt !== undefined}
                />}
            </div>
        </div>
    )
}

export default DevApplication
