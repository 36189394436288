import './Accout.scss'
import React, { useState } from 'react'
import { registerAsDeveloper } from '../../../../Requests/userRequests'
import { useGlobalState } from '../../../../GlobalStateContext'
import { AiOutlineCheckSquare } from 'react-icons/ai'
import { AlertSeverity, AlertType } from '../../../../Interfaces/alertsInterfaces'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import { useNavigate } from 'react-router-dom'

interface AccountInterface {
    sendAlert: Function
}

export const Account = ({ sendAlert }: AccountInterface) => {

    const navigate = useNavigate()
    const [globalState, globalActions] = useGlobalState()

    const nameRef = React.useRef(null)
    const surnameRef = React.useRef(null)
    const emailRef = React.useRef(null)

    const registerAsDeveloperFunction = async () => {
        await registerAsDeveloper().then(r => {
            sendAlert(AlertType.SUCCESS, 'You are now a developer', AlertSeverity.SUCCESS)
            globalActions.setDeveloper(true)
            globalActions.setUser(r)
        })
    }

    const updateUser = () => {

    }

    const deleteUser = () => {

    }
    return (
        <div className='dashboard-content-account'>
            <h1 className='dashboard-content-account-title'>Account settings</h1>
            <div className='dashboard-content-account-info'>
                <div className='dashboard-content-account-info-item' id={'info-name'}>
                    <h1>Name</h1>
                    <input placeholder={globalState.user?.name} ref={nameRef} />
                </div>
                <div className='dashboard-content-account-info-item' id={'info-surname'}>
                    <h1>Surname</h1>
                    <input placeholder={globalState.user?.surname} ref={surnameRef} />
                </div>
                <div className='dashboard-content-account-info-item' id={'info-email'}>
                    <h1>Email</h1>
                    <input placeholder={globalState.user?.email} ref={emailRef} />
                </div>
                <div className='dashboard-content-account-info-edit'>
                    <button onClick={() => {
                        updateUser()
                    }}>Save
                    </button>
                </div>
            </div>
            <span />
            <div className='dashboard-content-account-password'>
                <h1>Change password</h1>
                <form className='dashboard-content-account-password-change'>
                    <input placeholder='Old Password' />
                    <input placeholder='Old Password Repeat' />
                    <button className='dashboard-content-account-password-change-button'>Proceed</button>
                </form>
            </div>
            {/*<span />*/}
            {/*<div className='dashboard-content-account-keys'>*/}
            {/*    <h1>API keys</h1>*/}
            {/*    <div className='dashboard-content-account-keys-key'>*/}
            {/*        <h2>Open AI</h2>*/}
            {/*        <div className='dashboard-content-account-keys-key-wrapper'>*/}
            {/*            <input></input>*/}
            {/*            <AiOutlineCheckSquare className='dashboard-content-account-keys-key-icon' />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <span />
            <div className='dashboard-content-account-developer'>
                {!globalState.developer && <div className='dashboard-content-account-developer-item'>
                    <h1>Developer account</h1>
                    <button onClick={() => registerAsDeveloperFunction()}>Activate</button>
                </div>}
            </div>
            {!globalState.developer && <span />}
            <div className='dashboard-content-account-default'>
                {globalState.user?.defaultExperts?.length !== 0 && <>
                    <h1>Default experts:</h1>
                    {globalState.user?.defaultExperts?.map((expert: AgentInterface, index: number) => {
                        return (
                            <h2 key={index}> {expert.name} </h2>
                        )
                    })}
                </>}
                {globalState.user?.defaultSummarizer && <>
                    <h1>Default summarizer:</h1>
                    <h2> {globalState.user?.defaultSummarizer.name} </h2>
                </>
                }
            </div>
            <span />
            <h1 className='dashboard-content-account-delete-title'>Danger zone</h1>
            <span />
            <div className='dashboard-content-account-delete'>
                <h1>Delete account</h1>
                <button onClick={() => deleteUser()}>Delete
                </button>
            </div>
        </div>
    )
}