import React, { useEffect, useState } from 'react'
import './ModelSettings.scss'
import { AgentType } from '../../Utils/enums'
import { AiModelInterface, ModelConfigurationInterface } from '../../Interfaces/modelInterfaces'
import Dropdown from '../Dropdown/Dropdown'
import { getModels } from '../../Requests/aiModelRequests'

interface ModelSettingsInterface {
    type: string
    modelConfiguration: ModelConfigurationInterface
    setModelConfiguration: Function

}

interface ModelSettingsInterface {
    type: string;
    modelConfiguration: ModelConfigurationInterface;
    setModelConfiguration: Function;
}

export function ModelSettings({ type, modelConfiguration, setModelConfiguration }: ModelSettingsInterface) {

    const defaultAiModel: AiModelInterface = {
        categoryName: '',
        costOfOneInputToken: 0,
        costOfOneOutputToken: 0,
        creator: '',
        description: '',
        displayName: '',
        id: '',
        maxTokens: 0,
        name: '',
        readsImages: false,
    }

    const [selectedModel, setSelectedModel] = useState<AiModelInterface>(defaultAiModel)
    const [models, setModels] = useState<AiModelInterface[]>([])
    const handleConfigurationChange = (key: keyof Omit<ModelConfigurationInterface, 'aiModelId'>, value: number) => {
        setModelConfiguration({
            ...modelConfiguration,
            [key]: value,
        })
    }

    useEffect(() => {
        getModels().then(r => {
            setModels(r)
            setSelectedModel(r[0])
        })
    }, [])
    useEffect(() => {
        setModelConfiguration({
            ...modelConfiguration,
            aiModelId: selectedModel ? selectedModel.id : '',
        })
    }, [selectedModel])


    return (
        <div className='modelSettings' id={type}>
            <h1>Model configuration</h1>
            <div className='modelSettings-label'>AI model</div>
            <Dropdown<AiModelInterface> selected={selectedModel} setSelected={setSelectedModel} options={models}
                                        displayAttribute={'name'} />
            <ModelSettingsElement label='Temperature' min={0} max={1} step={0.01}
                                  defaultValue={modelConfiguration.temperature}
                                  onChange={value => handleConfigurationChange('temperature', value)} />
            <ModelSettingsElement label='Maximum length' min={1} max={4096} step={1}
                                  defaultValue={modelConfiguration.maxLength}
                                  onChange={value => handleConfigurationChange('maxLength', value)} />
            <ModelSettingsElement label='Top P' min={0} max={1} step={0.01} defaultValue={modelConfiguration.topP}
                                  onChange={value => handleConfigurationChange('topP', value)} />
            <ModelSettingsElement label='Frequency penalty' min={0} max={2} step={0.01}
                                  defaultValue={modelConfiguration.frequencyPenalty}
                                  onChange={value => handleConfigurationChange('frequencyPenalty', value)} />
            <ModelSettingsElement label='Presence penalty' min={0} max={2} step={0.01}
                                  defaultValue={modelConfiguration.presencePenalty}
                                  onChange={value => handleConfigurationChange('presencePenalty', value)} />
        </div>
    )
}

interface ModelSettingsElementInterface {
    label: string;
    min: number;
    max: number;
    step: number;
    defaultValue: number;
    onChange: (value: number) => void;
}

const ModelSettingsElement = ({
                                  label,
                                  min,
                                  max,
                                  step,
                                  defaultValue,
                                  onChange,
                              }: ModelSettingsElementInterface) => {

    const [value, setValue] = React.useState<number>(defaultValue)

    const handleChange = (newValue: number) => {
        const clampedValue = Math.max(min, Math.min(max, newValue))
        setValue(clampedValue)
        onChange(clampedValue)
    }

    return (
        <div className='modelSettings-item'>
            <div className='modelSettings-item-top'>
                <div className='modelSettings-item-label'>{label}</div>
                <input
                    className='modelSettings-item-input-number'
                    type='number'
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={(e) => handleChange(parseFloat(e.target.value))}
                />
            </div>
            <input
                className='modelSettings-item-input-range'
                type='range'
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={(e) => handleChange(parseFloat(e.target.value))}
            />
        </div>
    )
}
