import './StaticTextWithImage.scss'
import { AiOutlineCheck } from 'react-icons/ai'

interface StaticTextWithImageInterface {
    title: string
    text: string
    image: string
    side: boolean
    bullets?: {
        title: string
        text: string
    }[]

}

export const StaticTextWithImage = (props: StaticTextWithImageInterface) => {
    return (
        <div className='staticTextWithImage'>
            {props.side && <div className='staticTextWithImage-image'>
                <img src={props.image} alt={props.title} />
            </div>}
            <div className='staticTextWithImage-text'>
                <h1 className='staticTextWithImage-text-title'>{props.title}</h1>
                <p className='staticTextWithImage-text-text'>{props.text}</p>
                <div className='staticTextWithImage-text-bullets'>
                    {props.bullets && props.bullets.map((bullet, index) => (
                        <div className='staticTextWithImage-text-bullet'>
                            <AiOutlineCheck className='staticTextWithImage-text-bullet-icon' />
                            <p className='staticTextWithImage-text-bullet-text'><b>{bullet.title}</b> {bullet.text}</p>
                        </div>
                    ))}
                </div>

            </div>
            {!props.side && <div className='staticTextWithImage-image'>
                <img src={props.image} alt={props.title} />
            </div>}

        </div>
    )
}