import { AiOutlineUser } from 'react-icons/ai'
import { useState, useEffect } from 'react'
import AgentCard from '../../../../Components/AgentCard/AgentCard'
import './ProfileComponent.scss'
import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import { getExpert } from '../../../../Requests/agentRequests'
import { removeMemberFromOrganization, setOrganizationMemberRole } from '../../../../Requests/organizationsRequests'
import Dropdown from '../../../../Components/Dropdown/Dropdown'
import { OrganizationInterface } from '../../../../Interfaces/organizationInterfaces'
import { ConfirmModal } from '../../../../Components/Modal/Modal'
import WeeklyTeamUsage from '../../Charts/WeeklyTeamUsage'

interface ProfileComponentProps {
    employee: any,
    organization: OrganizationInterface,
    userRole: string,
}

enum Content {
    Agents,
    Usage,   //TODO
    Edit
}

export const ProfileComponent = ({ employee, organization, userRole }: ProfileComponentProps) => {
    const roleOptions = userRole === "Admin" ? [
        { id: 1, name: 'Member' },
        { id: 2, name: 'Manager' },
        { id: 3, name: 'Admin' },
    ]
    :
    [
        { id: 1, name: 'Member' },
        { id: 2, name: 'Manager' },
    ]

    const [content, setContent] = useState<Content>(Content.Agents)
    const [experts, setExperts] = useState<AgentInterface[]>([])
    const [role, setRole] = useState<any>(roleOptions[roleOptions.findIndex(option => option.name === employee.role)])
    const [newRole, setNewRole] = useState<any>(role)
    const [changeRole, setChangeRole] = useState<boolean>(false)
    const [deleteUser, setDeleteUser] = useState<boolean>(false)

    useEffect(() => {
        setRole(roleOptions[roleOptions.findIndex(option => option.name === employee.role)])
    }, [employee])

    useEffect(() => {
        setExperts([])
        organization.assignedExpertsIds.forEach((expertId) => {
            getExpert(expertId).then((res) => {
                setExperts((prevExperts) => {
                    if (prevExperts.find((e) => e.id === res.id)) return prevExperts
                    return [...prevExperts, res]
                })
            })
        })
    }, [organization])

    const removeFromOrg = () => {
        removeMemberFromOrganization(organization.id, employee.id).then((r) => {
            setDeleteUser(false)
            window.location.reload()
        })
    }

    const changeOption = (nRole: any) => {
        setNewRole(nRole)
        setChangeRole(true)
    }

    const confirmChange = () => {
        if (role && employee && organization) {
            setOrganizationMemberRole(organization.id, employee.id, newRole.name).then(r => {
                window.location.reload()
            })
        }
        setRole(newRole)
        setChangeRole(false)
    }

    const discardChange = () => {
        setChangeRole(false)
        setNewRole(role)
    }

    return (
        <div className='profile'>
            {changeRole && <ConfirmModal title='Confirm changes'
                                         description={'Are you sure you want to change ' + employee.name + ' ' + employee.surname + '\'s role to ' + newRole.name + ' ?'}
                                         close={discardChange} proceed={confirmChange} />}
            {deleteUser && <ConfirmModal title='Confirm deletion'
                                         description={'Are you sure you want to delete ' + employee.name + ' ' + employee.surname + ' from this organization?'}
                                         close={() => {
                                             setDeleteUser(false)
                                         }} proceed={() => {
                removeFromOrg()
            }} />}
            <AiOutlineUser className='profile-icon' />
            <h1>{employee.name + ' ' + employee.surname}</h1>
            <h2 className='profile-role'>{employee.role}</h2>
            <p className='profile-description'>{employee.email}</p>

            <div className='profile-nav'>
                <div
                    className={content === Content.Agents ? 'profile-nav-buttonContainer clicked' : 'profile-nav-buttonContainer'}>
                    <button onClick={() => {
                        setContent(Content.Agents)
                    }}>Experts
                    </button>
                </div>
                <div
                    className={content === Content.Usage ? 'profile-nav-buttonContainer clicked' : 'profile-nav-buttonContainer'}>
                    <button onClick={() => {
                        setContent(Content.Usage)
                    }}>Usage
                    </button>
                </div>
                {userRole !== 'Member' && !(userRole == 'Manager' && employee.role == 'Admin') && <div
                    className={content === Content.Edit ? 'profile-nav-buttonContainer clicked' : 'profile-nav-buttonContainer'}>
                    <button onClick={() => {
                        setContent(Content.Edit)
                    }}>Edit
                    </button>
                </div>}
            </div>

            {content === Content.Agents && (
                <div className='profile-agents'>
                    {(() => {
                        const filteredExperts = experts.filter((expert) => expert.authorId === employee.id)
                        if (filteredExperts.length === 0) {
                            return <h1 className='comingSoon'>Nothing here yet</h1>
                        } else {
                            return filteredExperts.map((expert, index) => (
                                <AgentCard
                                    agent={expert}
                                    key={index}
                                    small={true}
                                    description={false}
                                    colored={false}
                                    nav={'dev'}
                                />
                            ))
                        }
                    })()}
                </div>

            )}

            {content === Content.Usage &&
                <div className='profile-stats'>
                    <WeeklyTeamUsage organization={organization} employeeId={employee.id}/>
                </div>
            }

            {content === Content.Edit &&
                <div className='profile-edit'>
                    <div className='profile-edit-item profile-edit-item-dropdown'>
                        <p>Change role</p>
                        <Dropdown selected={role} setSelected={changeOption} options={roleOptions}
                                  displayAttribute='name' />
                    </div>
                    <div className='profile-edit-item'>
                        <p>Remove from organization</p>
                        <button onClick={() => {
                            setDeleteUser(true)
                        }}>Remove
                        </button>
                    </div>
                </div>
            }

        </div>
    )
}