import React, { useEffect, useState } from 'react'
import './Navbar.scss'
import './NavbarMobile.scss'
import { useLocation } from 'react-router-dom'
import useRedirect from '../../Utils/redirect'
import { AiFillTool, AiOutlineArrowDown, AiOutlineTool, AiOutlineUser } from 'react-icons/ai'
import { BiLogOut } from 'react-icons/bi'
import { getMyUserAccount } from '../../Requests/userRequests'
import Logout from '../../Pages/Login/Logout'
import { useWindowDimensions } from '../../Utils/hooks'
import { RxDashboard, RxHamburgerMenu } from 'react-icons/rx'
import { useGlobalState } from '../../GlobalStateContext'
import { MdBusinessCenter } from 'react-icons/md'
import { FaBook, FaUser, FaVial } from 'react-icons/fa'
import { getMyOrganizations } from '../../Requests/organizationsRequests'

enum NavbarPage {
    NONE,
    SOLUTIONS,
    DEVELOPERS,
}

const Navbar = ({ loggedIn, subdomain }: {
    loggedIn: boolean,
    subdomain: string,
}) => {
    const location = useLocation()
    const redirect = useRedirect()
    const dims = useWindowDimensions()
    const mobile = dims.width < 991
    const [scrollPosition, setScrollPosition] = useState(0)
    const [loginDropdown, setLoginDropdown] = useState<boolean>(false)
    const [userName, setUserName] = useState<any>(null)
    const [modal, setModal] = useState<boolean>(false)
    const [navbarPage, setNavbarPage] = useState<NavbarPage>(NavbarPage.NONE)
    const [hasOrganization, setHasOrganization] = useState<boolean>(false)

    const [globalState, globalActions] = useGlobalState()

    const handleScroll = () => {
        const position = window.scrollY
        setScrollPosition(position)

    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })
        if (loggedIn) getMyUserAccount().then((res) => {
            setUserName(res.name + ' ' + res.surname)
        })
        if (loggedIn) getMyOrganizations().then((res) => {
            if (res.length > 0) setHasOrganization(true)
            else setHasOrganization(false)
        })
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }

    }, [loggedIn])


    const navbarClassName = () => {

        if (scrollPosition !== 0 && subdomain !== 'chat' && location.pathname !== '/dashboard') {
            if (navbarPage === NavbarPage.NONE) {
                return 'navbar navbarOther'
            } else {
                return 'navbar navbar-open'
            }
        } else if (scrollPosition !== 0 && subdomain === 'chat') {
            return 'navbar navbarInvisibleScroll'
        } else if (location.pathname === '/' && subdomain !== 'chat') {
            return 'navbar navbarHome'
        } else if (subdomain === 'chat' || (location.pathname === '/dashboard' && mobile)) {
            return 'navbar navbarInvisible'
        } else if (scrollPosition === 0) {
            return 'navbar navbarNoBG'
        } else {
            return 'navbar navbarDefault'
        }
    }

    const handleModalClick = () => {
        if (modal) {
            document.body.style.overflow = 'auto'
        } else {
            document.body.style.overflow = 'hidden'
        }
        setModal(!modal)
    }
    if (!mobile) {
        if (subdomain !== 'chat') {
            return (
                <div className={navbarClassName()}>
                    <div className='navbarMain'>
                        <a className='logos navbar-link' href='/'>
                            <img className='appiIcon' src={require('../../Images/Logo/APPI_icon.png')} alt='Icon' />
                            {(scrollPosition === 0 && location.pathname === '/') || (location.pathname === '/chat') ? (
                                <img className='appiText' src={require('../../Images/Logo/APPI_alone.png')}
                                     alt='Icon' />
                            ) : null}
                        </a>
                        <div className='midSection'>
                            {(loggedIn && subdomain !== 'chat') &&
                                <a href={process.env.REACT_APP_DEV === 'true' ? '/dev-chat' : 'http://chat.' + process.env.REACT_APP_APPI_DOMAIN}
                                   className='navbar-link'>Chat</a>}
                            {(loggedIn && subdomain !== 'chat') && <div className='line' />}
                            <a href='/agents' className='navbar-link'>Agents</a>
                            <div className='line' />
                            {!loggedIn && <p
                                onMouseEnter={() => setNavbarPage(NavbarPage.SOLUTIONS)}
                                onMouseLeave={() => setNavbarPage(NavbarPage.NONE)}
                                className='navbar-link'
                            >
                                Solutions
                                {navbarPage === NavbarPage.SOLUTIONS &&
                                    <div className='navbar-midSection-modal-wrapper'>
                                        <div className='navbar-midSection-modal'>
                                            <a href='/solutions/businesses' className='navbar-midSection-modal-item'>
                                                <MdBusinessCenter className='navbar-midSection-modal-item-icon' />
                                                <div className='navbar-midSection-modal-item-text'>
                                                    <h1>Businesses</h1>
                                                    <p>Learn how we can improve your business with newest AI
                                                        technologies</p>
                                                </div>

                                            </a>
                                            <a href='/solutions/individual' className='navbar-midSection-modal-item'>
                                                <FaUser className='navbar-midSection-modal-item-icon' />
                                                <div className='navbar-midSection-modal-item-text'>
                                                    <h1>Individual users</h1>
                                                    <p>Utilize AI in everyday tasks</p>
                                                </div>
                                            </a>

                                            <a href='/solutions/guide' className='navbar-midSection-modal-item'>
                                                <FaBook className='navbar-midSection-modal-item-icon' />
                                                <div className='navbar-midSection-modal-item-text'>
                                                    <h1>Guide</h1>
                                                    <p>Learn how to use our platform </p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                }
                            </p>}
                            {!loggedIn && <div className='line' />}
                            {!loggedIn && <div
                                onMouseEnter={() => setNavbarPage(NavbarPage.DEVELOPERS)}
                                onMouseLeave={() => setNavbarPage(NavbarPage.NONE)}
                                className='navbar-link'
                            >
                                Developers
                                {navbarPage === NavbarPage.DEVELOPERS &&
                                    <div className='navbar-midSection-modal-wrapper'>
                                        <div className='navbar-midSection-modal'>
                                            <a href='/developer/custom-agents' className='navbar-midSection-modal-item'>
                                                <AiFillTool className='navbar-midSection-modal-item-icon' />
                                                <div className='navbar-midSection-modal-item-text'>
                                                    <h1>Custom Agents</h1>
                                                    <p>Lorem ipsum ad df sdf asdfs fdasa fsdafasfasd f</p>
                                                </div>
                                            </a>
                                            <a href='/labs' className='navbar-midSection-modal-item'>
                                                <FaVial className='navbar-midSection-modal-item-icon' />
                                                <div className='navbar-midSection-modal-item-text'>
                                                    <h1>Labs</h1>
                                                    <p>Join our innovative team</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>}
                            {!loggedIn && <div className='line' />}
                            {!loggedIn && <a href='/pricing' className='navbar-link'>Pricing</a>}
                            {!loggedIn && <div className='line' />}
                            {hasOrganization && <a href='/organization' className='navbar-link'>Organization</a>}
                            {hasOrganization && <div className='line' />}
                            {loggedIn && <a href='/dashboard' className='navbar-link'>Dashboard</a>}
                            {!loggedIn && <div className='line' />}
                            {!loggedIn && <a href='/login' className='login-button'><span>Login</span></a>}
                            {loggedIn && <div className='line' />}
                            {loggedIn &&
                                <div className='navbar-user-wrapper' onClick={() => setLoginDropdown(!loginDropdown)}>
                                    <AiOutlineUser className='navbar-user' />
                                    {loginDropdown && <div className='navbar-user-dropdown' id='login'>
                                        <AiOutlineUser className='navbar-user-dropdown-photo' />
                                        <h1 className='navbar-user-dropdown-name'>{userName}</h1>
                                        <a className='navbar-user-dropdown-item navbar-link'
                                           href='/dashboard?page=account'>
                                            <RxDashboard className='navbar-user-dropdown-item-icon' />
                                            <p className='navbar-user-dropdown-item-text navbar-link'>Account
                                                settings</p>
                                        </a>
                                        <div className='navbar-user-dropdown-item navbar-link'
                                             onClick={() => Logout(redirect)}>
                                            <BiLogOut className='navbar-user-dropdown-item-icon' />
                                            <p className='navbar-user-dropdown-item-text navbar-link'>Logout</p>
                                        </div>
                                    </div>}
                                </div>
                            }
                        </div>
                    </div>
                </div>

            )
        } else
            return (
                <div className={navbarClassName()}>
                    <div className='navbarMain'>
                        <p className='logos navbar-link' onClick={() => redirect('', '')}>
                            <img className='appiIcon' src={require('../../Images/Logo/APPI_icon.png')} alt='Icon' />
                            {(scrollPosition === 0 && location.pathname === '/') || (location.pathname === '/chat') ? (
                                <img className='appiText' src={require('../../Images/Logo/APPI_alone.png')}
                                     alt='Icon' />
                            ) : null}
                        </p>
                        <div className='midSection'>
                            {(loggedIn && subdomain !== 'chat') &&
                                <p className='navbar-link' onClick={() => redirect('chat', '')}>Chat</p>}
                            {(loggedIn && subdomain !== 'chat') && <div className='line' />}
                            <p className='navbar-link' onClick={() => redirect('', 'agents')}>Agents</p>
                            <div className='line' />
                            {!loggedIn &&
                                <p className='navbar-link' onClick={() => redirect('', 'pricing')}>Pricing</p>}
                            {!loggedIn && <div className='line' />}
                            {loggedIn &&
                                <p className='navbar-link' onClick={() => redirect('', 'dashboard')}>Dashboard</p>}
                            {!loggedIn &&
                                <p className='navbar-link login-button' onClick={() => redirect('', 'login')}>Login</p>}
                            {loggedIn && <div className='line' />}
                            {loggedIn &&
                                <div className='navbar-user-wrapper' onClick={() => setLoginDropdown(!loginDropdown)}>
                                    <AiOutlineUser className='navbar-user' />
                                    {loginDropdown && <div className='navbar-user-dropdown' id='login'>
                                        <AiOutlineUser className='navbar-user-dropdown-photo' />
                                        <h1 className='navbar-user-dropdown-name'>{userName}</h1>
                                        <p className='navbar-user-dropdown-item navbar-link'
                                           onClick={() => redirect('', 'dashboard?page=account')}>
                                            <RxDashboard className='navbar-user-dropdown-item-icon' />
                                            <p className='navbar-user-dropdown-item-text navbar-link'>Account
                                                settings</p>
                                        </p>
                                        <div className='navbar-user-dropdown-item navbar-link'
                                             onClick={() => Logout(redirect)}>
                                            <BiLogOut className='navbar-user-dropdown-item-icon' />
                                            <p className='navbar-user-dropdown-item-text navbar-link'>Logout</p>
                                        </div>
                                    </div>}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            )
    } else {
        return (
            <div className={navbarClassName()}>
                {modal && <div className='navbar-modal'>
                    <div className='midSection'>
                        <p className='navbar-link' onClick={() => redirect('', '')}>Home</p>
                        <div className='line' />
                        {loggedIn && <p className='navbar-link' onClick={() => redirect('chat', '')}>Chat</p>}
                        {loggedIn && <div className='line' />}
                        <p onClick={() => redirect('', 'agents')}>Agents</p>
                        <div className='line' />
                        {loggedIn && <p className='navbar-link' onClick={() => redirect('', 'dashboard')}>Dashboard</p>}
                        {loggedIn && <div className='line' />}
                        {!loggedIn && <p className='navbar-link' onClick={() => redirect('', 'login')}>Login</p>}
                        {loggedIn &&
                            <div className='navbar-modal-user'>
                                <div className='navbar-modal-user-wrapper'>
                                    <AiOutlineUser className='navbar-modal-user-photo' />
                                    <h1 className='navbar-modal-user-name'>{userName}</h1>
                                </div>
                                <div className='navbar-modal-user-item-wrapper'>
                                    <RxDashboard className='navbar-modal-user-item'
                                                 onClick={() => redirect('', '/dashboard?page=account')} />
                                    <BiLogOut className='navbar-modal-user-item'
                                              onClick={() => Logout(redirect)} />
                                </div>
                            </div>
                        }
                    </div>

                </div>}
                <div className='navbarMain'>
                    <p className='logos' onClick={() => redirect('', '')}>
                        <img className='appiIcon' src={require('../../Images/Logo/APPI_icon.png')} alt='Icon' />
                        {(scrollPosition === 0 && location.pathname === '/') || subdomain === 'chat' ? (
                            <img className='appiText' src={require('../../Images/Logo/APPI_alone.png')} alt='Icon' />
                        ) : null}
                    </p>

                    <RxHamburgerMenu className='navbarMain-burger' onClick={handleModalClick} />
                </div>
            </div>

        )
    }
}

export default Navbar
