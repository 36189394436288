import { AgentInterface } from '../../../../Interfaces/agentsInterfaces'
import './Documents.scss'
import { useEffect, useState } from 'react'
import { AiOutlineCalendar, AiOutlineLink, AiOutlinePlus } from 'react-icons/ai'
import { DocumentUploadModal } from '../../../../Components/DocumentUploadModal/DocumentUploadModal'
import { deleteExpertDocument, getDocuments } from '../../../../Requests/documentsRequests'
import { DocumentInterface } from '../../../../Interfaces/documentInterfaces'
import { ConfirmModal } from '../../../../Components/Modal/Modal'

interface DocumentsInterface {
    agent: AgentInterface;
    sendAlert: Function;
    perms: boolean;
}

export const Documents = ({ agent, sendAlert, perms }: DocumentsInterface) => {

    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [documents, setDocuments] = useState<DocumentInterface[]>([])
    const [selectedDocument, setSelectedDocument] = useState<DocumentInterface | null>(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
    useEffect(() => {
        if (agent?.id)
            getDocuments(agent.id).then(r => {
                setDocuments(r)
            })
    }, [agent])
    console.log(documents)

    return (
        <div className='documents'>
            {modalOpen &&
                <DocumentUploadModal id={agent?.id} closeModal={() => setModalOpen(false)} sendAlert={sendAlert} />}
            {/*<div className='documents-header'>*/}
            {/*    <h1 className='documents-title'> Uploaded documents</h1>*/}
            {/*    {perms && <button className='documents-upload' onClick={() => setModalOpen(true)}><AiOutlinePlus /></button>}*/}
            {/*</div>*/}
            <div className='documents-content'>
                <div className='documents-list'>
                    {documents.map((document: any) => <DocumentItem document={document}
                                                                    select={() => setSelectedDocument(document)}
                                                                    selected={selectedDocument === document}
                    />)}
                </div>
                {selectedDocument && <div className='documents-viewer'>
                    <h1 className='documents-viewer-title'>Document viewer</h1>
                    <h1 className='documents-viewer-name'>{selectedDocument.documentName}</h1>
                    <div className='documents-viewer-item'>
                        <AiOutlinePlus className='documents-viewer-item-icon' />
                        <h2 className='documents-viewer-item-title'>Document name</h2>
                        <h2 className='documents-viewer-item-value'>{selectedDocument.documentName}</h2>
                    </div>
                    <div className='documents-viewer-item'>
                        <AiOutlineCalendar className='documents-viewer-item-icon' />
                        <h2 className='documents-viewer-item-title'>Created at</h2>
                        <h2 className='documents-viewer-item-value'>{new Date(selectedDocument.createdAt).toLocaleString()}</h2>
                    </div>
                    <div className='documents-viewer-item'>
                        <AiOutlineLink className='documents-viewer-item-icon' />
                        <h2 className='documents-viewer-item-title'>Url</h2>
                        <h2 className='documents-viewer-item-value'>{selectedDocument.documentUrl}</h2>
                    </div>
                    {/*{perms && <button className='documents-viewer-delete'*/}
                    {/*        onClick={() => setDeleteModalOpen(true)}>Delete*/}
                    {/*</button>}*/}
                    {deleteModalOpen && <div>
                        <ConfirmModal title={'Are you sure you want to delete the document?'} description={''}
                                      close={() => setDeleteModalOpen(false)}
                                      proceed={() => deleteExpertDocument(agent?.id, selectedDocument?.id).then(() => setDeleteModalOpen(false))} />
                    </div>}

                </div>}
            </div>

        </div>
    )
}

const DocumentItem = ({ document, select, selected }: {
    document: DocumentInterface,
    select: Function,
    selected: boolean
}) => {
    return (
        <div className={selected ? 'document document-selected' : 'document'} onClick={() => select()}>
            <h1 className='document-title'>{document.documentName}</h1>
            <h2 className='document-date'>{new Date(document.createdAt).toLocaleString()}</h2>
        </div>
    )
}