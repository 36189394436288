import { apiDelete, apiGet, apiPost, apiPut } from '../Utils/RequestsUtil'
import { ErrorType } from '../Utils/enums'
import Cookies from 'js-cookie'

export const getMyOrganizations = async () => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Organizations/GetAllOrganizationsUserIsMemberOf',
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const getOrganizationById = async (id: string) => {
    const jwt = Cookies.get('jwt')
    return await apiGet(
        'Organizations/Details/' + id,
        null,
        false,
        // @ts-ignore
        jwt,
    )

}

export const assignExpertToOrganization = async (organizationId: string, expertId: string) => {
    const jwt = Cookies.get('jwt')
    return await apiPut(
        'Expert/' + expertId + '/AssignToOrganization/' + organizationId,
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const unassignExpertFromOrganization = async (organizationId: string, expertId: string) => {
    const jwt = Cookies.get('jwt')
    return await apiPut(
        'Expert/' + expertId + '/UnassignFromOrganization/' + organizationId,
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const addMemberToOrganization = async (organizationId: string, userEmail: string) => {
    const jwt = Cookies.get('jwt');
    return await apiPost(
        'Organizations/' + organizationId + '/Members',
        userEmail,
        false,
        //@ts-ignore
        jwt,
    )
}

export const removeMemberFromOrganization = async (organizationId: string, memberId: string) => {
    const jwt = Cookies.get('jwt');
    return await apiDelete(
        'Organizations/' + organizationId + '/Members/' + memberId,
        null,
        false,
        // @ts-ignore
        jwt,
    )
}

export const setOrganizationMemberRole = async (organizationId: string, memberId: string, newRole: string) => {
    const jwt = Cookies.get('jwt');
    return await apiPut(
        'Organizations/' + organizationId + '/Members/ChangeRole/' + memberId,
        newRole,
        false,
        // @ts-ignore
        jwt,
    )
}

export const getGeneralHourlyStats = async (organizationId: string) => {
    const jwt = Cookies.get('jwt');
    return await apiGet(
        'Organizations/Stats/Hourly/' + organizationId,
        null,
        false,
        //@ts-ignore
        jwt,
    )
}

export const getGeneralDailyStats = async (organizationId: string) => {
    const jwt = Cookies.get('jwt');
    return await apiGet(
        'Organizations/Stats/Daily/' + organizationId,
        null,
        false,
        //@ts-ignore
        jwt,
    )
}

export const getEmployeeDailyStats = async (organizationId: string, memberId: string) => {
    const jwt = Cookies.get('jwt');
    return await apiGet(
        'Organizations/Stats/Daily/' + organizationId + '/member/' + memberId,
        null,
        false,
        //@ts-ignore
        jwt,
    )
}

export const getEmployeeHourlyStats = async (organizationId: string, memberId: string) => {
    const jwt = Cookies.get('jwt');
    return await apiGet(
        'Organizations/Stats/Hourly/' + organizationId + '/member/' + memberId,
        null,
        false,
        //@ts-ignore
        jwt,
    )
}