import './MultiExpert.scss'
import React, { useEffect, useState } from 'react'
import { linked_in_hook_crafting_expert, senior_java_developer } from '../../../../../Components/staticExperts'
import { FunToolItem } from '../../FunToolItem/FunToolItem'
import AgentCard from '../../../../../Components/AgentCard/AgentCard'
import { useNavigate } from 'react-router-dom'
import { AiOutlineLoading, AiOutlineUser } from 'react-icons/ai'
import { FaRobot } from 'react-icons/fa'
import { Parser } from '../../../../Chat/Message/Parser'

enum Phase {
    Introduction,
    SelectExpert,
    GeneratingResponseLoading,
    GeneratingResponse,
    ResponseGenerated,
}

const expertList = [
    {
        'expert': senior_java_developer,
        'prompts': [
            'In Java, you can find the length of a string by using the length() method of the String class. Here is an example:\n\n```java\npublic class Main {\n    public static void main(String[] args) {\n        String myString = "Hello, world!";\n        int length = myString.length();\n        System.out.println("The length of the string is: " + length);\n    }\n}\n```',
        ],
    },
    {
        'expert': linked_in_hook_crafting_expert,
        'prompts': [
            'You can reverse a string in Java by using the StringBuilder class. Here is an example of how to do it:',
            'In Java, you can find the length of a string by using the length() method of the String class. Here is an example:\n\n```java\npublic class Main {\n    public static void main(String[] args) {\n        String myString = "Hello, world!";\n        int length = myString.length();\n        System.out.println("The length of the string is: " + length);\n    }\n}\n```',
        ],
    },
    {
        'expert': senior_java_developer,
        'prompts': [
            'In Java, you can find the length of a string by using the length() method of the String class. Here is an example:\n\n```java\npublic class Main {\n    public static void main(String[] args) {\n        String myString = "Hello, world!";\n        int length = myString.length();\n        System.out.println("The length of the string is: " + length);\n    }\n}\n```',
        ],
    },
    {
        'expert': linked_in_hook_crafting_expert,
        'prompts': [
            'You can reverse a string in Java by using the StringBuilder class. Here is an example of how to do it:',
            'In Java, you can find the length of a string by using the length() method of the String class. Here is an example:\n\n```java\npublic class Main {\n    public static void main(String[] args) {\n        String myString = "Hello, world!";\n        int length = myString.length();\n        System.out.println("The length of the string is: " + length);\n    }\n}\n```',
        ],
    },
]

const phaseText = {
    [Phase.Introduction]: {
        'title': 'Introduction',
        'description': '',
    },
    [Phase.SelectExpert]: {
        'title': 'Select Experts',
        'description': 'Choose an expert to generate a response from',
    },
    [Phase.GeneratingResponse]: {
        'title': 'Generating Response',
        'description': 'Generating a response from the selected expert...',
    },
    [Phase.ResponseGenerated]: {
        'title': 'Response Generated',
        'description': 'The response has been generated successfully',
    },
}

interface GeneratedResponse {
    [key: string]: string
}

export const MultiExpert = () => {
    const navigate = useNavigate()
    const [phase, setPhase] = useState<Phase>(Phase.SelectExpert)
    const [selectedExperts, setSelectedExperts] = useState<number[]>([])
    const [selectedPrompt, setSelectedPrompt] = useState<number>(0)
    const [generatedResponses, setGeneratedResponses] = useState<GeneratedResponse>({})
    const defaultNum3Text = {
        'title': phaseText[Phase.GeneratingResponse].title,
        'description': phaseText[Phase.GeneratingResponse].description,

    }
    const [num3Text, setNum3Text] = useState<any>(defaultNum3Text)

    useEffect(() => {
        if (phase === Phase.GeneratingResponseLoading) {
            setGeneratedResponses({})
            setNum3Text(defaultNum3Text)
            setTimeout(() => {
                setPhase(Phase.GeneratingResponse)
            }, 1500)
        }
        if (phase === Phase.GeneratingResponse) {
            const responses = selectedExperts.map((index) => {
                const response = expertList[index].prompts[selectedPrompt]
                return response
            })
            const appendNextLetter = async ({ key, iterator }: { key: number, iterator: number }) => {
                if (iterator < responses[key].length - 1) {
                    setGeneratedResponses((prev) => {
                        return {
                            ...prev,
                            [key]: (prev[key] || '') + responses[key][iterator],
                        }
                    })
                    iterator++
                    setTimeout(() => appendNextLetter({ key, iterator }), 15)
                }
            }

            responses.forEach((response, index) => {
                appendNextLetter({ key: index, iterator: -1 })
            })

            setNum3Text({
                'title': phaseText[Phase.ResponseGenerated].title,
                'description': phaseText[Phase.ResponseGenerated].description,
            })
        }

    }, [phase])

    return (
        <div className='funToolComponent multiExpert'>
            <FunToolItem
                num={0}
                title={phaseText[Phase.Introduction].title}
                description={phaseText[Phase.Introduction].description}
                active={phase === Phase.SelectExpert}
            >
                <div className='funToolComponent-text'>
                    <p>
                        Let's say you want to generate a new marketing strategy for your company. Imagine you are in a
                        room full of domain experts. Choose who you want to ask for advice!
                    </p>
                </div>
            </FunToolItem>
            <FunToolItem
                num={1}
                title={phaseText[Phase.SelectExpert].title}
                description={phaseText[Phase.SelectExpert].description}
                active={phase === Phase.SelectExpert}
            >
                {phase === Phase.SelectExpert && (
                    <>
                        <div className='funToolComponent-expertList'>
                            {expertList.map((item, index) => (
                                <AgentCard agent={item.expert}
                                           onClick={() => {
                                               if (selectedExperts.includes(index)) {
                                                   setSelectedExperts(selectedExperts.filter(item => item !== index))
                                               } else {
                                                   setSelectedExperts([...selectedExperts, index])
                                               }
                                           }}
                                           id={selectedExperts.includes(index) ? 'selectedCard' : ''}
                                           onClickRedirect={false}

                                />
                            ))}
                        </div>
                        <button onClick={() => setPhase(Phase.GeneratingResponseLoading)}>Generate Response</button>
                    </>
                )}
                {phase !== Phase.SelectExpert && (
                    <div className='funToolComponent-expertList'>
                        {selectedExperts.map((index) => (
                            <AgentCard agent={expertList[index].expert}
                                       onClick={() => {
                                           setSelectedExperts(selectedExperts.filter(item => item !== index))
                                           setPhase(Phase.SelectExpert)
                                       }}
                                       onClickRedirect={false}

                            />
                        ))}
                    </div>
                )}
            </FunToolItem>
            <FunToolItem
                num={2}
                title={num3Text.title}
                description={num3Text.description}
                active={phase === Phase.GeneratingResponse || phase === Phase.GeneratingResponseLoading}
            >
                {(phase === Phase.GeneratingResponseLoading || phase === Phase.GeneratingResponse) && (
                    <div className='funToolComponent-generatingResponse-wrapper'>
                        {Object.keys(generatedResponses).map((response: string, index: number) => (
                            <div className='funToolComponent-generatingResponse'>
                                {phase === Phase.GeneratingResponseLoading &&
                                    <AiOutlineLoading
                                        className='funToolComponent-generatingResponse-loading-spinner' />}
                                {phase === Phase.GeneratingResponse &&
                                    <div className='funToolComponent-generatingResponse-inline'>
                                        <div className='funToolComponent-generatingResponse-loading-sender'>
                                            <FaRobot className='message-icon'
                                                     style={{
                                                         // @ts-ignore
                                                         color: expertList[response].expert.logoColorHex ? expertList[response].expert.logoColorHex : '',
                                                     }}
                                            />
                                            <div
                                                // @ts-ignore
                                                className='message-icon-sender'>{expertList[response].expert.name}</div>
                                        </div>
                                        <h1 className='message-text'>
                                            <Parser>
                                                {generatedResponses[response]}
                                            </Parser>
                                        </h1>
                                    </div>}
                            </div>
                        ))
                        }
                    </div>
                )}
            </FunToolItem>
        </div>
    )
}