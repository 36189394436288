import React, { useEffect, useState } from 'react'
import './Pricing.scss'
import { getModels } from '../../Requests/aiModelRequests'
import SEO from '../../Components/SEO'
import FreeBlobEnv from '../../Components/Blobs/FreeBlob/FreeBlobEnv'

const Pricing = () => {
    const [pricing, setPricing] = useState<any>([])

    function createCategoryObject(array: any[]) {
        return array.reduce((acc, obj) => {
            if (obj.categoryName) {
                if (!acc[obj.categoryName]) {
                    acc[obj.categoryName] = []
                }
                if (obj.categoryName) {
                    acc[obj.categoryName].push(obj)
                }
            }
            return acc
        }, {})
    }

    useEffect(() => {
        getModels().then((res: any) => {
            setPricing(createCategoryObject(res))
        })
    }, [])
    return (
        <div className='pricing-wrapper'>
            <SEO
                title='APPI | Pricing'
                description='Build integrations fast and deliver a native integration marketplace experience to your customers. Built in a way devs love.'
                name='Pricing'
                type='website'
                keywords={['APPI', 'Pricing', 'Chat', 'Create an account', 'Read more']}
            />
            {/*<img className='blobPricing blobPricing1' src={require('../../Images/Blobs/blob3.svg').default}*/}
            {/*     alt='mySvgImage' />*/}
            {/*<img className='blobPricing blobPricing2' src={require('../../Images/Blobs/blob6.svg').default}*/}
            {/*     alt='mySvgImage' />*/}
            {/*<img className='blobPricing blobPricing3' src={require('../../Images/Blobs/blob9.svg').default}*/}
            {/*     alt='mySvgImage' />*/}
            <FreeBlobEnv width={'100%'} height={'100%'} min={30} max={30} />
            <div className='pricing'>
                <h1 className='pricing-title'>Plans and pricing</h1>

                <div className='pricing-tables'>
                    {Object.keys(pricing).map((model: string, index: number) => {
                        const subcategory = pricing[model]
                        const representative = subcategory[0]
                        return (
                            <div className='pricing-tables-model' key={index}>
                                <div className='pricing-tables-model-description'>
                                    <h1 className='pricing-tables-model-title'>{representative.categoryName}</h1>
                                    <p className='pricing-tables-model-text'>{representative.description}</p>
                                </div>
                                <div className='pricing-tables-model-pricing'>
                                    <table>
                                        <tbody>
                                        <tr className='pricing-tables-model-pricing-titlerow'>
                                            <td className='pricing-tables-model-pricing-variant-name'>Model</td>
                                            <td className='pricing-tables-model-pricing-variant-price'>
                                                Input [1K tokens]
                                            </td>
                                            <td className='pricing-tables-model-pricing-variant-price'>
                                                Output [1K tokens]
                                            </td>
                                        </tr>
                                        {subcategory.map((variant: any, index: number) => (
                                            <tr key={index} className='pricing-tables-model-pricing-row'>
                                                <td className='pricing-tables-model-pricing-variant-name'>{variant.displayName}</td>
                                                <td className='pricing-tables-model-pricing-variant-price'>${variant.costOfOneInputToken / 100000}</td>
                                                <td className='pricing-tables-model-pricing-variant-price'>${variant.costOfOneOutputToken / 100000}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {/*<PricingTiles />*/}
        </div>
    )
}

export default Pricing