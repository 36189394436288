import React, { useEffect } from 'react'
import '../../../../../../Components/AgentCard/AgentCard.scss'
import '../../../../../../Components/AgentCard/AgentCardMobile.scss'
import './DummyAgent.scss'
import { Color } from 'react-color-surge/dist/index'
import { FaLock, FaRobot } from 'react-icons/fa'
import { AnimatedBlob } from '../../../../../../Components/Blobs/AnimatedBlob/AnimatedBlob'
import { hexToCSSFilter } from 'hex-to-css-filter'
import { useNavigate } from 'react-router-dom'
import * as FaIcons from 'react-icons/fa'
import { IconType } from 'react-icons'

interface DummyAgentCardInterface {
    name: string;
    description: string;
    type?: string
    small?: boolean
    colored?: boolean
    id?: string
    nav?: string
    onClick?: Function
    onClickRedirect?: boolean
}

const DummyAgentCard = ({
                       name,
                       description,
                       type,
                       small = false,
                       colored = true,
                       id = '',
                       nav = 'default',
                       onClickRedirect = false,
                       onClick = () => {
                       },
                   }: DummyAgentCardInterface) => {

    const navigate = useNavigate()
    const icons = FaIcons as { [key: string]: IconType }
    const CurrentIconComponent = icons['FaRobot']

    const navigateFunc = () => {
        if (onClickRedirect) {
            if (nav === 'default')
                navigate('/agents/' + id)
            if (nav === 'dev')
                navigate('/developer/agents/' + id)
        }
        onClick()
    }

    const [color, setColor] = React.useState(type === 'expert' ? '#CE8B09' : '#ED1660')
    const [filters, setFilters] = React.useState('')

    useEffect(() => {
        const filters_base = hexToCSSFilter('#ED1660').filter
        if (filters_base)
            setFilters(filters_base.substring(0, filters_base.length - 1))
    }, [color])

    let className = 'agentCard'
    if (small) {
        className += ' agentCard-small'
    }
    if (!colored) {
        className += ' agentCard-noColor'
    }

    return (
        <div className='dummy'>
            <div className={className}
                onClick={() => navigateFunc()}
                id={id}
            >
                <CurrentIconComponent className='agentCard-logo agentCard-logo-default'
                                    style={{ color: '#ED1660' }} />
                <AnimatedBlob number={5} animated={false}
                            blobStyle={{
                                filter: filters,
                            }}
                />
                <div className='agentCard-title'>{name}</div>
                <div className='agentCard-author'>by YOU</div>
                <div className='agentCard-description'>
                    {description.slice(0, 100)}
                    {description.length > 100 && '...'}
                </div>
            </div>
            <a href='../developer/agents/new?type=expert' className='dummy-link'>
                <button className='dummy-button'>Try it yourself</button>
            </a>
        </div>
    )
}

export default DummyAgentCard