import { AiOutlineHistory, AiOutlineLoading } from 'react-icons/ai'
import { BsArrowsAngleContract, BsArrowsAngleExpand, BsChatLeftText, BsPlugin, BsPlusSquare } from 'react-icons/bs'
import { RiAuctionLine } from 'react-icons/ri'
import React from 'react'
import './Sidebar.scss'
import { MdOutlineIntegrationInstructions } from 'react-icons/md'
import { ConversationState, SidebarPages } from '../../../Utils/enums'
import { SidebarAgents } from './Elemets/Agents'
import { SidebarSummarizers } from './Elemets/Summarizers'
import { BiArrowBack, BiTrashAlt } from 'react-icons/bi'
import { FiSettings } from 'react-icons/fi'
import { CustomInstructionItem } from './CustomInstructionItem/CustomInstructionItem'
import useRedirect from '../../../Utils/redirect'
import './SidebarMobile.scss'
import { History } from '../History/History'
import { SidebarHoverComponent } from './Utils/SidebarHoverComponent/SidebarHoverComponent'
import { FaVial } from 'react-icons/fa'
import { chatPresets } from '../../../Dev/chatPresets'
import { useChatContext } from '../ChatPropsManager'

const APPI_text = require('../../../Images/Logo/APPI_alone.png')
const APPI_icon = require('../../../Images/Logo/APPI_icon.png')

interface SidebarComponentInterface {
    navigate: (path: string) => void
    APPI: string
    executeChatPreset: (index: number) => void
}

export const Sidebar = ({
                            navigate,
                            APPI,
                            executeChatPreset,
                        }: SidebarComponentInterface) => {
    const {
        sidebar,
        setSidebar,
        agentsList,
        agentsSelected,
        setAgentsSelected,
        selectedItem,
        setSelectedItem,
        summarizersList,
        summarizer,
        setSummarizer,
        selectedConversation,
        setSelectedConversation,
        setMessages,
        history,
        loadingHistory,
        setHistoryModal,
        customProfiles,
        selectedCustomInstruction,
        setSelectedCustomInstruction,
        setNewInstruction,
        setEditInstruction,
        conversationState,
        messages,
        sidebarModal,
        setSidebarModal,
        mobile,

    } = useChatContext()
    const redirect = useRedirect()
    const [sidebarMode, setSidebarMode] = React.useState(false)

    const handleModalClick = (e: any) => {
        if (sidebarModal) {
            document.body.style.overflow = 'auto'
        } else {
            document.body.style.overflow = 'hidden'
        }
        setSidebarModal(!sidebarModal)
    }

    const handleSidebarChange = (page: SidebarPages) => {
        if (sidebar === page) setSidebar(SidebarPages.None)
        else setSidebar(page)
    }
    if (!mobile) {
        return (
            <>
                <div className='chat-sidebar-selection'>
                    <img src={APPI} alt='appi' className={
                        sidebar === SidebarPages.None ? 'chat-sidebar-selection-logo' :
                            'chat-sidebar-selection-logo hidden'
                    }
                         onClick={() => navigate('/')}
                    />
                    <SidebarHoverComponent text={'Chat History'}>
                        <AiOutlineHistory className='chat-sidebar-selection-icon'
                                          id={sidebar === SidebarPages.History ? 'active' : ''}
                                          onClick={() => {
                                              if (sidebar === SidebarPages.History) setSidebar(SidebarPages.None)
                                              else setSidebar(SidebarPages.History)
                                          }}

                        />
                    </SidebarHoverComponent>
                    <SidebarHoverComponent text={'Select Experts'}>

                        <BsPlugin className='chat-sidebar-selection-icon'
                                  onClick={() => {
                                      if (sidebar === SidebarPages.Agents) setSidebar(SidebarPages.None)
                                      else setSidebar(SidebarPages.Agents)
                                  }}
                                  id={sidebar === SidebarPages.Agents ? 'active' : ''}
                        />
                    </SidebarHoverComponent>
                    <SidebarHoverComponent text={'Select Summarizer'}>

                        <RiAuctionLine className='chat-sidebar-selection-icon'
                                       onClick={() => {
                                           if (sidebar === SidebarPages.Summarizer) setSidebar(SidebarPages.None)
                                           else setSidebar(SidebarPages.Summarizer)
                                       }}
                                       id={sidebar === SidebarPages.Summarizer ? 'active' : ''}
                        />
                    </SidebarHoverComponent>
                    <SidebarHoverComponent text={'Select Custom Profile'}>
                        <MdOutlineIntegrationInstructions className='chat-sidebar-selection-icon'
                                                          onClick={() => {
                                                              if (sidebar === SidebarPages.CustomProfiles) setSidebar(SidebarPages.None)
                                                              else setSidebar(SidebarPages.CustomProfiles)
                                                          }}
                                                          id={sidebar === SidebarPages.CustomProfiles ? 'active' : ''}
                        />
                    </SidebarHoverComponent>
                    <SidebarHoverComponent text={'Settings'}>
                        <FiSettings className='chat-sidebar-selection-icon'
                                    onClick={() => {
                                        if (sidebar === SidebarPages.SETTINGS) setSidebar(SidebarPages.None)
                                        else setSidebar(SidebarPages.SETTINGS)
                                    }}
                                    id={sidebar === SidebarPages.SETTINGS ? 'active' : ''}
                        />
                    </SidebarHoverComponent>

                    {process.env.REACT_APP_DEV === 'true' && <SidebarHoverComponent text={'Settings'}>
                        <FaVial className='chat-sidebar-selection-icon'
                                onClick={() => {
                                    if (sidebar === SidebarPages.DEV) setSidebar(SidebarPages.None)
                                    else setSidebar(SidebarPages.DEV)
                                }}
                                id={sidebar === SidebarPages.DEV ? 'active' : ''}
                        />
                    </SidebarHoverComponent>}
                    {/*<SidebarHoverComponent text={'Settings'}>*/}
                    {/*    {!sidebarMode ?*/}
                    {/*        <BsArrowsAngleExpand className='chat-sidebar-selection-icon'*/}
                    {/*                             onClick={() => setSidebarMode(true)} /> :*/}
                    {/*        <BsArrowsAngleContract className='chat-sidebar-selection-icon'*/}
                    {/*                               onClick={() => setSidebarMode(false)} />*/}
                    {/*    }*/}
                    {/*</SidebarHoverComponent>*/}
                </div>

                {sidebar !== SidebarPages.None && (
                    <div className={!sidebarMode ? 'chat-sidebar' : 'chat-sidebar chat-sidebar-max'}>
                        <div className='chat-sidebar-logo' onClick={() => redirect('', '')}>
                            <img src={APPI} alt='appi' className='chat-sidebar-logo-appi' />
                            <img src={APPI_text} alt='appi text' className='chat-sidebar-logo-appiText' />
                        </div>
                        {sidebar === SidebarPages.Agents && (
                            <SidebarAgents
                                agents_list={agentsList}
                                agents_selected={agentsSelected}
                                setAgents_selected={setAgentsSelected}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                            />


                        )}
                        {sidebar === SidebarPages.Agents && (
                            <div className='chat-sidebar-agents-counter'>
                                <h1 className='chat-sidebar-agents-counter-text'>
                            <span
                                id={
                                    agentsSelected.length < 3 ? 'counter-low' :
                                        agentsSelected.length < 5 ? 'counter-mid' :
                                            'counter-high'
                                }>{agentsSelected.length}</span> / 5</h1>
                            </div>

                        )}
                        {sidebar === SidebarPages.Summarizer && (
                            <SidebarSummarizers
                                summarizers_list={summarizersList}
                                summarizer={summarizer}
                                setSummarizer={setSummarizer}
                                selectedItem={selectedItem}
                                setSelectedItem={setSelectedItem}
                            />
                        )}
                        {sidebar === SidebarPages.History && (
                            <History
                                setSelectedConversation={setSelectedConversation}
                                setMessages={setMessages}
                                setHistoryModal={setHistoryModal}
                                history={history}
                                loadingHistory={loadingHistory}
                                selectedConversation={selectedConversation}
                            />
                        )}
                        {sidebar === SidebarPages.CustomProfiles && (
                            <div className='chat-sidebar-CustomProfiles'>
                                <h1 className='chat-sidebar-titles'>Custom Profiles</h1>
                                <div className='chat-sidebar-history-new' onClick={() => setNewInstruction(true)}>
                                    <BsPlusSquare className='chat-sidebar-history-new-icon' />
                                    <h1>New profile</h1>
                                </div>
                                {customProfiles.map((instruction: any, index: number) => (
                                    <CustomInstructionItem key={index}
                                                           instruction={instruction}
                                                           selectedCustomInstruction={selectedCustomInstruction}
                                                           setSelectedCustomInstruction={() => {
                                                               if (conversationState === ConversationState.READY && messages.length === 0)
                                                                   setSelectedCustomInstruction(instruction)
                                                           }}
                                                           setEditInstruction={() => {
                                                               setEditInstruction(instruction)
                                                           }}
                                    />
                                ))}
                            </div>
                        )}
                        {sidebar === SidebarPages.SETTINGS && (
                            <div className='chat-sidebar-settings'>
                            </div>
                        )}
                        {/*{(sidebar === SidebarPages.DEV && process.env.REACT_APP_DEV === 'true') && (*/}
                        {/*    <div className='chat-sidebar-dev'>*/}
                        {/*        <h1>DEV</h1>*/}
                        {/*        <div className='chat-sidebar-dev-presets'>*/}
                        {/*            {chatPresets.map((preset, index) => (*/}
                        {/*                <div key={index} className='chat-sidebar-dev-presets-preset'>*/}
                        {/*                    <h1>{preset.name}</h1>*/}
                        {/*                    <button onClick={() => executeChatPreset(index)}>Execute</button>*/}
                        {/*                </div>*/}
                        {/*            ))}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>
                )}
            </>
        )
    } else {
        return (
            <>
                {sidebarModal && (
                    <div className='chat-sidebar-modal-wrapper' onClick={handleModalClick}>
                        <div className='chat-sidebar-modal' onClick={e => e.stopPropagation()}>
                            {SidebarPages.None === sidebar && <div className='chat-sidebar-modal-boxes'>
                                <div className='chat-sidebar-modal-boxes-box' id='box1'
                                     onClick={() => handleSidebarChange(SidebarPages.History)}>
                                    <AiOutlineHistory className='chat-sidebar-modal-boxes-box-icon' />
                                    <h1 className='chat-sidebar-modal-boxes-box-title'>History</h1>
                                </div>
                                <div className='chat-sidebar-modal-boxes-box' id='box2'
                                     onClick={() => handleSidebarChange(SidebarPages.Agents)}>
                                    <BsPlugin className='chat-sidebar-modal-boxes-box-icon' />
                                    <h1 className='chat-sidebar-modal-boxes-box-title'>Agents</h1>
                                </div>
                                <div className='chat-sidebar-modal-boxes-box' id='box3'
                                     onClick={() => handleSidebarChange(SidebarPages.Summarizer)}>
                                    <RiAuctionLine className='chat-sidebar-modal-boxes-box-icon' />
                                    <h1 className='chat-sidebar-modal-boxes-box-title'>Summarizer</h1>
                                </div>
                                <div className='chat-sidebar-modal-boxes-box' id='box4'
                                     onClick={() => handleSidebarChange(SidebarPages.CustomProfiles)}>
                                    <MdOutlineIntegrationInstructions className='chat-sidebar-modal-boxes-box-icon' />
                                    <h1 className='chat-sidebar-modal-boxes-box-title'>Custom Prfoiles</h1>
                                </div>
                            </div>}
                            {sidebar === SidebarPages.History &&
                                <div className='chat-sidebar-modal-history'>
                                    <History
                                        setSelectedConversation={setSelectedConversation}
                                        setMessages={setMessages}
                                        setHistoryModal={setHistoryModal}
                                        history={history}
                                        loadingHistory={loadingHistory}
                                        selectedConversation={selectedConversation}
                                    />
                                </div>
                            }
                            {sidebar === SidebarPages.Agents &&
                                <div className='chat-sidebar-modal-agents'>
                                    <SidebarAgents
                                        agents_list={agentsList}
                                        agents_selected={agentsSelected}
                                        setAgents_selected={setAgentsSelected}
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                    />
                                </div>
                            }
                            {sidebar === SidebarPages.Summarizer &&
                                <div className='chat-sidebar-modal-summarizers'>
                                    <SidebarSummarizers
                                        summarizers_list={summarizersList}
                                        summarizer={summarizer}
                                        setSummarizer={setSummarizer}
                                        selectedItem={selectedItem}
                                        setSelectedItem={setSelectedItem}
                                    />
                                </div>
                            }
                            {sidebar === SidebarPages.CustomProfiles &&
                                <div className='chat-sidebar-modal-CustomProfiles'>
                                    <div className='chat-sidebar-modal-CustomProfiles-new'
                                         onClick={() => setNewInstruction(true)}>
                                        <BsPlusSquare className='chat-sidebar-modal-CustomProfiles-new-icon' />
                                        <h1 className='chat-sidebar-titles'>Custom Profiles</h1>
                                    </div>
                                    {customProfiles.map((instruction: any, index: number) => (
                                        <CustomInstructionItem key={index}
                                                               instruction={instruction}
                                                               selectedCustomInstruction={selectedCustomInstruction}
                                                               setSelectedCustomInstruction={() => {
                                                                   if (conversationState === ConversationState.READY && messages.length === 0)
                                                                       setSelectedCustomInstruction(instruction)
                                                               }}
                                                               setEditInstruction={() => {
                                                                   setEditInstruction(instruction)
                                                               }}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                )}
                <FiSettings className='chat-sidebar-icon' onClick={handleModalClick} />
                {(sidebarModal && sidebar !== SidebarPages.None) &&
                    <BiArrowBack className='chat-sidebar-icon chat-sidebar-icon-back'
                                 onClick={() => setSidebar(SidebarPages.None)} />}

            </>
        )
    }
}