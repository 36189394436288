import React, { useEffect, useState, useRef } from 'react'
import './FreeBlob.scss'

interface AnimatedBlobInterface {
    number: number;
    width?: number | string;
    height?: number | string;
    scale?: number | string;
    rotate?: number | string;
    animated?: boolean;
    top?: number | string;
    left?: number | string;
    onDestruction?: () => void;
    lifespan?: number;
}

export const FreeBlob = ({
                             number,
                             scale,
                             rotate,
                             animated = true,
                             width = '20vh',
                             height = '20vh',
                             top = '40vh',
                             left = '40vh',
                             onDestruction,
                             lifespan = 15000,
                         }: AnimatedBlobInterface) => {
    const path = require('../../../Images/Blobs/blob' + number + '.svg')
    const convertToPixels = (value: number | string) => {
        if (typeof value === 'number') {
            return value
        }
        const numericValue = parseFloat(value)
        if (value.endsWith('vh')) {
            return numericValue * window.innerHeight / 100
        } else if (value.endsWith('vw')) {
            return numericValue * window.innerWidth / 100
        }
        return numericValue // Defaults to treating the value as pixels
    }
    const [blobStyle, setBlobStyle] = useState({
        width,
        height,
        transform: `scale(${scale ? scale : '1'}) rotate(${rotate ? rotate : '0deg'})`,
        left: convertToPixels(left),
        top: convertToPixels(top),
    } as React.CSSProperties)

    const getRandomVelocity = () => {
        const baseVelocity = 1
        const numericWidth = convertToPixels(width)
        const numericHeight = convertToPixels(height)
        const sizeFactor = 1 / (Math.sqrt(numericWidth * numericHeight) / 100)
        let velocity
        if (scale)
            velocity = Math.max(2, baseVelocity * sizeFactor * (scale as number))
        else
            velocity = Math.max(2, baseVelocity * sizeFactor)

        return Math.random() * velocity * (Math.random() < 0.5 ? -1 : 1)
    }

    const velocity = useRef({ x: getRandomVelocity(), y: getRandomVelocity() })
    const blobRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (animated) {
            const intervalId = setInterval(moveBlob, 50) // Adjust time interval for smoother animation
            const timeoutId = setTimeout(() => {
                onDestruction && onDestruction()
            }, lifespan)

            return () => {
                clearInterval(intervalId)
                clearTimeout(timeoutId)
            }
        }
    }, [animated])

    const moveBlob = () => {
        if (!blobRef.current) return
        const currentStyle = window.getComputedStyle(blobRef.current)
        let x = parseInt(currentStyle.left, 10)
        let y = parseInt(currentStyle.top, 10)
        // Update position based on velocity
        x += velocity.current.x
        y += velocity.current.y
        const parent = blobRef.current.parentElement
        if (!parent) return
        const parentWidth = parent.clientWidth
        const parentHeight = parent.clientHeight

        // Check boundaries and reverse direction if needed
        if (x < 0 || x + blobRef.current.offsetWidth > parentWidth) {
            velocity.current.x *= -1
            x = Math.max(0, Math.min(x, parentWidth - blobRef.current.offsetWidth))
        }
        if (y < 0 || y + blobRef.current.offsetHeight > parentHeight) {
            velocity.current.y *= -1
            y = Math.max(0, Math.min(y, parentHeight - blobRef.current.offsetHeight))
        }

        // Update the style to move the blob
        setBlobStyle(prevStyle => ({
            ...prevStyle,
            left: `${x}px`,
            top: `${y}px`,
        }))
    }

    return (
        <div ref={blobRef} className='freeBlob-wrapper' style={blobStyle}>
            <img className={animated ? 'freeBlob' : 'freeBlob freeBlob-noAnim'} src={path}
                 alt='Animated blob' />
        </div>
    )
}
