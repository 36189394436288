import './GetStarted.scss'
import React, { useEffect, useState } from 'react'
import { AgentInterface } from '../../../Interfaces/agentsInterfaces'
import {
    academic_financial_expert, custom_expert, customer_research_expert,
    designdirector,
    general_chat, linked_in_hook_crafting_expert, reactseniordeveloper,
    senior_java_developer, tailored_expert,
    theoryof_probability_expert,
} from '../../../Components/staticExperts'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { FaRobot } from 'react-icons/fa'
import AgentCard from '../../../Components/AgentCard/AgentCard'

export const GetStarted = () => {

    const [selected, setSelected] = useState<string>('Featured')
    const [config, setConfig] = useState<GetStartedConfigInterface[]>(getStartedConfig)

    useEffect(() => {
        setConfig([])
        const newConfig = getStartedConfig.filter(item => item.category === selected)
        setConfig(newConfig)
    }, [selected])

    return (
        <div className='getStarted'>
            <h1 className='getStarted-title'>Get started quickly</h1>
            <div className='getStarted-panels'>
                <div className='getStarted-category'>
                    <h1>Use case</h1>
                    {getStartedConfig.map((item, index) => (
                        <p
                            key={index}
                            className={item.category === selected ? 'getStarted-category-item-selected getStarted-category-item' : item.category === 'Custom' ? 'getStarted-category-item getStarted-category-item-custom' : 'getStarted-category-item'}
                            onClick={() => setSelected(item.category)}
                        >{item.category}</p>
                    ))}
                </div>
                <div className='getStarted-items'>
                    {config[0].items.map((item, index) => (
                        <AgentCard agent={item} />
                        // <div key={index}
                        //      className={selected === 'Custom' ? 'getStarted-item getStarted-item-custom' : 'getStarted-item'}>
                        //     <div className='getStarted-item-image'
                        //          style={{
                        //              background: 'linear-gradient(30deg, rgba(0, 0, 0, 0.5), ' + item.logoColorHex + ')',
                        //          }}
                        //     >
                        //         <FaRobot
                        //             className='getStarted-item-image-icon'
                        //             style={{
                        //                 color: item.logoColorHex ? item.logoColorHex : 'white',
                        //             }}
                        //         />
                        //     </div>
                        //     <h1
                        //         style={{
                        //             color: item.logoColorHex ? item.logoColorHex + 'BB' : 'white',
                        //         }}
                        //     >{item.name}</h1>
                        //     <p>{item.shortDescription}</p>
                        //     <div className='getStarted-item-button'>
                        //         Chat now
                        //         <MdOutlineKeyboardArrowRight
                        //
                        //         />
                        //     </div>
                        //
                        // </div>
                    ))}
                </div>
                <div className='getStarted-seeMore'>
                    See more
                </div>
            </div>
        </div>
    )

}

interface GetStartedConfigInterface {
    category: string
    items: AgentInterface[]
}

const getStartedConfig: GetStartedConfigInterface[] = [
    {
        category: 'Featured',
        items: [
            senior_java_developer,
            designdirector,
            general_chat,
        ],
    },
    {
        category: 'Code',
        items: [
            senior_java_developer,
            reactseniordeveloper,

        ],
    },
    {
        category: 'Marketing',
        items: [
            linked_in_hook_crafting_expert,
            customer_research_expert,
        ],
    },
    {
        category: 'Academic',
        items: [
            theoryof_probability_expert,
            academic_financial_expert,

        ],
    },
    {
        category: 'Custom',
        items: [
            custom_expert,
            tailored_expert,
        ],
    },
]