import React, { useEffect, useRef, useState } from 'react'
import './PdfViewer.scss'
import { AreaHighlight, Highlight, PdfHighlighter, PdfLoader, Popup, Tip } from 'react-pdf-highlighter'
import { Document, Page } from 'react-pdf'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdOutlineKeyboardArrowUp } from 'react-icons/md'

interface boundingBoxInterface {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
}

interface PdfViewerMetadata {
    page?: number | null;
    bounding_box?: boundingBoxInterface;

}

interface PdfViewerInterface {
    file: string;
    metadata?: PdfViewerMetadata[];
    selected?: number;
    setSelectedChunk?: Function;
}

const resetHash = () => {
    document.location.hash = ''
}

const HighlightPopup = ({
                            comment,
                        }: {
    comment: { text: string; emoji: string };
}) =>
    comment.text ? (
        <div className="Highlight__popup">
            {comment.emoji} {comment.text}
        </div>
    ) : null

function inchesToPercentageA4(xInches: number, yInches: number) {
    // A4 dimensions in inches
    const a4WidthInInches = 8.27
    const a4HeightInInches = 11.69

    // Convert inches to percentage of A4 dimensions
    const xPercentage = (xInches / a4WidthInInches) * 100
    const yPercentage = (yInches / a4HeightInInches) * 100

    // Return the calculated percentages
    return { xPercentage, yPercentage }
}


export const PdfViewer = ({ file, metadata, selected, setSelectedChunk }: PdfViewerInterface) => {
    const [highlights, setHighlights] = useState<any[]>([])
    const [scrollTo, setScrollTo] = useState<Function | null>(null) // Store the scrollTo function
    const viewerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (metadata && metadata.length > 0) {
            const new_highlights: any[] = []
            for (let i = 0; i < metadata.length; i++) {
                const meta = metadata[i]
                if (!meta.bounding_box) continue
                const x = meta.bounding_box.x1
                const y = meta.bounding_box.y1
                const x2 = meta.bounding_box.x2
                const y2 = meta.bounding_box.y2
                const width = 100
                const height = 100

                const { xPercentage, yPercentage } = inchesToPercentageA4(x, y)
                const { xPercentage: x2Percentage, yPercentage: y2Percentage } = inchesToPercentageA4(x2, y2)

                const defaultBox = {
                    id: String(Math.random()),
                    position: {
                        boundingRect: {
                            x1: xPercentage - 1,
                            x2: x2Percentage + 1,
                            y1: yPercentage - 1,
                            y2: y2Percentage + 1,
                            width,
                            height,
                            pageNumber: meta.page ? meta.page : 1,
                        },
                        pageNumber: meta.page ? meta.page : 1,
                        rects: [],
                    },
                    comment: { text: 'Default Box' },
                    content: {},
                }
                new_highlights.push(defaultBox)
            }
            setHighlights(new_highlights)
            setTimeout(() => {
                scrollToHighlight(selected ? selected : 0)
            }, 100)
        }
    }, [metadata, scrollTo])

    const scrollToHighlight = (index: number) => {
        setTimeout(() => {
            if (scrollTo) {
                scrollTo(highlights[index])
            }
        }, 100)
    }

    const handleScrollRef = (scrollFunction: any) => {
        setScrollTo(() => scrollFunction)
    }

    const scrollUp = () => {
        if (selected !== undefined && setSelectedChunk) {
            const nextSelected = selected === highlights.length - 1 ? 0 : selected + 1
            setSelectedChunk(nextSelected)
        }
    }

    const scrollDown = () => {
        if (selected !== undefined && setSelectedChunk) {
            const nextSelected = selected === 0 ? highlights.length - 1 : selected - 1
            setSelectedChunk(nextSelected)
        }
    }

    useEffect(() => {
        if (selected !== undefined) {
            scrollToHighlight(selected)
        }
    }, [selected])


    return (
        <div className="customPdfViewer" ref={viewerRef}>
            <PdfLoader url={file} beforeLoad={<div>Loading...</div>}>
                {pdfDocument => (
                    <PdfHighlighter
                        pdfDocument={pdfDocument}
                        enableAreaSelection={(event) => false}
                        onScrollChange={resetHash}
                        scrollRef={handleScrollRef}
                        onSelectionFinished={(
                            position,
                            content,
                            hideTipAndSelection,
                            transformSelection,
                        ) => null}
                        highlightTransform={(
                            highlight,
                            index,
                            setTip,
                            hideTip,
                            viewportToScaled,
                            screenshot,
                            isScrolledTo,
                        ) => (
                            <AreaHighlight
                                isScrolledTo={isScrolledTo}
                                highlight={highlight}
                                onChange={(boundingRect) => {
                                }}
                            />
                        )}
                        highlights={highlights}
                    />
                )}

            </PdfLoader>
            <div className="customPdfViewer-controlls">
                <MdOutlineKeyboardArrowLeft className="customPdfViewer-controlls-button" onClick={() => scrollDown()} />
                <p>Chunk: {selected !== undefined ? selected + 1 : 'Err'}</p>
                <MdOutlineKeyboardArrowRight className="customPdfViewer-controlls-button" onClick={() => scrollUp()} />
            </div>
        </div>
    )
}
