import { BsChatLeftText, BsPlusSquare } from 'react-icons/bs'
import { AiOutlineLoading } from 'react-icons/ai'
import { BiTrashAlt } from 'react-icons/bi'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './History.scss'
import useRedirect from '../../../Utils/redirect'

interface HistoryInterface {
    setSelectedConversation: Function
    setMessages: Function
    setHistoryModal: Function
    history: any
    loadingHistory: boolean
    selectedConversation: string

}

interface SortedHistoryInterface {
    Today: History[]
    Yesterday: History[]
    'Last week': History[]
    'Last month': History[]
    Other: History[]
}

export const History = ({
                            setSelectedConversation,
                            setMessages,
                            setHistoryModal,
                            history,
                            loadingHistory,
                            selectedConversation,
                        }: HistoryInterface) => {
    const navigate = useNavigate()
    const [historySorted, setHistorySorted] = useState<SortedHistoryInterface>({
        Today: [],
        Yesterday: [],
        'Last week': [],
        'Last month': [],
        Other: [],
    })
    useEffect(() => {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000)
        const lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())
        const sortedHistory: SortedHistoryInterface = {
            Today: [],
            Yesterday: [],
            'Last week': [],
            'Last month': [],
            Other: [],
        }

        history.forEach((item: any) => {
            const startDate = new Date(item.startedAt)
            if (startDate >= today) {
                sortedHistory.Today.push(item)
            } else if (startDate >= yesterday && startDate < today) {
                sortedHistory.Yesterday.push(item)
            } else if (startDate >= lastWeek && startDate < yesterday) {
                sortedHistory['Last week'].push(item)
            } else if (startDate >= lastMonth && startDate < lastWeek) {
                sortedHistory['Last month'].push(item)
            } else {
                sortedHistory.Other.push(item)
            }
        })

        setHistorySorted(sortedHistory)
    }, [history])

    return (
        <div className='chat-sidebar-history'>

            <h1 className='chat-sidebar-titles'>History</h1>
            <div className='chat-sidebar-history-list'>
                <div className='chat-sidebar-history-new' onClick={() => {
                    if (process.env.REACT_APP_DEV === 'true')
                        navigate(`/dev-chat/?new`)
                    else
                        navigate(`/?new`)
                    setSelectedConversation('-1')
                    setMessages([])
                }}>
                    <BsPlusSquare className='chat-sidebar-history-new-icon' />
                    <h1>New chat</h1>
                </div>

                {loadingHistory && <AiOutlineLoading className='chat-mainArea-chat-loading' />}
                {Object.keys(historySorted).map((key: string, index_key: number) => {
                    const historyItem = historySorted[key as keyof SortedHistoryInterface]
                    if (historyItem.length === 0)
                        return null
                    else
                        return (
                            <div className='chat-sidebar-history-wrapper' key={index_key}>
                                <h1 className='chat-sidebar-history-breakpoint'>{key}</h1>
                                {historyItem.map((conversation_item: any, index: number) => {
                                    return (
                                        <div className='chat-sidebar-history-item'
                                             id={conversation_item.id === selectedConversation ? 'selected' : ''}
                                             onClick={() => {
                                                 if (conversation_item.id !== selectedConversation) {
                                                     if (process.env.REACT_APP_DEV === 'true')
                                                         navigate(`/dev-chat?${conversation_item.id}`)
                                                     else
                                                         navigate(`/?${conversation_item.id}`)
                                                     setMessages([])
                                                 }
                                             }}
                                             key={index}>
                                            {/*<BsChatLeftText className='chat-sidebar-history-item-icon' />*/}
                                            <h1>{conversation_item.name.slice(0, 35)}</h1>
                                            {conversation_item.id === selectedConversation && (
                                                <BiTrashAlt className='chat-sidebar-history-item-delete'
                                                            onClick={() => {
                                                                setHistoryModal(conversation_item.id)
                                                            }}
                                                />
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                })}
                <div className='chat-sidebar-history-line' />
            </div>
            <div className='chat-sidebar-history-footer'>
                <BiTrashAlt className='chat-sidebar-history-footer-trash'
                            onClick={() => {
                                setHistoryModal('')
                            }}
                />
            </div>
        </div>

    )
}