import './DummyFormPrompts.scss'
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export interface DummyPromptsProps {
    onClick: () => void;
    active: boolean;
}

export const DummyFormPrompts = (props: DummyPromptsProps) => {
    const [system, setSystem] = useState<string>('');
    const [expert, setExpert] = useState<string>('');

    return (
        <div className={props.active ? 'dummyFormPrompts' : 'dummyFormPrompts inactive'}>
            <div className='dummyFormPrompts-forms'>
                <div className='dummyFormPrompts-forms-element'>
                    <label className='dummyFormPrompts-forms-label'>System Prompt</label>
                    <TextareaAutosize value={system} onChange={(e) => setSystem(e.target.value)} minRows={9} maxRows={9}
                                    className='dummyFormPrompts-forms-input'
                                    required={true} maxLength={500}
                                    disabled={!props.active}>
                    </TextareaAutosize>
                    <p className='dummyFormPrompts-forms-input-charcount'>{system.length}/500</p>
                </div>
            </div>

            <div className='dummyFormPrompts-forms'>
                <div className='dummyFormPrompts-forms-element'>
                    <label className='dummyFormPrompts-forms-label'>Expert Prompt</label>
                    <TextareaAutosize value={expert} onChange={(e) => setExpert(e.target.value)} minRows={9} maxRows={9}
                                    className='dummyFormPrompts-forms-input'
                                    required={true} maxLength={500}
                                    disabled={!props.active}>
                    </TextareaAutosize>
                    <p className='dummyFormPrompts-forms-input-charcount'>{expert.length}/500</p>
                </div>
            </div>
            <button className='dummyFormPrompts-button' disabled={!props.active} onClick={() => {props.onClick()}}>Continue</button>
        </div>
    );
}
