import { MessageFlow } from '../../../Utils/enums'
import { AgentInterface } from '../../../Interfaces/agentsInterfaces'

export enum MessageType {
    USER,
    AGENT,
    SUMMARIZER
}

export interface SubMessageInterface {
    id: string,
    text: string,
    sender?: AgentInterface | { 'name': string },
    timestamp: Date,
    messageType: MessageType,
    chosen: boolean,
    chosenFinal: boolean,
    finished: boolean,
    chunk?: number
}


interface MessageInterface {
    children?: MessageInterface[]
    id: string,
    text: string,
    sender?: any,
    timestamp: Date,
    agents?: any[],
    summarizer?: any,
    finished?: boolean,
    messageType?: MessageType,
    experts_answers: SubMessageInterface[],
    summarizer_answer?: any,
    chosenFinal?: boolean,
    messageFlow: MessageFlow,
    experts_answers_to_fetch: any[],
    summarizer_answer_to_fetch: any,
    previousMessageId: string,
    nextMessagesIds: string[],
    versions?: number,
    depth?: number,
}

export default MessageInterface

export enum ChunkType {
    CONTEXT,
    RESPONSE,
    VERIFICATION,
    SPENDING,
    STATUS,
    ERROR
}

export interface ChunkSubInterface {
    Content: string,
    Type: ChunkType
}

export interface ChunkInterface {
    chunk: ChunkSubInterface,
    agentId: string,
    responseChunkNumber: number,
    expertOrSummarizer: string
    name: string,
    conversationId: string
    agentAnswerId: string
    lastMessageId: string;

}

export interface ChunkQueueInterface {
    [conversationId: string]: ChunkInterface[]
}


export interface StreamInterface {
    [conversationId: string]: ChunkInterface
}